import { createContext } from "react";

export interface InfoBullesContextData {
  infoSwitches: Record<string, boolean>;
  getInfoBulleSwitch: (s: string) => boolean;
  setInfoBulleSwitch: (s: string, b: boolean) => void;
}

const data: InfoBullesContextData = {
  infoSwitches: {},
  getInfoBulleSwitch: (s) => false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setInfoBulleSwitch: (s, b) => {},
};

const InfoBullesContext = createContext(data);

export default InfoBullesContext;
