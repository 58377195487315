import type { ReactElement } from "react";
import ConfirmationCard from "./ConfirmationCard";

interface ConfirmationCardSSTProps {
  onConfirm: (b: boolean) => Promise<void>;
  onRefute: () => void;
  loading: boolean;
}

/**
 * Card de sélection du choix de confirmation du SST pour les accidents bénins
 */
function ConfirmationCardSST({
  onConfirm,
  onRefute,
  loading,
}: ConfirmationCardSSTProps): ReactElement {
  const title = "Confirmation requise";
  const text = `Vous avez été désigné comme le SST qui a 1) été prévenu, 2) a constaté, ou 3) a assisté et/ou prodigué des soins suite à l'accident bénin ci-dessous.
    Merci de bien vouloir en prendre connaissance et de confirmer l'exactitude des faits.`;
  const noText =
    "Non, je ne suis pas le SST qui est intervenu, je n'ai pas d'habilitation SST, ou les faits ne sont pas exacts.";
  const yesText =
    "Oui, je confirme que je suis bien SST et que je suis dans un des 3 cas de figures cités précédemment concernant cet accident bénin.";
  const commentLabel = "Commentaire du SST";

  return (
    <ConfirmationCard
      title={title}
      text={text}
      noText={noText}
      yesText={yesText}
      onConfirm={onConfirm}
      onRefute={onRefute}
      loading={loading}
      comment
      commentLabel={commentLabel}
    />
  );
}

export default ConfirmationCardSST;
