enum ToastMessages {
  ERROR = "Oups ! Une erreur est survenue.",
  ERROR_RETRY = "Une erreur est survenue. Merci de réessayer ultérieurement.",
  ERROR_FORM_INFO_MISSING = "Veuillez compléter les champs indiqués en rouge.",
  ERROR_UPDATE = ERROR_RETRY,
  ERROR_NOT_FOUND = "Nous n'avons pas trouvé la ressource à laquelle vous essayez d'accéder.",
  ERROR_FORBIDDEN = "Vous n'avez pas les droits nécessaires pour accéder à cette ressource.",
  ERROR_INTERNAL_SERVER_ERROR = ERROR,
  ERROR_FORM_VALIDATION = "Merci de bien vouloir remplir correctement tous les champs obligatoires (marqués par une étoile *).",
  ERROR_DECLARATIONS = "Une erreur est survenue. Certaines déclarations ne seront pas affichées.",

  SUCCESS_CREATE = "Votre déclaration a bien été enregistrée.",
  SUCCESS_CREATE_REDIRECT = "Votre déclaration a bien été enregistrée. Les personnes concernées ont bien été notifiées.",
  SUCCESS_MODIFY = "Vos modifications ont bien été enregistrées.",
  SUCCESS_CONFIRM = "Votre confirmation a bien été enregistrée.",

  REFUTE_CONFIRMATION_TEMP = "Merci de bien vouloir le signaler par mail au Gestionnaire Temps Santé : valerie.helm@sdea.fr avec le numéro de la déclaration",
  INFO_UPDATE_REFRESH = "Une nouvelle version de l'application est disponible.",
  INFO_RGPD = "En utilisant cette application, vous acceptez que vos actions soient enregistrées dans l'unique but de faciliter la maintenance de l'application.",
  WARNING_CREATE_OFFLINE = "Votre déclaration n'a pas pu être envoyée. Nous l'enverrons dès que le réseau sera rétabli.",
  OLD_DATA = "Merci de vous connecter à Internet pour mettre à jour les données de l'application.",
  ERROR_CREATE_OFFLINE = "Pour une raison inconnue, nous ne sommes ni parvenus à envoyer votre déclaration, ni à la sauvegarder sur votre appareil. Merci de bien vouloir nous signaler ce problème.",
}

export default ToastMessages;
