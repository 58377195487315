import { apiConfig } from "app-config";
import type { Accident } from "models/Accident";
import Errors from "constants/Errors";
import errorHandlingService from "./errorHandlingService";
import networkService from "./networkService";
import { dateUtil } from "@sdeapps/react-core";

const apiBaseUrl = `${apiConfig.apimBaseUrl}/${apiConfig.declarationApi.apiName}`;
const scopes = apiConfig.declarationApi.scopes;

const accidentsEndpoint = "accidents";
const userAccidentsEndpoint = "me/accidents";
const accidentCompleteEndpoint = "complete";
const userAndSubordinatesAccidentsEndpoint = "me/directReports/accidents";
const photosEndpoint = "photos";

/**
 * Génère les headers pour la requête http
 * @returns Les Headers
 */
async function createHeaders(): Promise<HeadersInit> {
  return networkService.createHeaders(scopes);
}

/**
 * Récupère tous les accidents
 * Cette route n'est accessible qu'aux modérateurs
 * @returns Liste d'accidents
 */
async function getAll(): Promise<Array<Accident>> {
  const url = `${apiBaseUrl}/${accidentsEndpoint}`;
  return fetch(url, {
    method: "GET",
    headers: await createHeaders(),
  })
    .then(errorHandlingService.defaultAndDefined)
    .then(networkService.getJsonData);
}

/**
 * Récupère tous les accidents entre deux dates (comprises)
 * Cette route n'est accessible qu'aux modérateurs
 * @param dateDebut la date de début
 * @param dateFin la date de fin
 * @returns Liste d'accidents
 */
async function getAllBetweenDates(dateDebut: Date, dateFin: Date): Promise<Array<Accident>> {
  const AllDeclarations: Array<Accident> = await getAll();
  const selectedDeclarations: Array<Accident> = [];

  AllDeclarations.forEach((d) => {
    if (dateUtil.isBetween(d.creationDate, dateDebut, dateFin)) {
      selectedDeclarations.push(d);
    }
  });

  return selectedDeclarations;
}

/**
 * Récupère tous les accidents de l'utilisateur courant
 * @returns Liste d'accidents
 */
async function getAllForCurrentUser(): Promise<Array<Accident>> {
  const url = `${apiBaseUrl}/${userAccidentsEndpoint}`;
  return fetch(url, {
    method: "GET",
    headers: await createHeaders(),
  })
    .then(errorHandlingService.defaultAndDefined)
    .then(networkService.getJsonData);
}

/**
 * Récupère tous les accidents de l'utilisateur courant et de ses subordonnées
 * @returns Liste d'accidents
 */
async function getAllForSubordinatesAndCurrentUser(): Promise<Array<Accident>> {
  const url = `${apiBaseUrl}/${userAndSubordinatesAccidentsEndpoint}`;
  return fetch(url, {
    method: "GET",
    headers: await createHeaders(),
  })
    .then(errorHandlingService.defaultAndDefined)
    .then(networkService.getJsonData);
}

/**
 * Récupère un accident en fonction de son id
 * @param id l'id de l'accident
 * @returns Accident
 */
async function getById(id: string): Promise<Accident> {
  const url = `${apiBaseUrl}/${accidentsEndpoint}/${id}`;
  return fetch(url, {
    method: "GET",
    headers: await createHeaders(),
    mode: "cors",
  })
    .then(errorHandlingService.defaultAndDefined)
    .then(networkService.getJsonData);
}

/**
 * Crée un nouvel accident
 * @returns Le nouvel accident créé
 */
async function create(declaration: Accident): Promise<Accident> {
  const url = `${apiBaseUrl}/${accidentsEndpoint}`;
  return fetch(url, {
    method: "POST",
    headers: await createHeaders(),
    mode: "cors",
    body: JSON.stringify(declaration),
  })
    .then((res) => {
      // 201 - Created = nouvel accident créé
      // 409 - Conflict = accident offline déjà envoyée
      switch (res.status) {
        case 201:
          return res;
        case 409:
          throw new Error(Errors.ALREADY_CREATED);
        default:
          throw new Error(`Error ${res.status} creating declaration`);
      }
    })
    .then(errorHandlingService.defaultAndDefined)
    .then(networkService.getJsonData);
}

/**
 * Met à jour un accident
 * __RESERVE AUX MODERATEURS__
 * @param declaration l'accident à mettre à jour
 * @param id optionnel - l'id de l'accident à mettre à jour, si absent
 * de l'accident lui-même
 * @returns L'accident mise à jour
 */
async function update(declaration: Accident, id?: string): Promise<Accident> {
  const url = `${apiBaseUrl}/${accidentsEndpoint}/${id ?? declaration.id}`;
  return fetch(url, {
    method: "PUT",
    headers: await createHeaders(),
    mode: "cors",
    body: JSON.stringify(declaration),
  })
    .then(errorHandlingService.defaultAndDefined)
    .then(networkService.getJsonData);
}

/**
 * Crée un nouvel accident
 * @returns Le nouvel accident créé
 */
async function requalify(declaration: Accident): Promise<Accident> {
  const url = `${apiBaseUrl}/${accidentsEndpoint}`;
  return fetch(url, {
    method: "POST",
    headers: await createHeaders(),
    mode: "cors",
    body: JSON.stringify(declaration),
  })
    .then((res) => {
      // TODO gérer le conflit si declaration déjà requalifiée et prévenir user
      if (res.status === 201) {
        return res;
      } else {
        throw new Error(`Error ${res.status} requalifying declaration`);
      }
    })
    .then(errorHandlingService.defaultAndDefined)
    .then(networkService.getJsonData);
}

/**
 * Récupère les photos d'un accident en fonction de son id
 * @param id l'id de l'accident
 * @returns Tableau d'urls des photos
 */
async function getPhotosById(id: string): Promise<Array<string>> {
  const url = `${apiBaseUrl}/${accidentsEndpoint}/${id}/${photosEndpoint}`;
  return fetch(url, {
    method: "GET",
    headers: await createHeaders(),
    mode: "cors",
  })
    .then(errorHandlingService.defaultAndDefined)
    .then(networkService.getJsonData);
}

/**
 * Complète un accident
 * @param declaration l'accident à compléter
 * @returns L'accident complété
 */
async function complete(declaration: Accident): Promise<Accident> {
  const url = `${apiBaseUrl}/${accidentsEndpoint}/${declaration.id}/${accidentCompleteEndpoint}`;
  return fetch(url, {
    method: "PUT",
    headers: await createHeaders(),
    mode: "cors",
    body: JSON.stringify(declaration),
  })
    .then(errorHandlingService.defaultAndDefined)
    .then(networkService.getJsonData);
}

const accidentService = {
  getAll,
  getAllBetweenDates,
  getAllForCurrentUser,
  getAllForSubordinatesAndCurrentUser,
  getById,
  create,
  update,
  complete,
  requalify,
  getPhotosById,
};
export default accidentService;
