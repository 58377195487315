import { useEffect, useState } from "react";
import type { ReactElement } from "react";
import { TextField, Autocomplete, type SxProps } from "@mui/material";
import AgentInfo from "components/inputs/agents/AgentInfo";
import type User from "models/User";
import graphService from "services/graphService";
import agentService from "services/agentService";
import type { RefCallBack } from "react-hook-form";

interface AgentSelectProps {
  agents: Array<User>;
  value?: User | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (...event: Array<any>) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onValueChange?: (...event: Array<any>) => void;
  readOnly?: boolean;
  defaultValue?: string;
  defaultAgent?: User;
  agentInfos?: boolean;
  label?: string;
  error?: boolean;
  helperText?: string;
  fullWidth?: boolean;
  size?: "small" | "medium";
  sx?: SxProps;
  variant?: "outlined" | "standard" | "filled";
  inputRef?: RefCallBack;
}

/**
 * Input de selection d'un agent
 * @param agents Array<User> Tableau de tous les agents
 * @param value User | null
 * @param onChange (...event: Array<any>) => void
 * @param onValueChange (...event: Array<any>) => void
 * @param readOnly boolean
 * @param defaultValue string valeur par défaut
 * @param agentInfos boolean Montrer ou non les informations telles que le département, le manager de l'agent
 * @param label string
 * @param error boolean
 * @param helperText string
 * @param fullWidth boolean
 * @param size "small" | "medium" | undefined
 * @param sx any
 * @param variant "outlined" | "standard" | "filled" | undefined
 * @param inputRef
 */
function AgentSelect({
  agents,
  value,
  onChange,
  onValueChange,
  readOnly = false,
  defaultValue = "",
  defaultAgent,
  agentInfos = false,
  label,
  error,
  helperText,
  fullWidth = false,
  size = "medium",
  sx = {},
  variant = "outlined",
  inputRef,
}: AgentSelectProps): ReactElement {
  const [selectedAgent, setSelectedAgent] = useState<User | null>(getStartingAgent());
  const [infoAgent, setInfoAgent] = useState<User | null>();
  const [inputValue, setInputValue] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [newAgents, setNewAgents] = useState<Array<User>>([]);

  function getStartingAgent(): User | null {
    if (defaultValue !== "") {
      const agent = agentService.getById(agents, defaultValue);
      return agent ?? defaultAgent ?? null;
    }
    return null;
  }

  useEffect(() => {
    async function updateInfoAgent(): Promise<void> {
      if (selectedAgent == null) return;

      const agentInformations = selectedAgent;
      agentInformations.manager = await graphService.getManagerByAgent(selectedAgent.id);

      setInfoAgent(agentInformations);
    }

    if (agentInfos) {
      void updateInfoAgent();
    }
  }, [value, selectedAgent, agentInfos]);

  function _onValueChange(_event: React.SyntheticEvent, data: User | null): void {
    if (data != null) {
      setSelectedAgent(data);
      if (onValueChange != null) onValueChange(data);
      if (onChange != null) onChange(data?.id);
    }
  }

  function onInputChange(_event: React.SyntheticEvent, newInput: string): void {
    if (newInput === "") {
      setSelectedAgent(null);
      setInfoAgent(null);
      if (onValueChange != null) onValueChange(null);
      if (onChange != null) onChange("");
    }
    setInputValue(newInput);
  }

  function getAgentLabel(user: User): string {
    if (user?.department != null && user.department !== "") {
      return `${user.displayName} (${user?.department})`;
    }
    return user.displayName;
  }

  useEffect(() => {
    const _agents = agents;
    _agents.sort((a: User, b: User) => {
      return a.displayName.localeCompare(b.displayName, "fr");
    });

    if (defaultAgent != null && defaultValue === defaultAgent.id) {
      setNewAgents([defaultAgent, ..._agents]);
    } else {
      setNewAgents(_agents);
    }
  }, [agents, defaultAgent, defaultValue]);

  return (
    <Autocomplete
      fullWidth={fullWidth}
      autoHighlight
      openOnFocus
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      size={size}
      value={value ?? selectedAgent}
      onChange={_onValueChange}
      inputValue={inputValue}
      onInputChange={onInputChange}
      isOptionEqualToValue={(user: User, otherUser: User) => user.id === otherUser.id}
      getOptionLabel={getAgentLabel}
      options={newAgents}
      open={open}
      onOpen={() => !readOnly && setOpen(true)}
      onClose={() => {
        setOpen(false);
      }}
      disableClearable={readOnly}
      sx={sx}
      renderInput={(params) => (
        <>
          <TextField
            {...params}
            variant={variant}
            label={label}
            placeholder="Nom Prénom"
            inputRef={inputRef}
            InputProps={{
              ...params.InputProps,
              readOnly,
            }}
            error={error}
            helperText={helperText}
          />
          {agentInfos && <AgentInfo user={infoAgent} />}
        </>
      )}
    />
  );
}

export default AgentSelect;
