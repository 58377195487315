import { cacheConfig } from "app-config";
import type { ControlledTextFieldOptions } from "components/inputs/ControlledTextField";
import networkService from "./networkService";

const cacheName = cacheConfig.territoires.name;

/**
 * Récupère tous les territoires
 * @returns Liste des territoires
 */
async function getAll(): Promise<Array<string>> {
  const url = `/data/territoires.json`;
  const options: RequestInit = {
    method: "GET",
    mode: "cors",
  };
  return networkService.fetchWithCacheFallback(url, options, cacheName).then((res) => res.json());
}

/**
 * Récupère tous les territoires sous la forme ControlledTextFieldOptions
 */
async function getAllTextFieldOptions(): Promise<ControlledTextFieldOptions> {
  return getAll().then((textTerritoires: Array<string>) => {
    const territoires: ControlledTextFieldOptions = [];
    textTerritoires.forEach((territoire) => {
      territoires.push({
        active: true,
        label: territoire,
        value: territoire,
      });
    });
    return territoires;
  });
}

const territoireService = {
  getAll,
  getAllTextFieldOptions,
};
export default territoireService;
