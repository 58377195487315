import { useEffect } from "react";
import type { ReactElement } from "react";
import { useLocation } from "react-router-dom";

/** Ce composant scrolle en haut de la page à la navigation */
function ScrollToTop(): ReactElement {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return <></>;
}

export default ScrollToTop;
