import { IconButton } from "@mui/material";
import {
  type SnackbarKey,
  SnackbarProvider as NotistackSnackbarProvider,
  type SnackbarProviderProps as NotistackSnackbarProviderProps,
} from "notistack";
import { createRef } from "react";
import type { ReactElement } from "react";
import CloseIcon from "@mui/icons-material/Close";

interface SnackbarProviderProps extends NotistackSnackbarProviderProps {}

function SnackbarProvider({
  maxSnack = 3,
  autoHideDuration = 10000, // 10s
  ...props
}: SnackbarProviderProps): ReactElement {
  // On ajoute à chaque snackbar un bouton pour la fermer
  const notistackRef = createRef<NotistackSnackbarProvider>();
  const onClickDismiss = (key: SnackbarKey) => () => {
    notistackRef?.current?.closeSnackbar(key);
  };

  function closeButton(key: SnackbarKey): ReactElement {
    return (
      <IconButton onClick={onClickDismiss(key)} sx={{ color: "white" }}>
        <CloseIcon />
      </IconButton>
    );
  }

  return (
    <NotistackSnackbarProvider
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      maxSnack={maxSnack}
      autoHideDuration={autoHideDuration}
      ref={notistackRef}
      action={closeButton}
      preventDuplicate
      {...props}
    />
  );
}

export default SnackbarProvider;
