import { apiConfig, authenticationConfig, cacheConfig } from "app-config";
import AuthService from "services/authService";
import type User from "models/User";
import networkService from "./networkService";

/**
 * Génère les headers pour la requête http vers l'API graph
 * @returns
 */
async function createHeaders(): Promise<HeadersInit> {
  const accessToken = await AuthService.getAccessToken(apiConfig.azureAd.scopes);
  return {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${accessToken}`,
    ConsistencyLevel: "eventual",
  };
}

const cacheName = cacheConfig.agents.name;

/**
 * Récupère la totalité des agents
 * @param selectFilter les informations supplémentaires précises que
 * l'on souhaite récupérer sur les agents
 * @returns
 */
async function getAllAgents(selectFilter: Array<string>): Promise<Array<User>> {
  const options = {
    method: "GET",
    headers: await createHeaders(),
  };

  let url = `${authenticationConfig.graph.graphEndpoint}/users?$top=999`;
  let filters = ["surname", "givenName", "displayName", "id", "jobTitle"];
  if (selectFilter.length > 0) {
    filters = filters.concat(selectFilter);
  }
  url += "&$select=" + filters.toString();
  url += "&$filter=employeeId ne null&$count=true";

  return networkService
    .fetchWithCacheFallback(url, options, cacheName)
    .then(networkService.getJsonValue)
    .catch((error) => {
      console.error(error);
    });
}

/**
 * Récupère le manager d'un agent
 * @param id l'id de l'agent
 * @returns
 */
async function getManagerByAgent(id: string): Promise<User> {
  const url = `${authenticationConfig.graph.graphEndpoint}/users/${id}/manager`;
  const options = {
    method: "GET",
    headers: await createHeaders(),
  };

  return networkService
    .fetchWithCacheFallback(url, options, cacheName)
    .then((res) => res.json())
    .catch((error) => {
      console.error(error);
    });
}

/**
 * Récupère les subordonnés d'un manager
 * @returns Un tableau des ids des subordonnés ou void
 */
async function getDirectReports(): Promise<Array<string>> {
  const url = `${authenticationConfig.graph.graphEndpoint}/me/directReports?$select=employeeId`;
  const options = {
    method: "GET",
    headers: await createHeaders(),
  };

  return networkService
    .fetchWithCacheFallback(url, options, cacheName)
    .then((res) => res.json())
    .then((result) => {
      let directReportsIds: Array<string> = [];
      if (result?.value?.length > 0) {
        directReportsIds = result.value.map((o: User) => {
          return o?.id;
        });
      }
      return directReportsIds;
    })
    .catch((error) => {
      console.error("Error getting Direct Reports", error);
      return [];
    });
}

/**
 * Récupère les informations de base de l'utilisateur
 * @returns l'utilisateur
 */
async function getUserInfo(): Promise<User> {
  const userInfos = ["displayName", "employeeId", "mail", "id", "jobTitle"];
  const url = `${authenticationConfig.graph.graphEndpoint}/me?$select=${userInfos.join(",")}`;
  const options = {
    method: "GET",
    headers: await createHeaders(),
  };

  return networkService
    .fetchWithCacheFallback(url, options, cacheName)
    .then((res) => res.json())
    .catch((error) => {
      console.error(error);
    });
}

/**
 * Récupère l'image de profil de l'utilisateur
 * @returns string contenant l'image ou undefined
 */
async function getUserPhoto(): Promise<string | undefined> {
  const url = `${authenticationConfig.graph.graphEndpoint}/me/photo/$value`;
  const options = {
    method: "GET",
    headers: await createHeaders(),
  };

  return await fetch(url, options)
    .then(async (res) => res.blob())
    .then(async (blob) => {
      const base64data = await readFileAsDataURL(blob);
      if (base64data != null) {
        if (typeof base64data === "string") {
          return base64data.toString();
        } else {
          return new TextDecoder().decode(base64data);
        }
      }
    })
    .catch((error: Error) => {
      console.error(error);
      return undefined;
    });
}

async function readFileAsDataURL(file: Blob): Promise<string | ArrayBuffer | null> {
  const resultBase64 = await new Promise<string | ArrayBuffer | null>((resolve) => {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.readAsDataURL(file);
  });

  return resultBase64;
}

const graphService = {
  getAllAgents,
  getUserInfo,
  getUserPhoto,
  getManagerByAgent,
  getDirectReports,
};

export default graphService;
