import type { ReactElement } from "react";
import ConfirmationCard from "./ConfirmationCard";

interface ConfirmationCardVictimeProps {
  onConfirm: (b: boolean) => Promise<void>;
  onRefute: () => void;
  loading: boolean;
}
/**
 * Card de sélection du choix de confirmation de la victime pour les accidents bénins
 */
function ConfirmationCardVictime({
  onConfirm,
  onRefute,
  loading,
}: ConfirmationCardVictimeProps): ReactElement {
  const title = "Confirmation requise";
  const text =
    "Vous avez été identifié comme la victime de l'accident bénin décrit ci-dessous, merci de bien vouloir en prendre connaissance et de confirmer l'exactitude des faits.";
  const noText =
    "Non, je ne suis pas la victime de cet accident bénin, ou les faits ne sont pas exacts.";
  const yesText = "Oui, je confirme que je suis bien la victime de cet accident bénin.";

  return (
    <ConfirmationCard
      title={title}
      text={text}
      noText={noText}
      yesText={yesText}
      onConfirm={onConfirm}
      onRefute={onRefute}
      loading={loading}
    />
  );
}

export default ConfirmationCardVictime;
