import { apiConfig } from "app-config";
import Errors from "constants/Errors";
import errorHandlingService from "./errorHandlingService";
import networkService from "./networkService";
import type { Securite } from "models/Securite";
import { dateUtil } from "@sdeapps/react-core";

const apiBaseUrl = `${apiConfig.apimBaseUrl}/${apiConfig.declarationApi.apiName}`;
const scopes = apiConfig.declarationApi.scopes;

const securitesEndpoint = "securites";
const userSecuritesEndpoint = "me/securites";
const userAsSstSecuritesEndpoint = "me/sst/securites";
const userAndSubordinatesSecuritesEndpoint = "me/directReports/securites";
const photosEndpoint = "photos";

/**
 * Génère les headers pour la requête http
 * @returns Les Headers
 */
async function createHeaders(): Promise<HeadersInit> {
  return networkService.createHeaders(scopes);
}

/**
 * Récupère toutes les sécurités
 * Cette route n'est accessible qu'aux modérateurs
 * @returns Liste de sécurités
 */
async function getAll(): Promise<Array<Securite>> {
  const url = `${apiBaseUrl}/${securitesEndpoint}`;
  return fetch(url, {
    method: "GET",
    headers: await createHeaders(),
  })
    .then(errorHandlingService.defaultAndDefined)
    .then(networkService.getJsonData);
}

/**
 * Récupère toutes les sécurités entre deux dates (comprises)
 * Cette route n'est accessible qu'aux modérateurs
 * @param dateDebut la date de début
 * @param dateFin la date de fin
 * @returns Liste de sécurités
 */
async function getAllBetweenDates(dateDebut: Date, dateFin: Date): Promise<Array<Securite>> {
  const AllDeclarations: Array<Securite> = await getAll();
  const selectedDeclarations: Array<Securite> = [];

  AllDeclarations.forEach((d) => {
    if (dateUtil.isBetween(d.creationDate, dateDebut, dateFin)) {
      selectedDeclarations.push(d);
    }
  });

  return selectedDeclarations;
}

/**
 * Récupère toutes les sécurités dont l'utilisateur courant est victime ou créateur
 * @returns Liste de sécurités
 */
async function getAllWhereCurrentUserIsVictimOrCreator(): Promise<Array<Securite>> {
  const url = `${apiBaseUrl}/${userSecuritesEndpoint}`;
  return fetch(url, {
    method: "GET",
    headers: await createHeaders(),
  })
    .then(errorHandlingService.defaultAndDefined)
    .then(networkService.getJsonData);
}

/**
 * Récupère toutes les sécurités dont l'utilisateur courant est déclaré sauveteur
 * @returns Liste de sécurités
 */
async function getAllWhereCurrentUserIsSst(): Promise<Array<Securite>> {
  const url = `${apiBaseUrl}/${userAsSstSecuritesEndpoint}`;
  return fetch(url, {
    method: "GET",
    headers: await createHeaders(),
  })
    .then(errorHandlingService.defaultAndDefined)
    .then(networkService.getJsonData);
}

/**
 * Récupère toutes les sécurités dont l'utilisateur courant et ses subordonnées sont victimes ou créateurs
 * @returns Liste de sécurités
 */
async function getAllWhereCurrentUserAndSubordinatesAreVictimsOrCreator(): Promise<
  Array<Securite>
> {
  const url = `${apiBaseUrl}/${userAndSubordinatesSecuritesEndpoint}`;
  return fetch(url, {
    method: "GET",
    headers: await createHeaders(),
  })
    .then(errorHandlingService.defaultAndDefined)
    .then(networkService.getJsonData);
}

/**
 * Récupère toutes les sécurités dont l'utilisateur courant et ses subordonnées sont victimes ou créateurs
 * ainsi que les sécurités dont l'utilisateur courant est sauveteur
 * @returns Liste de sécurités
 */
async function getAllForCurrentUser(): Promise<Array<Securite>> {
  const currentUserAndSubordinatesAsVictimsOrCreators =
    await securiteService.getAllWhereCurrentUserAndSubordinatesAreVictimsOrCreator();
  const currentUserAsSst = await securiteService.getAllWhereCurrentUserIsSst();

  return currentUserAndSubordinatesAsVictimsOrCreators.concat(currentUserAsSst);
}

/**
 * Récupère une securite en fonction de son id
 * @param id l'id de la securite
 * @returns Securite
 */
async function getById(id: string): Promise<Securite> {
  const url = `${apiBaseUrl}/${securitesEndpoint}/${id}`;
  return fetch(url, {
    method: "GET",
    headers: await createHeaders(),
    mode: "cors",
  })
    .then(errorHandlingService.defaultAndDefined)
    .then(networkService.getJsonData);
}

/**
 * Crée une nouvelle sécurité
 * @returns La nouvelle sécurité créée
 */
async function create(declaration: Securite): Promise<Securite> {
  const url = `${apiBaseUrl}/${securitesEndpoint}`;
  return fetch(url, {
    method: "POST",
    headers: await createHeaders(),
    mode: "cors",
    body: JSON.stringify(declaration),
  })
    .then((res) => {
      // 201 - Created = nouvelle sécurité créée
      // 409 - Conflict = sécurité offline déjà envoyée
      switch (res.status) {
        case 201:
          return res;
        case 409:
          throw new Error(Errors.ALREADY_CREATED);
        default:
          throw new Error(`Error ${res.status} creating declaration`);
      }
    })
    .then(errorHandlingService.defaultAndDefined)
    .then(networkService.getJsonData);
}

/**
 * Met à jour une securite
 * @param declaration la securite à mettre à jour
 * @param id optionnel - l'id de la securite à mettre à jour, si absent
 * de la securite elle-même
 * @returns La securite mise à jour
 */
async function update(declaration: Securite, id?: string): Promise<Securite> {
  const url = `${apiBaseUrl}/${securitesEndpoint}/${id ?? declaration.id}`;
  return fetch(url, {
    method: "PUT",
    headers: await createHeaders(),
    mode: "cors",
    body: JSON.stringify(declaration),
  })
    .then(errorHandlingService.defaultAndDefined)
    .then(networkService.getJsonData);
}

/**
 * Crée une nouvelle sécurité
 * @returns La nouvelle sécurité créée
 */
async function requalify(declaration: Securite): Promise<Securite> {
  const url = `${apiBaseUrl}/${securitesEndpoint}`;
  return fetch(url, {
    method: "POST",
    headers: await createHeaders(),
    mode: "cors",
    body: JSON.stringify(declaration),
  })
    .then((res) => {
      if (res.status === 201) {
        return res;
      } else {
        throw new Error(`Error ${res.status} requalifying declaration`);
      }
    })
    .then(errorHandlingService.defaultAndDefined)
    .then(networkService.getJsonData);
}

/**
 * Met à jour uniquement une partie des champs d'une securite
 * @param declaration la securite à mettre à jour
 * @param id optionnel - l'id de la securite à mettre à jour, si absent
 * de la securite elle-même
 * @returns La securite mise à jour
 */
async function patch(declaration: Securite, id?: string): Promise<Securite> {
  const url = `${apiBaseUrl}/${securitesEndpoint}/${id ?? declaration.id}`;
  return fetch(url, {
    method: "PATCH",
    headers: await createHeaders(),
    mode: "cors",
    body: JSON.stringify(declaration),
  })
    .then((res) => {
      if (res.status === 200) {
        return res;
      } else {
        throw new Error(`Error ${res.status} patching declaration`);
      }
    })
    .then(errorHandlingService.defaultAndDefined)
    .then(networkService.getJsonData);
}

/**
 * Met à jour la confirmation du SST dans un accident bénin
 * @param confirmationSST boolean : confirmation du SST
 * @param id l'id de l'accident bénin à mettre à jour
 * @returns L'accident bénin mis à jour
 */
async function confirmSST(
  confirmationSST: boolean,
  id: string,
  comment?: string
): Promise<Securite> {
  const securite = {
    accidentBeninConfirmationSauveteur: confirmationSST,
    accidentBeninCommentaireSauveteur: comment ?? null,
  };
  return patch(securite as Securite, id);
}

/**
 * Met à jour la confirmation de la victime dans un accident bénin
 * @param confirmationVictim boolean : confirmation de la victime
 * @param id l'id de l'accident bénin à mettre à jour
 * @returns L'accident bénin mis à jour
 */
async function confirmVictim(confirmationVictim: boolean, id: string): Promise<Securite> {
  const securite = {
    accidentBeninConfirmationVictime: confirmationVictim,
  };
  return patch(securite as Securite, id);
}

/**
 * Récupère les photos d'une securite en fonction de son id
 * @param id l'id de la securite
 * @returns Tableau d'urls des photos
 */
async function getPhotosById(id: string): Promise<Array<string>> {
  const url = `${apiBaseUrl}/${securitesEndpoint}/${id}/${photosEndpoint}`;
  return fetch(url, {
    method: "GET",
    headers: await createHeaders(),
    mode: "cors",
  })
    .then(errorHandlingService.defaultAndDefined)
    .then(networkService.getJsonData);
}

const securiteService = {
  getAll,
  getAllForCurrentUser,
  getAllBetweenDates,
  getAllWhereCurrentUserIsVictimOrCreator,
  getAllWhereCurrentUserIsSst,
  getAllWhereCurrentUserAndSubordinatesAreVictimsOrCreator,
  getById,
  create,
  update,
  requalify,
  confirmSST,
  confirmVictim,
  getPhotosById,
};
export default securiteService;
