import { Button, CircularProgress, Container, Fade, Grid } from "@mui/material";
import Online from "components/utils/onlineStatus/Online";
import Offline from "components/utils/onlineStatus/Offline";
import WifiOffIcon from "@mui/icons-material/WifiOff";
import { useMsal } from "@azure/msal-react";
import { apiConfig } from "app-config";
import type { ReactElement } from "react";

/**
 * Page appelée si l'utilisateur n'est pas connecté; lance la connexion
 */
function ConnectionPage(): ReactElement {
  const { instance } = useMsal();

  function login(): void {
    void instance.loginRedirect({
      account: instance.getActiveAccount() ?? undefined,
      ...apiConfig.azureAd,
    });
  }

  return (
    <Container maxWidth="md">
      <Grid container direction="column" alignItems="center" justifyContent="center" width="100%">
        <Online>
          <Grid item>
            <CircularProgress />
          </Grid>
          <Grid item>
            <p>Connexion en cours...</p>
          </Grid>
          <Grid item>
            {/* Petite sécurité supplémentaire : Un bouton qui apparaît
              au bout de 5s pour lancer manuellement la connexion */}
            <Fade
              style={{ transitionDelay: `${5 * 1000}ms` }}
              timeout={1000}
              easing="ease-out"
              in={true}>
              <Button onClick={login} variant="contained">
                Se connecter manuellement
              </Button>
            </Fade>
          </Grid>
        </Online>
        <Offline>
          <Grid item>
            <WifiOffIcon fontSize="large" />
          </Grid>
          <Grid item textAlign="center">
            <p>Vous êtes hors ligne.</p>
            <p>
              Pour pouvoir utiliser <strong>Eau Secours</strong> hors ligne, il est nécessaire de
              s'être préalablement authentifié en se connectant à l'application via Internet.
            </p>
          </Grid>
          <Grid item>
            <Button onClick={login} variant="contained">
              Se connecter manuellement
            </Button>
          </Grid>
        </Offline>
      </Grid>
    </Container>
  );
}

export default ConnectionPage;
