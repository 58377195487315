import { Skeleton, Typography } from "@mui/material";
import type { ReactElement } from "react";
import type { EditionDetails } from "./useDeclarationCreationDetails";

type CreationDetailsSubtitleProps = {
  isLoading: boolean;
  creationDetails?: EditionDetails;
  isSmallScreen?: boolean;
};

const emStyle = { fontStyle: "normal", fontWeight: 600 };

function CreationDetailsSubtitle({
  isLoading,
  creationDetails,
  isSmallScreen = false,
}: CreationDetailsSubtitleProps): ReactElement {
  if (isLoading) {
    return <Skeleton width="50%" />;
  }

  return (
    <Typography variant={isSmallScreen ? "subtitle2" : "subtitle1"} gutterBottom>
      {"créée par "}
      <em style={emStyle}>{creationDetails?.auteur}</em>
      {" le "}
      <em style={emStyle}>{creationDetails?.date}</em>
      {` à ${creationDetails?.time}`}
    </Typography>
  );
}

export default CreationDetailsSubtitle;
