const lesions = [
  "Amputation",
  "Asphyxie",
  "Brûlure chimique",
  "Brûlure électrique",
  "Brûlure thermique",
  "Choc traumatique",
  "Commotion",
  "Déchirure musculaire",
  "Douleurs",
  "Electrisation, électrocution",
  "Entorse",
  "Fracture",
  "Gelûre",
  "Hématome",
  "Hernie",
  "Infection biologique",
  "Intoxication",
  "Lésions internes",
  "Lésions nerveuses",
  "Lumbago",
  "Luxation",
  "Morsure",
  "Piqûre",
  "Plaie",
  "Présence corp étrangers",
  "Réaction allergique",
  "Réaction cutanée (dermite)",
  "Traumatisme psychologique",
  "Troubles auditifs",
  "Troubles visuels",
  "Autre nature de lésion",
];

export default lesions;
