import { useEffect } from "react";
import type { ReactElement } from "react";
import { DEFAULT_PAGE_TITLE, routesConfig } from "app-config";
import { matchRoutes, useLocation } from "react-router-dom";

const tempRoutes = Object.keys(routesConfig).map((v) => {
  return { path: routesConfig[v].path, element: routesConfig[v].title };
});

/** Ce composant change le title de la page selon le routing dans routesConfig */
function TitlesUtil(): ReactElement {
  const location = useLocation();

  useEffect(() => {
    const match = matchRoutes(tempRoutes, location);
    let title: string | undefined;
    if (match != null) {
      title = match[0]?.route?.element;
    }
    document.title = title ?? DEFAULT_PAGE_TITLE;
  }, [location]);

  return <></>;
}

export default TitlesUtil;
