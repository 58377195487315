import { useState } from "react";
import type { ReactElement } from "react";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  CardActions,
  CardHeader,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormControl,
  TextField,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import LoadingButton from "components/buttons/LoadingButton";

interface ConfirmationCardProps {
  title?: string;
  text?: string;
  noText?: string;
  yesText?: string;
  onConfirm: (b: boolean, comment?: string) => Promise<void>;
  onRefute: () => void;
  loading?: boolean;
  comment?: boolean;
  commentLabel?: string;
}

/**
 * Card de sélection du choix de confirmation pour les accidents bénins
 */
function ConfirmationCard({
  title = "",
  text = "",
  noText = "",
  yesText = "",
  onConfirm,
  onRefute,
  loading = false,
  comment,
  commentLabel = "",
}: ConfirmationCardProps): ReactElement {
  const [confirmation, setConfirmation] = useState("");
  const [commentSST, setCommentSST] = useState<string>();

  function onChange(_event: unknown, value: string): void {
    setConfirmation(value);
  }

  function onClick(): void {
    if (confirmation === "yes") {
      void onConfirm(true, commentSST);
    } else {
      onRefute();
    }
  }

  return (
    <Card sx={{ background: "#e3f2fd" }} variant="outlined">
      <CardHeader title={title} />
      <CardContent>
        <Typography color="secondary" gutterBottom>
          {text}
        </Typography>
        <FormControl>
          <RadioGroup name="radio-buttons-group" value={confirmation} onChange={onChange}>
            <FormControlLabel value="no" control={<Radio />} label={noText} />
            <FormControlLabel value="yes" control={<Radio />} label={yesText} />
          </RadioGroup>
        </FormControl>
        {(comment ?? false) && (
          <TextField
            value={commentSST}
            onChange={({ target: { value } }) => {
              setCommentSST(value);
            }}
            label={commentLabel}
            sx={{ background: "white", mt: 2 }}
            multiline
            fullWidth
          />
        )}
      </CardContent>
      <CardActions sx={{ m: 1, mt: 0 }}>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <LoadingButton
              variant="contained"
              loading={loading}
              endIcon={<SendIcon />}
              disabled={confirmation === ""}
              type="button"
              onClick={onClick}>
              Confirmer
            </LoadingButton>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
}

export default ConfirmationCard;
