import { frFR } from "@mui/material/locale";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { forwardRef, type PropsWithChildren, type ReactElement } from "react";
import { Link as RouterLink, type LinkProps as RouterLinkProps } from "react-router-dom";
import type { LinkProps } from "@mui/material/Link";

/** Ceci sert à customizer les composants MUI Link et Button pour utiliser
 * le lien de react-router avec la prop "href" mappée sur "to"
 */
// eslint-disable-next-line react/display-name
const LinkBehavior = forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, "to"> & { href: RouterLinkProps["to"] }
>((props, ref) => {
  const { href, ...other } = props;
  // Map href (MUI) -> to (react-router)
  return <RouterLink ref={ref} to={href} {...other} />;
});

const theme = {
  palette: {
    primary: {
      light: "#e0f3f8",
      main: "#017cac",
      dark: "#01638a",
    },
    secondary: { main: "#4B515D" },
    info: {
      light: "#eff1f8",
      main: "#617abb",
      dark: "#27314b",
    },
    success: {
      light: "#e5f4f0",
      main: "#02986a",
      dark: "#013d2a",
    },
    warning: {
      light: "#fff8ed",
      main: "#ffbf4d",
      dark: "#664c1f",
    },
    error: {
      light: "#fcefed",
      main: "#e1604d",
      dark: "#5a261f",
    },
  },
  /** On customize les composants MUI Link et Button pour utiliser
   * le lien de react-router avec la prop "href" mappée sur "to"
   */
  components: {
    MuiLink: {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
  },
};

const SdeaTheme = createTheme(theme, frFR);

function SDEAThemeProvider({ children }: PropsWithChildren): ReactElement {
  return <ThemeProvider theme={SdeaTheme}>{children}</ThemeProvider>;
}

export default SDEAThemeProvider;
