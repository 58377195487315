import { Slide } from "@mui/material";
import type { TransitionProps } from "@mui/material/transitions";
import { forwardRef, type ReactElement, type Ref } from "react";

export const TransitionSlideUp: any | undefined = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement;
  },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const spinningAnimation = { animation: "spin 4s linear infinite" };
