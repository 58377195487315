const primaryColor = "#026C99";
const disabledColor = "#BDC5C8";

export const ACCIDENTTABSCHILDRENCONTAINERSTYLES = {
  pt: "20px",
  border: `1px ${primaryColor} solid`,
  borderTop: "none",
};

export const ACCIDENTTABSSTYLES = {
  "&.SDEA-accident-tabs-root": {
    ".SDEA-accident-tab-grid-item": {
      zIndex: 0,
      ".SDEA-accident-tab-box": {
        textAlign: "center",
        border: `1px ${primaryColor} solid`,
        borderRadius: "6px 6px 0 0",
        height: "100%",
        display: "block",
        textDecoration: "none",
        fontWeight: 700,
        backgroundColor: primaryColor,
        color: "white",
        boxShadow:
          "rgba(0, 0, 0, 0.2) inset 0 -3px 1px -2px, rgba(0, 0, 0, 0.14) inset 0 -2px 2px 0px",
      },
      "&.SDEA-accident-tab-active": {
        zIndex: 1,
        ".SDEA-accident-tab-box": {
          borderBottom: "none",
          backgroundColor: "white",
          color: primaryColor,
          boxShadow: "none",
        },
        "&.SDEA-accident-tab-general .SDEA-accident-tab-box": {
          boxShadow: "rgba(0, 0, 0, 0.2) 5px -3px 1px -2px, rgba(0, 0, 0, 0.14) 5px -2px 2px 0px",
        },
        "&.SDEA-accident-tab-complement .SDEA-accident-tab-box": {
          boxShadow: "rgba(0, 0, 0, 0.2) -5px -3px 1px -2px, rgba(0, 0, 0, 0.14) -5px -2px 2px 0px",
        },
      },
      "&.SDEA-accident-tab-disabled .SDEA-accident-tab-box": {
        backgroundColor: disabledColor,
        borderColor: `${disabledColor} ${disabledColor} ${primaryColor} ${disabledColor}`,
      },
    },
  },
};
