import type { AuthenticationConfig } from "@sdeapps/react-core";
import type { To } from "react-router-dom";

const APPLICATION_NAME = "Eau Secours";
const DEFAULT_PAGE_TITLE = "Eau Secours";

const config = {
  basename: import.meta.env.PUBLIC_PATH,
  isBrowser: typeof window !== "undefined",
  appName: APPLICATION_NAME,
  appVersion: import.meta.env.VITE_VERSION ?? "?",
};

type RoutesConfig = Record<
  string,
  {
    path: string;
    getParameterPath: (parameter: string) => To;
    title: string;
  }
>;

const routesConfig: RoutesConfig = {
  home: { path: "/", getParameterPath: (_) => "/", title: DEFAULT_PAGE_TITLE },

  accident: {
    path: "/accident/:declarationId",
    getParameterPath: (declarationId) => `/accident/${declarationId}`,
    title: "Consultation : Informations générales",
  },
  accidentComplement: {
    path: "/accident/complement/:declarationId",
    getParameterPath: (declarationId) => `/accident/complement/${declarationId}`,
    title: "Consultation : Informations complémentaires",
  },
  securite: {
    path: "/securite/:declarationId",
    getParameterPath: (declarationId) => `/securite/${declarationId}`,
    title: "Consultation",
  },

  accidentPhotos: {
    path: "/accident/:declarationId/photos",
    getParameterPath: (declarationId) => `/accident/${declarationId}/photos`,
    title: "Photos",
  },
  securitePhotos: {
    path: "/securite/:declarationId/photos",
    getParameterPath: (declarationId) => `/securite/${declarationId}/photos`,
    title: "Photos",
  },
  dangerGravePhotos: {
    path: "/danger/:declarationId/photos",
    getParameterPath: (declarationId) => `/danger/${declarationId}/photos`,
    title: "Photos",
  },

  new: { path: "/new", getParameterPath: (_) => "/new", title: "Nouvelle Déclaration" },
  accidentBenin: {
    path: "/accident-benin",
    getParameterPath: (_) => "/accident-benin",
    title: "Nouvel Accident Bénin",
  },
  accidentTravail: {
    path: "/accident-travail",
    getParameterPath: (_) => "/accident-travail",
    title: "Nouvel Accident de travail",
  },
  accidentTrajet: {
    path: "/accident-trajet",
    getParameterPath: (_) => "/accident-trajet",
    title: "Nouvel Accident de trajet",
  },
  presquAccident: {
    path: "/presqu-accident",
    getParameterPath: (_) => "/presqu-accident",
    title: "Nouveau Presqu'accident",
  },
  situationDangereuse: {
    path: "/situation-dangereuse",
    getParameterPath: (_) => "/situation-dangereuse",
    title: "Nouvelle Situation dangereuse",
  },
  dangerGrave: {
    path: "/danger",
    getParameterPath: (_) => "/danger",
    title: "Nouveau Danger grave et imminent",
  },

  photo: {
    path: `photo/:photoUrl`,
    getParameterPath: (photoUrl) => `photo/${photoUrl}`,
    title: "Photo",
  },
  flush: { path: "/flush", getParameterPath: (_) => "/flush", title: "Eau Secours logout" },
  legal: { path: "/legal", getParameterPath: (_) => "/legal", title: "Mentions Légales" },
  enSavoirPlus: {
    path: "/en_savoir_plus",
    getParameterPath: (_) => "/en_savoir_plus",
    title: "En savoir plus",
  },
  any: { path: "*", getParameterPath: (_) => "*", title: "Not Found" },
};

const authenticationConfig: AuthenticationConfig = {
  msal: {
    auth: {
      clientId: import.meta.env.VITE_AUTH_CLIENT_ID,
      authority: import.meta.env.VITE_AUTH_AUTHORITY,
      knownAuthorities: [import.meta.env.VITE_AUTH_AUTHORITY],
      redirectUri: import.meta.env.VITE_APP_BASE_URL,
      postLogoutRedirectUri: import.meta.env.VITE_APP_BASE_URL,
    },
    cache: {
      cacheLocation: "localStorage",
      claimsBasedCachingEnabled: true,
    },
  },
  graph: {
    graphEndpoint: "https://graph.microsoft.com/v1.0",
    scopes: ["User.Read", "User.ReadBasic.All", "User.Read.All"],
  },
};

const apiConfig = {
  apimBaseUrl: import.meta.env.VITE_APIM_BASE_URL,
  apimSubscriptionKey: import.meta.env.VITE_APIM_SUBSCRIPTION_KEY,
  azureAd: {
    scopes: ["User.Read", "User.ReadBasic.All", "User.Read.All"],
  },
  declarationApi: {
    scopes: import.meta.env.VITE_DECLARATIONS_SCOPES?.split(" "),
    apiName: import.meta.env.VITE_DECLARATIONS_API_NAME,
  },
  ouvragesApi: {
    scopes: import.meta.env.VITE_OUVRAGES_SCOPES?.split(" "),
    apiName: import.meta.env.VITE_OUVRAGES_API_NAME,
  },
};

const appiConfig = {
  instrumentationKey: import.meta.env.VITE_APPINSIGHTS_INSTRUMENTATIONKEY,
};

const envConfig = {
  name: import.meta.env.VITE_ENV,
};

const localStorageKeys = {
  RGPD: "isAcceptingRGPD",
  introText: "IntroText",
  currentUserData: "currentUserData",
};

const oneDayMs = 24 * 60 * 60; // 1 jour en millisecondes
const defaultCacheMaxAge = 100 * oneDayMs; // 100 jours
const cacheConfig = {
  ouvrages: {
    name: "ouvrages",
    matchUrl: new RegExp(`${apiConfig.apimBaseUrl}/${apiConfig.ouvragesApi.apiName}`, "i"),
    strategy: "NetworkFirst",
    maxAge: defaultCacheMaxAge,
  },
  territoires: {
    name: "territoires",
    matchUrl: /data\/territoires/i,
    strategy: "NetworkFirst",
    maxAge: defaultCacheMaxAge,
  },
  communes: {
    name: "communes",
    matchUrl: /https:\/\/geo\.api\.gouv\.fr\/communes/i,
    strategy: "NetworkFirst",
    maxAge: defaultCacheMaxAge,
  },
  agents: {
    name: "agents",
    matchUrl: new RegExp(`${authenticationConfig.graph.graphEndpoint}/users`, "i"),
    strategy: "NetworkFirst",
    maxAge: defaultCacheMaxAge,
  },
  env: {
    name: "env",
    matchUrl: /data\/env/i,
    strategy: "CacheFirst",
    maxAge: 365 * oneDayMs, // 1 an
  },
  microsoftlogin: {
    name: "microsoftlogin",
    matchUrl: new RegExp(import.meta.env.VITE_AUTH_AUTHORITY ?? "", "i"),
    strategy: "NetworkFirst",
    maxAge: 365 * oneDayMs, // 1 an
  },
};

export {
  config,
  authenticationConfig,
  apiConfig,
  cacheConfig,
  DEFAULT_PAGE_TITLE,
  routesConfig,
  localStorageKeys,
  appiConfig,
  envConfig,
};
