import { useState, useEffect, useCallback, type ReactElement } from "react";
import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  FormHelperText,
  type SelectChangeEvent,
} from "@mui/material";
import type Ouvrage from "models/Ouvrage";

interface OuvrageSelectProps {
  allOuvrages: Array<Ouvrage>;
  typeOuvrage: string;
  communeINSEE: string;
  setPosteTechnique: (s: string) => void;
  setOuvrageDescription: (s: string) => void;
  defaultOuvrage?: Ouvrage;
  readOnly?: boolean;
  notOuvrageTypeString?: string;
  error?: boolean;
  helperText?: string;
}

function OuvrageSelect({
  allOuvrages,
  typeOuvrage,
  communeINSEE,
  setPosteTechnique,
  setOuvrageDescription,
  defaultOuvrage,
  readOnly = false,
  notOuvrageTypeString,
  error,
  helperText = "",
}: OuvrageSelectProps): ReactElement {
  const [inseeRegex, setInseeRegex] = useState<RegExp>(/.*/);
  const [value, setValue] = useState<string>(defaultOuvrage?.posteTechnique ?? "");
  const [filteredOuvrages, setFilteredOuvrages] = useState<Array<Ouvrage>>(allOuvrages);
  const [cheatingFirstRenders, setCheatingFirstRenders] = useState<number>(0);

  const regroupement = [
    { typeObjet: "RES", regroupement: "OEP" },
    { typeObjet: "SOU", regroupement: "OEP" },
    { typeObjet: "STP", regroupement: "OEP" },
    { typeObjet: "STR", regroupement: "OEP" },
    { typeObjet: "STT", regroupement: "OEP" },
    { typeObjet: "REG", regroupement: "OEP" },
    { typeObjet: "DES", regroupement: "OEP" },
    { typeObjet: "CPT", regroupement: "OEP" },
    { typeObjet: "PUI", regroupement: "OEP" },
    { typeObjet: "DO", regroupement: "DO" },
    { typeObjet: "EPU", regroupement: "EPU" },
    { typeObjet: "REF", regroupement: "PPEU" },
    { typeObjet: "REL", regroupement: "PPEU" },
    { typeObjet: "BOR", regroupement: "PPEU" },
    { typeObjet: "H2S", regroupement: "PPEU" },
    { typeObjet: "PDE", regroupement: "PPEU" },
  ];

  const filterOuvrages = useCallback(
    (options: Array<Ouvrage>) => {
      function ouvrageFilter(ouvrage: Ouvrage): boolean {
        return (
          regroupement
            .filter((r) => r.regroupement === typeOuvrage)
            ?.find((o) => ouvrage.typeObjet === o.typeObjet) != null &&
          inseeRegex.test(ouvrage.posteTechnique)
        );
      }
      const filteredOptions = options.filter(ouvrageFilter);
      if (
        defaultOuvrage != null &&
        value === defaultOuvrage.posteTechnique &&
        filteredOptions.find((o) => o.posteTechnique === defaultOuvrage.posteTechnique) == null
      ) {
        filteredOptions.push(defaultOuvrage);
      }
      return filteredOptions;
    },
    [inseeRegex, typeOuvrage]
  );

  /** Lorsque la commune sélectionnée change, on met à jour la Regex de
   * filtre des postes techniques */
  useEffect(() => {
    let insee = communeINSEE !== "" ? communeINSEE : ".*";
    if (insee.startsWith("67")) {
      insee = insee.substring(2, 5);
    }
    const r = new RegExp(`[0-9]-[0-9]+-${insee}-[a-z]-[a-z]+-[0-9]+`, "i");
    setInseeRegex(r);
  }, [communeINSEE]);

  // Lorsque l'un des paramètres de filtrage change, on refiltre les ouvrages
  useEffect(() => {
    setFilteredOuvrages(filterOuvrages(allOuvrages));
  }, [inseeRegex, typeOuvrage, allOuvrages]);

  /** Lorsque le filtre ou la valeur change, on vérifie que la valeur
   * sélectionnée est toujours valide */
  useEffect(() => {
    if (!readOnly) {
      /** A cause des useEffect en cascade avec lesquels on update les différentes valeurs
       * du composant, il prend 3 renders à s'initialiser totalement (regexInsee, typeOuvrage,
       * filterOuvrages). Afin d'éviter que, lorsqu'en tant que RH, quand on veut modifier
       * les informations générales d'une déclaration, on ne se retrouve instantanément avec
       * la valeur souhaitée en dehors de filteredOuvrages, on utilise cheatingFirstRenders
       * pour désactiver la vérification pour les 3 premiers renders */
      if (
        filteredOuvrages.find((_o) => _o.posteTechnique === value) == null &&
        cheatingFirstRenders >= 4
      ) {
        setPosteTechnique("");
        setOuvrageDescription("");
        setValue("");
      }
      if (cheatingFirstRenders < 4) {
        setCheatingFirstRenders(cheatingFirstRenders + 1);
      }
    }
  }, [filteredOuvrages, value]);

  function getHelperText(): string {
    const firstHelperText: string =
      typeOuvrage === "" || communeINSEE === ""
        ? "Veuillez préciser une commune et un type d'ouvrage. "
        : "";
    return `${firstHelperText}${helperText ?? ""}`;
  }

  function getOuvrageLabel(ouvrage: Ouvrage): string {
    const precisionDo = ouvrage.typeObjet === "DO" ? ` (${ouvrage.rue})` : "";
    return `${ouvrage.designation}${precisionDo}`;
  }

  return (
    <FormControl
      error={error}
      disabled={
        !readOnly &&
        (typeOuvrage === "" ||
          typeOuvrage === notOuvrageTypeString ||
          communeINSEE === "" ||
          filteredOuvrages.length <= 0)
      }
      fullWidth>
      <InputLabel id="ouvrage">Ouvrage</InputLabel>
      <Select
        labelId="ouvrage"
        label="Ouvrage"
        variant="outlined"
        inputProps={{ readOnly }}
        value={value}
        onChange={(e: SelectChangeEvent<string>) => {
          const o = filteredOuvrages.find((_o) => _o.posteTechnique === e.target.value);
          setValue(e.target.value);
          setPosteTechnique(o?.posteTechnique ?? "");
          setOuvrageDescription(o?.designation ?? "");
        }}>
        {filteredOuvrages.map((ouvrage: Ouvrage) => {
          return (
            <MenuItem key={ouvrage.posteTechnique} value={ouvrage.posteTechnique}>
              {getOuvrageLabel(ouvrage)}
            </MenuItem>
          );
        })}
      </Select>
      {typeOuvrage === "" || communeINSEE === "" || helperText !== "" ? (
        <FormHelperText>{getHelperText()}</FormHelperText>
      ) : (
        filteredOuvrages.length <= 0 &&
        typeOuvrage !== notOuvrageTypeString && (
          <FormHelperText>Aucun ouvrage de ce type dans cette commune.</FormHelperText>
        )
      )}
    </FormControl>
  );
}

export default OuvrageSelect;
