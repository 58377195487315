import { useState } from "react";
import type { ReactElement } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { TransitionSlideUp } from "components/utils/animations";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ControlledExportDeclarationsDialogParts from "./ControlledExportDeclarationsDialogParts";
import ControlledExportRegistreDialogParts from "./ControlledExportRegistreDialogParts";
import DividerTitle from "components/utils/DividerTitle";

type ExcelModalProps = {
  open: boolean;
  handleClose: () => void;
};

enum ExportType {
  REGISTRE_ACCIDENTS_BENINS = "REGISTRE_ACCIDENTS_BENINS",
  DECLARATIONS = "DECLARATIONS",
}

function ExcelExportsModal({ open, handleClose }: ExcelModalProps): ReactElement {
  const [exportType, setExportType] = useState<ExportType | "">("");

  function resetAndClose(): void {
    setExportType("");
    handleClose();
  }

  return (
    <Dialog open={open} TransitionComponent={TransitionSlideUp} onClose={resetAndClose} fullWidth>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}>
        Export de données au format Excel
        <IconButton sx={{ mr: -1 }} onClick={resetAndClose}>
          <HighlightOffIcon color="primary" />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ overflow: "initial" }}>
        <Grid container spacing={3} maxWidth="md">
          <Grid item xs={12}>
            <DividerTitle>
              <Typography variant="subtitle1">Que souhaitez-vous exporter ?</Typography>
            </DividerTitle>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Type d'export *"
              placeholder="Sélectionnez les données à exporter"
              value={exportType}
              defaultValue=""
              onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                setExportType(event.target.value as ExportType);
              }}
              fullWidth
              select>
              <MenuItem value="DECLARATIONS">Liste des Déclarations</MenuItem>
              <MenuItem value="REGISTRE_ACCIDENTS_BENINS">Registre des accident bénins</MenuItem>
            </TextField>
          </Grid>
        </Grid>
      </DialogContent>
      {exportType === ExportType.DECLARATIONS && (
        <ControlledExportDeclarationsDialogParts handleClose={resetAndClose} />
      )}
      {exportType === ExportType.REGISTRE_ACCIDENTS_BENINS && (
        <ControlledExportRegistreDialogParts handleClose={resetAndClose} />
      )}
    </Dialog>
  );
}

export default ExcelExportsModal;
