import { Autocomplete, type SxProps, TextField } from "@mui/material";
import type { ReactElement } from "react";

interface FilterAutocompleteProps<T> {
  value: T;
  setFilterValue: (value: T | null) => void;
  getOptionLabel?: (option: T) => string;
  options: Array<T>;
  sx: SxProps;
  placeholder: string;
}

function FilterAutocomplete<T>({
  value,
  setFilterValue,
  getOptionLabel,
  options,
  sx,
  placeholder,
}: FilterAutocompleteProps<T>): ReactElement {
  return (
    <Autocomplete
      value={value !== "" ? value : null}
      onChange={(_, newValue) => {
        setFilterValue(newValue);
      }}
      options={options}
      getOptionLabel={getOptionLabel}
      sx={sx}
      size="small"
      autoHighlight
      openOnFocus
      selectOnFocus
      renderInput={(params) => <TextField {...params} placeholder={placeholder} />}
    />
  );
}

export default FilterAutocomplete;
