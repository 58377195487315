import type { PropsWithChildren, ReactElement } from "react";
import type ApplicationRoles from "constants/ApplicationRoles";
import { useUser } from "providers/Authentication/SecuUserProvider";

type Props = PropsWithChildren<{
  roles: Array<ApplicationRoles> | ApplicationRoles;
}>;

function Authorization({ roles, children }: Props): ReactElement {
  const { isRoles } = useUser();

  return <>{isRoles(roles) && children}</>;
}

export default Authorization;
