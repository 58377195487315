import { useEffect, useState } from "react";
import type { BaseSyntheticEvent, ReactElement } from "react";
import { useForm, Controller } from "react-hook-form";
import type { SubmitHandler, FieldValues } from "react-hook-form";
import { Grid, Typography, Button, FormControl, FormControlLabel, Checkbox } from "@mui/material";
import accidentService from "services/accidentService";
import AgentSelect from "components/inputs/agents/AgentSelect";
import CommuneSelect from "components/inputs/CommuneSelect";
import OuvrageSelect from "components/inputs/OuvrageSelect";
import type { AccidentFormModel } from "models/AccidentFormModel";
import PhotosInput from "components/inputs/photos/PhotosInput";
import { grey } from "@mui/material/colors";
import SkeletonForm from "components/loading/SkeletonForm";
import declarationOfflineService from "services/declarationOfflineService";
import type { Accident, OfflineAccident } from "models/Accident";
import formModelService from "services/formModelService";
import type TypeAccident from "constants/TypeAccident";
import { useSnackbar } from "notistack";
import ToastMessages from "constants/ToastMessages";
import SaveIcon from "@mui/icons-material/Save";
import ControlledTimePicker from "components/inputs/ControlledTimePicker";
import ControlledDateTime from "components/inputs/ControlledDateTime";
import ControlledTextField from "components/inputs/ControlledTextField";
import ControlledYesNoText from "components/inputs/YesNoText/ControlledYesNoText";
import LoadingButton from "components/buttons/LoadingButton";
import { Guid } from "guid-typescript";
import { useData } from "providers/DataProvider";
import { routesConfig } from "app-config";
import { useNavigate } from "react-router-dom";
import ControlledOuvrageTypeSelect from "components/inputs/ControlledOuvrageTypeSelect";
import RequalificationModal from "../RequalificationModal";
import FormSectionTitle from "./FormSectionTitle";
import type User from "models/User";
import declarationService from "services/declarationService";
import { dateUtil } from "@sdeapps/react-core";
import StatutVictime from "constants/StatutVictime";
import ControlledRadioGroup from "components/inputs/ControlledRadioGroup";
import type { IndexableType } from "dexie";
import ApplicationRoles from "constants/ApplicationRoles";
import Authorization from "../Authorization";
import ControlledBooleanRadioGroup from "components/inputs/ControlledBooleanRadioGroup";
import DividerTitle from "components/utils/DividerTitle";
import { useUser } from "providers/Authentication/SecuUserProvider";

type AccidentFormProps = {
  isNew?: boolean;
  isRequalification?: boolean;
  accident?: Accident;
  isLoading: boolean;
  updateReferenceDeclaration: (declaration: Accident) => void;
  typeAccident: TypeAccident;
};

function AccidentForm({
  isNew = true,
  isRequalification = false,
  accident,
  isLoading,
  updateReferenceDeclaration,
  typeAccident,
}: AccidentFormProps): ReactElement {
  const [isFormReadonly, setIsFormReadonly] = useState(false);
  const [disableSending, setDisableSending] = useState(false);
  const [openRequalificationModal, setOpenRequalificationModal] = useState(false);
  const navigate = useNavigate();
  const data = useData();
  const { user, isRoles } = useUser();
  const { enqueueSnackbar } = useSnackbar();

  const {
    register,
    unregister,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
    watch,
    reset,
  } = useForm({ shouldFocusError: false });
  /** Le SubmitHandler valide les données par rapport à leurs propriétés
   * (required...) et l'interface AccidentPageViewModel */
  async function onSubmit(formModel: AccidentFormModel): Promise<void> {
    setDisableSending(true);

    const newDeclaration = formModelService.formModelToAccident(formModel);

    if (isRequalification) {
      await requalifyDeclaration(newDeclaration);
    } else if (isNew || !isRoles(ApplicationRoles.MODERATOR)) {
      await createDeclaration(newDeclaration);
    } else {
      await updateDeclaration(newDeclaration);
    }
    setDisableSending(false);
  }

  async function createDeclaration(newDeclaration: Accident): Promise<void> {
    let offlineFailed: boolean = false;
    newDeclaration.type = typeAccident;
    let offlineIndex: IndexableType | undefined;
    let offlineDeclaration: OfflineAccident | undefined;
    if (newDeclaration.isVictimePrestataire) {
      newDeclaration.victimeCity = "";
      newDeclaration.victimeDepartment = "";
      newDeclaration.victimeEmployeeId = "";
      newDeclaration.victimeId = "";
      newDeclaration.victimeJobTitle = "";
      newDeclaration.victimeStatut = null;
    } else {
      newDeclaration.victimeCompany = "";
    }

    try {
      offlineIndex = await declarationOfflineService.createAccident(newDeclaration);
      offlineDeclaration = await declarationOfflineService.getAccidentById(offlineIndex);
    } catch (error: any) {
      console.error(error);
      offlineFailed = true;
      newDeclaration.id = Guid.create().toString();
    }
    try {
      const createdDeclaration = await accidentService.create(offlineDeclaration ?? newDeclaration);
      if (offlineIndex !== undefined) {
        void declarationOfflineService.removeAccidentById(offlineIndex);
      }
      if (
        createdDeclaration.victimeId === user?.id ||
        (user?.directReports?.includes(createdDeclaration.victimeId) ?? false)
      ) {
        updateReferenceDeclaration(createdDeclaration);
        /** Si l'utilisateur est la victime ou le manager de la victime de cette
         * déclaration, il peut continuer vers les informations complémentaires */
        navigate(routesConfig.accidentComplement.getParameterPath(createdDeclaration?.id ?? ""));
        enqueueSnackbar(ToastMessages.SUCCESS_CREATE, {
          variant: "success",
          autoHideDuration: 5000,
        });
      } else {
        /** Sinon, on le redirige vers la page d'accueil */
        enqueueSnackbar(ToastMessages.SUCCESS_CREATE_REDIRECT, {
          variant: "success",
        });
        navigate(`/`);
      }
    } catch (error) {
      if (!offlineFailed) {
        enqueueSnackbar(ToastMessages.WARNING_CREATE_OFFLINE, {
          variant: "warning",
        });
        navigate(`/`);
      } else {
        console.error("Offline save failed !", error);
        enqueueSnackbar(ToastMessages.ERROR_CREATE_OFFLINE, {
          variant: "error",
        });
      }
    }
  }

  async function updateDeclaration(newDeclaration: Accident): Promise<void> {
    newDeclaration.id = accident!.id;
    try {
      const updatedDeclaration = await accidentService.update(newDeclaration);
      updateReferenceDeclaration(updatedDeclaration);
      enqueueSnackbar(ToastMessages.SUCCESS_MODIFY, {
        variant: "success",
        autoHideDuration: 5000,
      });
      navigate(routesConfig.accidentComplement.getParameterPath(updatedDeclaration?.id ?? ""));
    } catch (error) {
      console.error(error);
      enqueueSnackbar(ToastMessages.ERROR_UPDATE, {
        variant: "error",
      });
    }
  }

  async function requalifyDeclaration(newDeclaration: Accident): Promise<void> {
    newDeclaration.id = Guid.create().toString();
    try {
      await accidentService.requalify(newDeclaration);
      enqueueSnackbar(ToastMessages.SUCCESS_MODIFY, {
        variant: "success",
        autoHideDuration: 5000,
      });
      navigate(`/`);
    } catch (error) {
      console.error(error);
      enqueueSnackbar(ToastMessages.ERROR_UPDATE, {
        variant: "error",
      });
    }
  }

  useEffect(() => {
    register("ouvragePosteTechnique", { value: "" });
    register("ouvrageDescription", { value: "" });
    register("victimeFirstName", { value: "" });
    register("victimeLastName", { value: "" });
    register("victimeEmployeeId", { value: "" });
    register("victimeCity", { value: "" });
    register("victimeDepartment", { value: "" });
    register("victimeJobTitle", { value: "" });
    setValue("ouvragePosteTechnique", "");
    setValue("ouvrageDescription", "");
    setValue("victimeFirstName", "");
    setValue("victimeLastName", "");
    setValue("victimeEmployeeId", "");
    setValue("victimeCity", "");
    setValue("victimeDepartment", "");
    setValue("victimeJobTitle", "");
  }, []);

  useEffect(() => {
    if (!isLoading && accident != null && (!isNew || isRequalification)) {
      const accidentPageFormModel: AccidentFormModel =
        formModelService.accidentToFormModel(accident);

      reset(accidentPageFormModel);

      setIsFormReadonly(!isRoles(ApplicationRoles.MODERATOR));
    }
  }, [isLoading, accident]);

  function onFormValidationError(_errors: object, _event?: BaseSyntheticEvent): void {
    enqueueSnackbar(ToastMessages.ERROR_FORM_VALIDATION, {
      variant: "error",
    });
    /** Ceci permet de choper le nom du premier composant de la page en erreur.
     * On peut mettre le focus dessus avec le setFocus() de react-hook-form, mais il ne
     * nous file pas sa ref, la manipulation du composant est donc compliquée pour l'instant
     */
    /* const firstErrorKey: keyof typeof errors = Object.keys(
      errors
    )[0] as keyof typeof errors;
    console.log(firstErrorKey);
    if (firstErrorKey) {
      setFocus(firstErrorKey as string);
      const firstError = errors[firstErrorKey];
    } */
  }

  function getSaveButtonLabel(): string {
    if (isRequalification) {
      return "Requalifier";
    }
    if (!isNew && isRoles(ApplicationRoles.MODERATOR)) {
      return "Modifier";
    } else {
      return "Enregistrer";
    }
  }

  if (isLoading || data.isLoading) {
    return <SkeletonForm />;
  }

  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <form onSubmit={handleSubmit(onSubmit as SubmitHandler<FieldValues>, onFormValidationError)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="caption" sx={{ color: grey[500] }}>
            Les champs suivis d'un astérisque ( * ) sont obligatoires
          </Typography>
        </Grid>
        {!isNew && accident?.requalification == null && (
          <Authorization roles={ApplicationRoles.MODERATOR}>
            <Grid item container rowSpacing={2} columnSpacing={3} alignItems="center">
              <Grid item xs={12} sm="auto">
                <Button
                  variant="contained"
                  onClick={() => {
                    setOpenRequalificationModal(true);
                  }}>
                  Requalifier
                </Button>
                <RequalificationModal
                  handleClose={() => {
                    setOpenRequalificationModal(false);
                  }}
                  open={openRequalificationModal}
                  declaration={accident!}
                />
              </Grid>
            </Grid>
          </Authorization>
        )}
        <Grid item xs={12} sm={6}>
          <ControlledDateTime
            name="dateEtHeure"
            control={control}
            defaultValue={accident?.dateEtHeure ?? new Date()}
            rules={{
              required: "Veuillez renseigner une date valide",
              validate: (value: Date) =>
                !dateUtil.isFuture(value) || "Veuillez renseigner une date valide",
            }}
            label="Date de l'accident *"
            readOnly={isFormReadonly}
            maxDate={dateUtil.getDate()}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledTimePicker
            name="heureAccident"
            control={control}
            defaultValue={accident?.dateEtHeure ?? null}
            rules={{
              required: "Veuillez renseigner l'heure de l'accident.",
              validate: (value: Date) => {
                if (!dateUtil.isValid(value)) {
                  return "Veuillez renseigner une heure valide.";
                }
                let comparisonDate: Date | string;
                if (dateUtil.isValid(getValues("dateEtHeure"))) {
                  const date = dateUtil.getDate(getValues("dateEtHeure"));
                  const time = dateUtil.getDate(value);
                  comparisonDate = dateUtil.composeFromSeparateDateAndTime(date, time);
                } else {
                  comparisonDate = value;
                }
                return (
                  !dateUtil.isFuture(comparisonDate) ||
                  "L'heure de l'accident ne peut pas être dans le futur."
                );
              },
            }}
            label="Heure de l'accident *"
            readOnly={isFormReadonly}
          />
        </Grid>
        <Grid item xs={12}>
          <FormSectionTitle title="Lieu de l'accident" infoKey="" />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            name="rue"
            control={control}
            defaultValue={accident?.rue ?? ""}
            rules={{ required: "Ce champ est obligatoire" }}
            label="Rue ou description du lieu *"
            placeholder="Rue ou description du lieu"
            readOnly={isFormReadonly}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="communeInsee"
            control={control}
            rules={{ required: "Ce champ est obligatoire" }}
            render={({ field: { onChange, ref }, fieldState: { error } }) => (
              <CommuneSelect
                onChange={onChange}
                setCommuneName={(value: string) => {
                  setValue("communeName", value);
                }}
                inputRef={ref}
                communes={data.communes}
                defaultValue={accident?.communeInsee}
                defaultName={accident?.communeName}
                error={error != null}
                readOnly={isFormReadonly}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledOuvrageTypeSelect
            name="ouvrageType"
            control={control}
            defaultValue={accident?.ouvrageType}
            label="Type d'ouvrage"
            readOnly={isFormReadonly}
          />
        </Grid>
        <Grid item xs={12}>
          <OuvrageSelect
            communeINSEE={watch("communeInsee") ?? ""}
            typeOuvrage={watch("ouvrageType") ?? ""}
            allOuvrages={data.ouvrages}
            notOuvrageTypeString="NON"
            defaultOuvrage={
              accident?.ouvragePosteTechnique != null && accident?.ouvrageDescription !== ""
                ? {
                    posteTechnique: accident.ouvragePosteTechnique,
                    designation: accident.ouvrageDescription,
                    typeObjet: "",
                    rue: "",
                  }
                : undefined
            }
            setPosteTechnique={(s: string) => {
              setValue("ouvragePosteTechnique", s);
            }}
            setOuvrageDescription={(s: string) => {
              setValue("ouvrageDescription", s);
            }}
            readOnly={isFormReadonly}
            error={errors.ouvragePosteTechnique != null || errors.ouvrageDescription != null}
            helperText={errors.ouvragePosteTechnique?.message as string}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            name="territoire"
            control={control}
            defaultValue={accident?.territoire}
            label="Territoire de l'accident si connu"
            readOnly={isFormReadonly}
            select
            options={data.territoires}
          />
        </Grid>
        <Grid item xs={12}>
          <FormSectionTitle title="Victime" infoKey="" />
        </Grid>
        <Grid item xs={12}>
          <ControlledBooleanRadioGroup
            name="isVictimePrestataire"
            control={control}
            label="La victime est-elle : *"
            defaultValue={accident?.isVictimePrestataire ?? false}
            trueLabel="Prestataire"
            falseLabel="Agent SDEA"
            readOnly={isFormReadonly || !isNew || isRequalification}
          />
        </Grid>
        {watch("isVictimePrestataire") === true ? (
          <>
            <Grid item xs={12} sm={6}>
              <ControlledTextField
                name="victimeFirstName"
                control={control}
                rules={{ required: "Ce champ est obligatoire" }}
                defaultValue={accident?.victimeFirstName}
                label="Prénom du prestataire *"
                placeholder="Champ libre obligatoire"
                readOnly={isFormReadonly}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ControlledTextField
                name="victimeLastName"
                control={control}
                rules={{ required: "Ce champ est obligatoire" }}
                defaultValue={accident?.victimeLastName}
                label="Nom du prestataire *"
                placeholder="Champ libre obligatoire"
                readOnly={isFormReadonly}
              />
            </Grid>
            <Grid item xs={12}>
              <ControlledTextField
                name="victimeCompany"
                control={control}
                rules={{ required: "Ce champ est obligatoire" }}
                defaultValue={accident?.victimeCompany}
                label="Entreprise du prestataire *"
                placeholder="Champ libre obligatoire"
                readOnly={isFormReadonly}
              />
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12}>
              <Controller
                name="victimeId"
                control={control}
                rules={{ required: "Ce champ est obligatoire" }}
                render={({ field: { onChange, ref }, fieldState: { error } }) => (
                  <AgentSelect
                    agents={data.agents}
                    label="Agent concerné *"
                    onValueChange={(user: User | null) => {
                      if (user != null) {
                        onChange(user?.id);
                        setValue("victimeFirstName", user.givenName);
                        setValue("victimeLastName", user.surname);
                        setValue("victimeEmployeeId", user.employeeId);
                        setValue("victimeCity", user.city);
                        setValue("victimeDepartment", user.department);
                        setValue("victimeJobTitle", user.jobTitle);
                      } else {
                        onChange("");
                        setValue("victimeFirstName", "");
                        setValue("victimeLastName", "");
                        setValue("victimeEmployeeId", "");
                        setValue("victimeCity", "");
                        setValue("victimeDepartment", "");
                        setValue("victimeJobTitle", "");
                      }
                    }}
                    inputRef={ref}
                    agentInfos
                    fullWidth
                    defaultValue={accident?.victimeId}
                    defaultAgent={declarationService.getSnapshotVictim(accident)}
                    readOnly={isFormReadonly}
                    error={error != null}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <ControlledRadioGroup
                name="victimeStatut"
                control={control}
                label="La victime est-elle fonctionnaire ? *"
                defaultValue={null}
                rules={{ required: "Ce champ est obligatoire" }}
                options={[
                  { label: "Oui", value: StatutVictime.Titulaire },
                  { label: "Non", value: StatutVictime.Contractuel },
                ]}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <FormSectionTitle title="Description" infoKey="" />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            name="precisions"
            control={control}
            defaultValue={accident?.precisions ?? ""}
            rules={{ required: "Ce champ est obligatoire" }}
            label="Que s'est-il passé ? *"
            placeholder="Que s'est-il passé ?"
            readOnly={isFormReadonly}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            name="activiteEnCours"
            control={control}
            defaultValue={accident?.activiteEnCours ?? ""}
            rules={{ required: "Ce champ est obligatoire" }}
            label="Qu'étiez-vous en train de faire ? *"
            placeholder="Qu'étiez-vous en train de faire ?"
            readOnly={isFormReadonly}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            name="objetResponsable"
            control={control}
            defaultValue={accident?.objetResponsable ?? ""}
            label="Objet dont le contact a blessé la victime"
            readOnly={isFormReadonly}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledYesNoText
            name="isTiers"
            control={control}
            register={register}
            unregister={unregister}
            textFieldName="tiers"
            setValue={setValue}
            label="L'accident a-t-il été causé par un tiers ? *"
            isNew={isNew && !isRequalification}
            defaultValue={accident?.tiers}
            readOnly={isFormReadonly}
            textLabel="Nom Prénom du tiers"
            textPlaceholder="Nom Prénom du tiers"
            textRequired="Veuillez indiquer les nom et prénom du tiers"
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledYesNoText
            name="isTemoin"
            control={control}
            register={register}
            unregister={unregister}
            textFieldName="temoin"
            setValue={setValue}
            label="Quelqu'un d'autre a-t-il été témoin de l'accident ? *"
            isNew={isNew && !isRequalification}
            defaultValue={accident?.temoin}
            readOnly={isFormReadonly}
            textLabel="Nom Prénom du témoin"
            textPlaceholder="Nom Prénom du témoin"
            textRequired="Veuillez indiquer les nom et prénom du témoin"
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            name="commentaire"
            control={control}
            defaultValue={accident?.commentaire ?? ""}
            label="Commentaire lié à l'accident"
            placeholder="Mon commentaire..."
            readOnly={isFormReadonly}
          />
        </Grid>
        <Grid item xs={12}>
          <DividerTitle>
            <Typography variant="h5">Photos</Typography>
          </DividerTitle>
        </Grid>
        <Controller
          name="base64Photos"
          control={control}
          render={({ field: { onChange } }) => (
            <PhotosInput
              value={accident?.photos ?? []}
              onChange={onChange}
              maximumPhotos={3}
              readOnly={!isNew || isRequalification}
            />
          )}
        />
        <Grid item xs={12}>
          <Controller
            name="isConfidentiel"
            control={control}
            defaultValue={false}
            render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
              <FormControl fullWidth error={error != null}>
                <FormControlLabel
                  label="Je souhaite que ma déclaration soit confidentielle, à ce titre que la diffusion soit limitée au strict minimum de destinataire (lien avec des RPS, harcèlement, signaux faibles, ...)"
                  control={<Checkbox checked={value} onChange={onChange} />}
                  labelPlacement="end"
                />
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <LoadingButton
            loading={disableSending}
            endIcon={<SaveIcon />}
            disabled={isFormReadonly || disableSending}>
            {getSaveButtonLabel()}
          </LoadingButton>
        </Grid>
      </Grid>
      <br />
    </form>
  );
}

export default AccidentForm;
