import { useForm, Controller, type SubmitHandler, type FieldValues } from "react-hook-form";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Checkbox,
  FormControlLabel,
  FormLabel,
  MenuItem,
} from "@mui/material";
import type Lesion from "models/Lesion";
import ControlledTextField from "components/inputs/ControlledTextField";
import siegeLesions from "data/siegeLesions";
import lesions from "data/lesions";
import { TransitionSlideUp } from "components/utils/animations";
import type { ReactElement } from "react";

interface ModalLesionProps {
  addLesion: (lesion: Lesion) => void;
  onClose: () => void;
  open: boolean;
}

function ModalLesion({ addLesion, onClose, open }: ModalLesionProps): ReactElement {
  const { handleSubmit, control, reset } = useForm({ shouldFocusError: false });

  function close(): void {
    onClose();
    reset();
  }

  function onSubmitModal(lesion: Lesion): void {
    addLesion(lesion);
    close();
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={TransitionSlideUp}
      onClose={onClose}
      fullWidth
      keepMounted={false}>
      <DialogTitle>Sélectionnez une lésion</DialogTitle>
      <form>
        <DialogContent dividers>
          <Grid container spacing={3} maxWidth="md">
            <Grid item xs={12}>
              <ControlledTextField
                name="typeLesion"
                control={control}
                label="Type de lésion *"
                rules={{
                  required: "Veuillez sélectionner un type de lésion",
                }}
                select>
                {lesions.map((l: string) => {
                  return (
                    <MenuItem value={l} key={l}>
                      {l}
                    </MenuItem>
                  );
                })}
              </ControlledTextField>
            </Grid>
            <Grid item xs={12}>
              <ControlledTextField
                name="siegeLesion"
                control={control}
                label="Siège de la lesion *"
                rules={{
                  required: "Veuillez sélectionner un siège de lésion",
                }}
                select>
                {siegeLesions.map((siegeLesion) => {
                  return (
                    <MenuItem value={siegeLesion.key} key={siegeLesion.key}>
                      {siegeLesion.value}
                    </MenuItem>
                  );
                })}
              </ControlledTextField>
            </Grid>
            <Grid item container justifyContent="center">
              <Grid item xs={12} textAlign="center">
                <FormLabel component="legend">Côté(s) de la lésion</FormLabel>
              </Grid>
              {["Gauche", "Droite"].map((cote: string) => {
                return (
                  <Grid item key={cote}>
                    <Controller
                      name={cote.toLowerCase()}
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={<Checkbox value={field.value ?? ""} onChange={field.onChange} />}
                          label={cote}
                          labelPlacement="bottom"
                        />
                      )}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} color="error">
            Annuler
          </Button>
          <Button onClick={handleSubmit(onSubmitModal as SubmitHandler<FieldValues>)}>
            Ajouter
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default ModalLesion;
