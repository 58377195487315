const siegeLesions = [
  { key: "BRAS", value: "Bras" },
  { key: "DOS", value: "Dos" },
  { key: "INTERNE", value: "Interne" },
  { key: "JAMBES", value: "Jambes" },
  { key: "MAINS", value: "Mains" },
  { key: "PIEDS", value: "Pieds" },
  { key: "TETE", value: "Tête" },
  { key: "TRONC", value: "Tronc" },
  { key: "YEUX", value: "Yeux" },
];

export default siegeLesions;
