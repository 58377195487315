import type { ReactElement } from "react";
import { Button, Container, Divider, Toolbar, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { config, routesConfig } from "app-config";

function Footer(): ReactElement {
  return (
    <Container maxWidth="md" sx={{ marginTop: "auto", paddingTop: 3 }}>
      <Divider />
      <Toolbar>
        <Button href={routesConfig.legal.path}>Mentions légales</Button>
        <Typography
          sx={{
            flexGrow: 1,
            display: "inline-block",
            textAlign: "right",
            color: grey[500],
          }}>
          Version de build : {config.appVersion ?? "local"}
        </Typography>
      </Toolbar>
    </Container>
  );
}

export default Footer;
