import { useState } from "react";
import type { ReactElement } from "react";
import { Button, IconButton, useMediaQuery } from "@mui/material";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ExcelExportsModal from "components/excelExport/ExcelModal";
import { lightGreen } from "@mui/material/colors";

function ExcelExportButton(): ReactElement {
  const [open, setOpen] = useState<boolean>(false);
  const isSmallScreen = useMediaQuery(useTheme().breakpoints.down("sm"));

  // On fait des pieds et des mains pour pouvoir avoir un bouton du vert qu'on veut
  const greenTheme = createTheme({
    palette: { primary: { main: lightGreen[700] } },
  });

  function openModal(): void {
    setOpen(true);
  }

  function closeModal(): void {
    setOpen(false);
  }

  return (
    <>
      <ThemeProvider theme={greenTheme}>
        {isSmallScreen ? (
          <IconButton onClick={openModal} color="inherit">
            <FileDownloadIcon />
          </IconButton>
        ) : (
          <Button
            variant="contained"
            onClick={openModal}
            startIcon={<FileDownloadIcon />}
            color="primary">
            Exporter
          </Button>
        )}
      </ThemeProvider>
      <ExcelExportsModal open={open} handleClose={closeModal} />
    </>
  );
}

export default ExcelExportButton;
