import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import type { ReactElement } from "react";
import { Controller } from "react-hook-form";
import type { Control } from "react-hook-form";

interface ControlledBooleanRadioGroupProps {
  name: string;
  control: Control;
  trueLabel: string;
  falseLabel: string;
  label: string;
  defaultValue?: boolean;
  rules?: object; // difficile de retranscrire ce type
  fullWidth?: boolean;
  readOnly?: boolean;
}

/**
 * Un RadioGroup de mui controllé par react-hook-forms.
 * @param name obligatoire - Nom unique de l'input
 * @param control obligatoire - objet Control du formulaire react-hook-forms
 * https://react-hook-form.com/api/useform/control
 *
 * @param label obligatoire - label de l'input
 * @param defaultValue valeur par défaut de l'input
 * @param rules règles de validation de l'input au même format que
 * les options de la méthode register() de react-hook-forms
 * https://react-hook-form.com/api/useform/register#options
 * @param fullWidth - booléen déterminant si l'input prend toute la place
 * horizontale disponible
 * @returns Un RadioGroup de mui controllé par react-hook-forms
 */
function ControlledBooleanRadioGroup({
  name,
  control,
  trueLabel,
  falseLabel,
  label,
  defaultValue = false,
  rules,
  fullWidth = false,
  readOnly = false,
}: ControlledBooleanRadioGroupProps): ReactElement {
  function getBooleanFromString(value: string): boolean {
    return value === "true";
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl fullWidth={fullWidth} error={error != null}>
          <FormLabel>{label}</FormLabel>
          <RadioGroup
            value={value}
            onChange={(e) => {
              onChange(getBooleanFromString(e.target.value));
            }}>
            <Grid container spacing={2}>
              <Grid item xs="auto">
                <FormControlLabel
                  value="false"
                  control={<Radio disabled={readOnly} />}
                  label={falseLabel}
                />
              </Grid>
              <Grid item xs="auto">
                <FormControlLabel
                  value="true"
                  control={<Radio disabled={readOnly} />}
                  label={trueLabel}
                />
              </Grid>
            </Grid>
          </RadioGroup>
          {error != null && <FormHelperText>{error?.message}</FormHelperText>}
        </FormControl>
      )}
    />
  );
}

export default ControlledBooleanRadioGroup;
