import PopupHandler from "components/utils/PopupHandler/PopupHandler";
import ScrollToTop from "components/utils/ScrollToTop";
import TitlesUtil from "components/utils/TitlesUtil";
import { DataProvider } from "providers/DataProvider";
import SnackbarProvider from "providers/SnackbarProvider";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header/Header";
import type { ReactElement } from "react";

function Root(): ReactElement {
  return (
    <SnackbarProvider>
      <DataProvider>
        <Header />
        <Outlet />
        <Footer />
        <ScrollToTop />
        <TitlesUtil />
        <PopupHandler />
      </DataProvider>
    </SnackbarProvider>
  );
}

export default Root;
