import { useEffect } from "react";
import type { ReactElement } from "react";
import { useMsal, useMsalAuthentication } from "@azure/msal-react";
import { InteractionStatus, InteractionType } from "@azure/msal-browser";
import { apiConfig } from "app-config";
import { useOnlineStatus } from "providers/OnlineStatusProvider";
import { msalInstance } from "./SecuAuthentificationProvider";

/**
 * ConnectionHandler custom, spécifique à Eau Secours, qui ajoute isOnline comme condition à la tentative
 * de connecion par redirection. Peut-être inutile.
 */
function SecuConnectionHandler(): ReactElement {
  const { instance, accounts, inProgress } = useMsal();
  const { login, error } = useMsalAuthentication(InteractionType.Silent, {
    ...apiConfig.azureAd,
    redirectUri: `${window.location.origin}/blank.html`,
  });

  const { isOnline, checkIfOnline } = useOnlineStatus();

  useEffect(() => {
    checkIfOnline();

    try {
      void msalInstance?.handleRedirectPromise();
    } catch (error) {
      console.error("handleRedirectPromise failed", error);
    }
  }, [checkIfOnline]);

  useEffect(() => {
    if (accounts.length > 0) {
      instance.setActiveAccount(accounts[0]);
    }
  }, [accounts, instance]);

  useEffect(() => {
    if (error != null && inProgress === InteractionStatus.None && isOnline) {
      const activeAccount = instance.getActiveAccount();
      console.info(
        "%cFail identification silencieuse initiale",
        "color: cornflowerblue;",
        error.name,
        error.message
      );
      console.info("%cReconnection par redirection", "color: cornflowerblue;");

      void login(InteractionType.Redirect, {
        account: activeAccount ?? undefined,
        ...apiConfig.azureAd,
      });
    }
  }, [error, inProgress]);

  return <></>;
}

export default SecuConnectionHandler;
