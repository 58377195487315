import { Box } from "@mui/material";
import type User from "models/User";
import type { ReactElement } from "react";

interface AgentInfoProps {
  user?: User | null;
}
/**
 * Composant affichant les informations complémentaires de l'agent sélectionné.
 * @param param0 User à afficher
 * @returns
 */
function AgentInfo({ user }: AgentInfoProps): ReactElement {
  if (user != null && (user.city !== "" || user.department !== "" || user.manager != null)) {
    return (
      <Box component="span" sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}>
        <div>{user.displayName}</div>
        {(user.city !== "" || user.department !== "") && (
          <div>
            {user.city} - {user.department}
          </div>
        )}
        {user.manager != null && <div>Manager : {user.manager?.displayName}</div>}
      </Box>
    );
  } else {
    return <></>;
  }
}

export default AgentInfo;
