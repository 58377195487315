import { IconButton } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import type { ReactElement } from "react";

interface DeleteButtonProps {
  onClick: () => void;
}

function DeleteButton({ onClick }: DeleteButtonProps): ReactElement {
  return (
    <IconButton
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
      sx={{ position: "absolute", top: 0, right: 0 }}>
      <CancelIcon color="error" sx={{ borderRadius: "100%", bgcolor: "white" }} />
    </IconButton>
  );
}

export default DeleteButton;
