import { useEffect, useState, useMemo } from "react";
import type { ReactElement } from "react";
import { TextField, Autocomplete, createFilterOptions } from "@mui/material";
import type Commune from "models/Commune";
import communeService from "services/communeService";
import type { RefCallBack } from "react-hook-form";

interface CommuneSelectProps {
  onChange: (...event: Array<any>) => void;
  setCommuneName: (value: string) => void;
  defaultValue?: string;
  defaultName?: string;
  communes: Array<Commune>;
  readOnly?: boolean;
  inputRef?: RefCallBack;
  label?: string;
  error?: boolean;
  helperText?: string;
}

function CommuneSelect({
  onChange,
  setCommuneName,
  defaultValue = "",
  defaultName = "",
  communes,
  readOnly = false,
  inputRef,
  label = "",
  error = false,
  helperText = "",
}: CommuneSelectProps): ReactElement {
  const defaultCommune = useMemo((): Commune => {
    return {
      code: defaultValue,
      nom: defaultName,
    };
  }, [defaultName, defaultValue]);

  const [commune, setCommune] = useState<Commune | null>(getStartingCommune());
  const [open, setOpen] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState("");
  const [newCommunes, setNewCommunes] = useState<Array<Commune>>([]);

  function getStartingCommune(): Commune | null {
    if (defaultValue !== "") {
      const startingCommune = communes.find((v: Commune) => v.code === defaultValue);
      return startingCommune ?? defaultCommune;
    }
    return null;
  }

  function onCommuneChange(_event: any, ville: Commune | null): void {
    if (ville != null) {
      setCommune(ville);
      onChange(ville.code);
      setCommuneName(ville.nom);
    }
  }

  function onInputChange(newInput: string): void {
    if (newInput === "") {
      setCommune(null);
      onChange("");
      setCommuneName("");
    }
    setInputValue(newInput);
  }

  useEffect(() => {
    communes.sort((a: Commune, b: Commune) => {
      return a.nom.localeCompare(b.nom, "fr");
    });

    if (defaultCommune.code !== "" && defaultCommune.nom !== "") {
      setNewCommunes([defaultCommune, ...communes]);
    } else {
      setNewCommunes(communes);
    }
  }, [communes, defaultCommune]);

  const filterOptions = createFilterOptions({
    trim: true,
    stringify: (option: Commune) => communeService.getFrenchSearchString(option.nom),
  });

  return (
    <Autocomplete
      fullWidth
      autoHighlight
      openOnFocus
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      value={commune}
      onChange={onCommuneChange}
      isOptionEqualToValue={(v1: Commune, v2: Commune): boolean => v1.nom === v2.nom}
      getOptionLabel={(ville: Commune): string => ville.nom}
      inputValue={inputValue}
      onInputChange={(_e: unknown, newInputValue: string) => {
        onInputChange(newInputValue);
      }}
      options={newCommunes}
      filterOptions={filterOptions}
      open={open}
      onOpen={() => !readOnly && setOpen(true)}
      onClose={() => {
        setOpen(false);
      }}
      disableClearable={readOnly}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label !== "" ? label : "Commune *"}
          placeholder="Nom de la Commune"
          inputRef={inputRef}
          InputProps={{
            ...params.InputProps,
            readOnly,
          }}
          error={error}
          helperText={helperText}
        />
      )}
    />
  );
}

export default CommuneSelect;
