import { useEffect, useState } from "react";
import type { ReactElement } from "react";
import { Chip } from "@mui/material";
import StatutCompletion from "constants/StatutCompletion";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import WifiOffIcon from "@mui/icons-material/WifiOff";
import EditIcon from "@mui/icons-material/Edit";
import SyncIcon from "@mui/icons-material/Sync";
import { spinningAnimation } from "components/utils/animations";

interface StatutChipProps {
  sending?: boolean;
  statutCompletion: StatutCompletion;
}

function StatusChip({ sending, statutCompletion }: StatutChipProps): ReactElement {
  const [icon, setIcon] = useState<ReactElement>(<></>);
  const [label, setLabel] = useState<string>("");
  const [color, setColor] = useState<
    "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning" | undefined
  >("default");
  const [clickable, setClickable] = useState<boolean>(false);

  useEffect(() => {
    if (sending !== undefined) {
      if (sending) {
        setIcon(<SyncIcon sx={spinningAnimation} />);
        setLabel(" Envoi en cours");
      } else {
        setIcon(<WifiOffIcon />);
        setLabel(" Offline");
      }
    } else {
      if (statutCompletion === StatutCompletion.ACompleter) {
        setClickable(true);
        setIcon(<EditIcon />);
        setLabel(" À Compléter");
        setColor("info");
      } else {
        setIcon(<AssignmentTurnedInIcon />);
        setLabel(" Terminé");
        setColor("success");
      }
    }
  }, [sending, statutCompletion]);

  return <Chip clickable={clickable} icon={icon} label={label} color={color} />;
}

export default StatusChip;
