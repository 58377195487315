import { useState } from "react";
import type { ReactElement } from "react";
import { Alert, Button, Snackbar } from "@mui/material";
import ToastMessages from "constants/ToastMessages";
import SyncIcon from "@mui/icons-material/Sync";
import { spinningAnimation } from "../animations";

const syncIconStyle = { ...spinningAnimation, ml: 1 };

type UpdateSnackbarProps = {
  skipWaiting: () => void;
};

function UpdateSnackbar({ skipWaiting }: UpdateSnackbarProps): ReactElement {
  const [isSkipping, setIsSkipping] = useState(false);

  return (
    <Snackbar
      open={true}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}>
      <Alert
        variant="filled"
        severity="info"
        sx={{ width: "100%" }}
        action={
          <Button
            onClick={() => {
              skipWaiting();
              setIsSkipping(true);
            }}
            size="small"
            sx={{ color: "#FFF" }}>
            Installer
            {isSkipping && <SyncIcon sx={syncIconStyle} />}
          </Button>
        }>
        {ToastMessages.INFO_UPDATE_REFRESH}
      </Alert>
    </Snackbar>
  );
}

export default UpdateSnackbar;
