import { useEffect } from "react";
import type { ReactElement } from "react";
import { Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { routesConfig } from "app-config";
import storageService from "services/storageService";

/**
 * Page appelée après déconnexion de l'utilisateur sur la platefome Microsoft Online
 */
function FlushPage(): ReactElement {
  const navigate = useNavigate();

  useEffect(() => {
    storageService.removeUserInfo();
    navigate(routesConfig.home.path);
  }, []);

  return <Container maxWidth="md">Vous êtes à présent déconnecté.</Container>;
}

export default FlushPage;
