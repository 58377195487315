import { apiConfig, routesConfig } from "app-config";
import type User from "models/User";
import graphService from "services/graphService";
import type { RedirectRequest, SilentRequest } from "@azure/msal-browser";
import { msalInstance } from "providers/Authentication/SecuAuthentificationProvider";
import storageService from "./storageService";

/**
 * Redirige l'utilisateur vers la page de login Microsoft.
 */
async function login(): Promise<void> {
  try {
    await msalInstance?.loginRedirect({
      account: msalInstance?.getActiveAccount() ?? undefined,
      ...apiConfig.azureAd,
    });
  } catch (error) {
    console.error(error);
  }
}

/**
 * Redirige l'utilisateur vers la page de logout Microsoft, puis vers
 * notre page de flush.
 */
async function logout(): Promise<void> {
  try {
    await msalInstance?.logoutRedirect({
      account: msalInstance?.getActiveAccount() ?? undefined,
      postLogoutRedirectUri: `${window.location.origin}${routesConfig.flush.path}`,
    });
  } catch (error) {
    console.error(error);
  }
}

/**
 * Récupère les données sur le compte actuellement authentifié, et logge
 * l'utilisateur si aucun compte n'est actuellement authentifié.
 */
async function getAccessToken(scopes: Array<string>): Promise<string> {
  const account = msalInstance?.getActiveAccount();

  const request: SilentRequest | RedirectRequest = {
    scopes,
    account: account ?? undefined,
  };

  try {
    const authResult = await msalInstance?.acquireTokenSilent({
      ...request,
      redirectUri: `${window.location.origin}/blank.html`,
    });
    if (authResult == null) {
      throw Error("No auth token found");
    }
    return authResult.accessToken;
  } catch (error) {
    const e1 = error as Error;
    console.info(
      "%cFail acquireTokenSilent initial",
      "color: cornflowerblue;",
      e1.name,
      e1.message
    );
    try {
      console.info("%cTrying acquireTokenRedirect", "color: cornflowerblue;");
      await msalInstance?.acquireTokenRedirect(request);
    } catch (error2) {
      const e2 = error2 as Error;
      console.info("%cFail acquireTokenRedirect : ", "color: cornflowerblue;", e2.name, e2.message);
      // au fail du acquireTokenRedirect, il y a un problème d'authentification.
      // On va clear le local et session storage pour effacer toute trace
      // d'authentification incomplète, obsolète ou erronée, et recommencer.

      console.info("%cCLEANING LOCAL AND SESSION STORAGE", "color: cornflowerblue;");
      storageService.clearLocalAndSessionStorage();

      try {
        console.info("%cRetrying acquireTokenRedirect again", "color: cornflowerblue;");
        await msalInstance?.acquireTokenRedirect(request);
      } catch (error3) {
        // Si ça pèche toujours, on ne peut plus faire grand-chose d'autre que tenter
        // de hard reloger l'utilisateur.
        console.info("%cFail acquireTokenRedirect again : ", "color: cornflowerblue;", error3);
        console.info("%cRetrying login", "color: cornflowerblue;");
        await login();
        const e3 = error3 as Error;
        throw Error(e3.name + e3.message);
      }
    }
  }

  const error = Error("ACQUIRE TOKEN ATTEMPTS ALL FAILED MISERABLY");
  console.error(error);
  throw error;
}

/**
 * Cherche les données dans l'AD et l'avatar de l'utilisateur actuellement authentifié.
 */
async function getUserInfo(): Promise<User> {
  const userInfo: User = await graphService.getUserInfo();
  try {
    userInfo.avatar = await graphService.getUserPhoto();
  } catch (error) {
    console.error("error getting user photo : ", error);
  }
  return userInfo;
}

const authService = {
  login,
  logout,
  getAccessToken,
  getUserInfo,
};
export default authService;
