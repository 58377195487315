import { routesConfig } from "app-config";
import NotFoundErrorPage from "components/errors/NotFoundErrorPage";
import Root from "components/sections/Root";
import TypeAccident from "constants/TypeAccident";
import TypeSecurite from "constants/TypeSecurite";
import AccidentPage from "pages/AccidentPage";
import DangerGravePage from "pages/DangerGravePage";
import EnSavoirPlusPage from "pages/EnSavoirPlusPage";
import FlushPage from "pages/FlushPage";
import LegalMentionsPage from "pages/LegalMentionsPage";
import MainPage from "pages/MainPage";
import PhotoPage from "pages/PhotoPage";
import PhotosPage from "pages/PhotosPage";
import SecuritePage from "pages/SecuritePage";
import TypeSelection from "pages/TypeSelection";
import { createBrowserRouter } from "react-router-dom";

const authenticatedRouter = createBrowserRouter([
  {
    element: <Root />,
    children: [
      {
        path: routesConfig.home.path,
        element: <MainPage />,
      },
      {
        path: routesConfig.accident.path,
        element: <AccidentPage />,
      },
      {
        path: routesConfig.dangerGrave.path,
        element: <DangerGravePage />,
      },
      {
        path: routesConfig.accidentComplement.path,
        element: <AccidentPage />,
      },
      {
        path: routesConfig.securite.path,
        element: <SecuritePage />,
      },
      {
        path: routesConfig.accidentPhotos.path,
        element: <PhotosPage typeDeclaration="accident" />,
      },
      {
        path: routesConfig.securitePhotos.path,
        element: <PhotosPage typeDeclaration="securite" />,
      },
      {
        path: routesConfig.dangerGravePhotos.path,
        element: <PhotosPage typeDeclaration="danger" />,
      },
      {
        path: routesConfig.new.path,
        element: <TypeSelection />,
      },
      {
        path: routesConfig.accidentTrajet.path,
        element: <AccidentPage typeAccident={TypeAccident.TRAJET} />,
      },
      {
        path: routesConfig.accidentTravail.path,
        element: <AccidentPage typeAccident={TypeAccident.TRAVAIL} />,
      },
      {
        path: routesConfig.accidentBenin.path,
        element: <SecuritePage typeSecurite={TypeSecurite.ACCIDENT_BENIN} />,
      },
      {
        path: routesConfig.presquAccident.path,
        element: <SecuritePage typeSecurite={TypeSecurite.PRESQU_ACCIDENT} />,
      },
      {
        path: routesConfig.situationDangereuse.path,
        element: <SecuritePage typeSecurite={TypeSecurite.SITUATION_DANGEREUSE} />,
      },
      {
        path: routesConfig.photo.path,
        element: <PhotoPage />,
      },
      {
        path: routesConfig.flush.path,
        element: <FlushPage />,
      },
      {
        path: routesConfig.legal.path,
        element: <LegalMentionsPage />,
      },
      {
        path: routesConfig.enSavoirPlus.path,
        element: <EnSavoirPlusPage />,
      },
      {
        path: routesConfig.any.path,
        element: <NotFoundErrorPage />,
      },
    ],
  },
]);

export default authenticatedRouter;
