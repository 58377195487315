import { apiConfig } from "app-config";
import errorHandlingService from "./errorHandlingService";
import networkService from "./networkService";

const apiBaseUrl = `${apiConfig.apimBaseUrl}/${apiConfig.declarationApi.apiName}`;
const scopes = apiConfig.declarationApi.scopes;

const endpoint = "photos";

/**
 * Génère les headers pour la requête http
 * @returns Les Headers
 */
async function createHeaders(): Promise<HeadersInit> {
  return networkService.createHeaders(scopes);
}

/**
 * Récupère un accident en fonction de son id
 * @param id l'id de l'accident
 * @returns Accident
 */
async function getUriByUrl(id: string): Promise<string> {
  const url = `${apiBaseUrl}/${endpoint}/${id}`;
  return fetch(url, {
    method: "GET",
    headers: await createHeaders(),
    mode: "cors",
  })
    .then(errorHandlingService.defaultAndDefined)
    .then(networkService.getJsonData);
}

const photoService = {
  getUriByUrl,
};
export default photoService;
