import { useMemo } from "react";
import type { Declaration } from "models/Declaration";
import { useData } from "providers/DataProvider";
import agentService from "services/agentService";
import { dateUtil } from "@sdeapps/react-core";

export type EditionDetails = {
  auteur?: string;
  date?: string;
  time?: string;
};

interface UseDeclarationCreationDetailsResult {
  creationDetails: EditionDetails;
  requalDetails?: EditionDetails;
  isDeclarationOrigin: boolean;
}
export function useDeclarationCreationDetails(
  declaration: Declaration | undefined
): UseDeclarationCreationDetailsResult {
  const { agents } = useData();
  const isDeclarationOrigin = useMemo(
    () => !(declaration?.isRequalificationOrigin ?? false),
    [declaration]
  );

  const creationDetails = useMemo((): EditionDetails => {
    const realDeclaration =
      isDeclarationOrigin && declaration?.requalification != null
        ? declaration?.requalification
        : declaration;
    const auteur =
      agentService.getDisplayNameById(agents, realDeclaration?.creatorId ?? "") ??
      realDeclaration?.creatorId;
    const date = dateUtil.format(realDeclaration?.creationDate, "dd/MM/yyyy");
    const time = dateUtil.format(realDeclaration?.creationDate, "HH:mm");

    const creationDetails = {
      auteur,
      date,
      time,
    };

    return creationDetails;
  }, [declaration, isDeclarationOrigin, agents]);

  const requalDetails = useMemo((): EditionDetails | undefined => {
    if (declaration?.requalification == null) {
      return undefined;
    }

    const realDeclaration = isDeclarationOrigin ? declaration : declaration?.requalification;
    const auteur =
      agentService.getDisplayNameById(agents, realDeclaration?.creatorId ?? "") ??
      realDeclaration?.creatorId;
    const date = dateUtil.format(realDeclaration?.creationDate, "dd/MM/yyyy");
    const time = dateUtil.format(realDeclaration?.creationDate, "HH:mm");

    const requalDetails = {
      auteur,
      date,
      time,
    };

    return requalDetails;
  }, [declaration, isDeclarationOrigin, agents]);

  return {
    isDeclarationOrigin,
    creationDetails,
    requalDetails,
  };
}
