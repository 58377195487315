import type { Accident } from "models/Accident";
import type { Securite } from "models/Securite";
import type { AccidentFormModel } from "models/AccidentFormModel";
import type { SecuriteFormModel } from "models/SecuriteFormModel";
import { dateUtil } from "@sdeapps/react-core";
import type { DangerGraveFormModel } from "models/DangerGraveFormModel";
import type { DangerGrave } from "models/DangerGrave";

function accidentToFormModel(accident: Accident): AccidentFormModel {
  const temoin = accident?.temoin ?? "";
  const tiers = accident?.tiers ?? "";
  const isTemoin = temoin !== "";
  const isTiers = tiers !== "";
  const horaireDebut =
    accident?.horaireTravailDebut != null
      ? dateUtil.fromFormatToDate(accident.horaireTravailDebut, "HH:mm")
      : null;
  const horaireFin =
    accident?.horaireTravailFin != null
      ? dateUtil.fromFormatToDate(accident.horaireTravailFin, "HH:mm")
      : null;
  accident.base64Photos = accident.base64Photos ?? [];
  accident.lesions = accident.lesions ?? [];

  const formModel: AccidentFormModel = {
    ...accident,
    horaireTravailDebut: horaireDebut,
    horaireTravailFin: horaireFin,
    heureAccident: new Date(accident.dateEtHeure),
    temoin,
    tiers,
    isTemoin,
    isTiers,
  };

  return formModel;
}

function formModelToAccident(accidentFormModel: AccidentFormModel, id?: string): Accident {
  const accident = accidentFormModel as Accident;

  accident.dateEtHeure = dateUtil.composeFromSeparateDateAndTime(
    accidentFormModel.dateEtHeure,
    accidentFormModel.heureAccident
  );

  accident.horaireTravailDebut =
    accidentFormModel.horaireTravailDebut != null
      ? dateUtil.format(accidentFormModel.horaireTravailDebut, "HH:mm")
      : null;
  accident.horaireTravailFin =
    accidentFormModel.horaireTravailFin != null
      ? dateUtil.format(accidentFormModel.horaireTravailFin, "HH:mm")
      : null;

  accident.id = id ?? accidentFormModel.id;

  return accident;
}

function securiteToFormModel(securite: Securite): SecuriteFormModel {
  const temoin = securite?.temoin ?? "";
  const tiers = securite?.tiers ?? "";
  const isTemoin = temoin !== "";
  const isTiers = tiers !== "";
  securite.base64Photos = securite.base64Photos ?? [];
  securite.lesions = securite.lesions ?? [];

  const formModel: SecuriteFormModel = {
    ...securite,
    heureAccident: new Date(securite.dateEtHeure),
    temoin,
    tiers,
    isTemoin,
    isTiers,
  };

  return formModel;
}

function formModelToSecurite(securiteFormModel: SecuriteFormModel, id?: string): Securite {
  const accident = securiteFormModel as Securite;

  accident.dateEtHeure = dateUtil.composeFromSeparateDateAndTime(
    securiteFormModel.dateEtHeure,
    securiteFormModel.heureAccident
  );

  accident.id = id ?? securiteFormModel.id;

  return accident;
}

function formModelToDangerGrave(dangerGraveFormModel: DangerGraveFormModel): DangerGrave {
  const dateEtHeure: Date = dateUtil.composeFromSeparateDateAndTime(
    dangerGraveFormModel.dateDanger,
    dangerGraveFormModel.heureDanger
  );
  const dangerGrave: DangerGrave = { ...dangerGraveFormModel, dateEtHeure };

  return dangerGrave;
}

const formModelService = {
  accidentToFormModel,
  formModelToAccident,
  securiteToFormModel,
  formModelToSecurite,
  formModelToDangerGrave,
};

export default formModelService;
