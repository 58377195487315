import { Skeleton, Typography } from "@mui/material";
import { routesConfig } from "app-config";
import TypeAccident from "constants/TypeAccident";
import TypeSecurite from "constants/TypeSecurite";
import type { Declaration } from "models/Declaration";
import { useData } from "providers/DataProvider";
import type { PropsWithChildren, ReactElement } from "react";
import { Link, type To } from "react-router-dom";
import declarationService from "services/declarationService";
import type { EditionDetails } from "./useDeclarationCreationDetails";

type RequalificationDetailsSubtitleProps = {
  isLoading: boolean;
  declaration: Declaration;
  requalDetails?: EditionDetails;
  isSmallScreen?: boolean;
  isRequalifying?: boolean;
  origin?: boolean;
};

const emStyle = { fontStyle: "normal", fontWeight: 600 };

function RequalificationDetailsSubtitle({
  isLoading,
  declaration,
  requalDetails,
  isSmallScreen = false,
  isRequalifying = false,
  origin = false,
}: RequalificationDetailsSubtitleProps): ReactElement {
  const { isLoading: isDataLoading } = useData();

  if (declaration.requalification == null) {
    return <></>;
  }

  const linkToRequal: To = declarationService.isAccident(declaration.requalification)
    ? routesConfig.accident.getParameterPath(declaration?.requalification?.id ?? "")
    : routesConfig.securite.getParameterPath(declaration?.requalification?.id ?? "");

  if (isLoading || isDataLoading) {
    return <Skeleton width="75%" />;
  }

  function shouldDisplayLink(declaration: Declaration): boolean {
    if (declaration.isRequalificationOrigin ?? false) {
      return (
        declaration.type === TypeSecurite.ACCIDENT_BENIN &&
        (declaration.requalification?.type === TypeAccident.TRAJET ||
          declaration.requalification?.type === TypeAccident.TRAVAIL)
      );
    } else {
      return (
        declaration.requalification?.type === TypeSecurite.ACCIDENT_BENIN &&
        (declaration.type === TypeAccident.TRAJET || declaration.type === TypeAccident.TRAVAIL)
      );
    }
  }

  return (
    <Typography variant={isSmallScreen ? "subtitle2" : "subtitle1"} gutterBottom>
      {!isRequalifying && (
        <>
          {origin ? "" : "A été "}
          {"requalifiée par "}
          <em style={emStyle}>{requalDetails?.auteur}</em>
          {" le "}
          <em style={emStyle}>{requalDetails?.date}</em>
          {` à ${requalDetails?.time}`}
        </>
      )}
      {origin ? " à partir de " : " en "}
      <ConditionalLink condition={shouldDisplayLink(declaration)} to={linkToRequal}>
        <em style={emStyle}>
          {declarationService.getTypeDeclarationLabelFromDeclaration(
            declaration.requalification,
            true
          )}
        </em>{" "}
        n°
        <em style={emStyle}>{declaration.requalification.displayNumber ?? ""}</em>
      </ConditionalLink>
    </Typography>
  );
}

type ConditionalLinkProps = PropsWithChildren<{
  condition: boolean;
  to: string | To;
}>;

function ConditionalLink({ condition, children, to }: ConditionalLinkProps): JSX.Element {
  if (condition) {
    return <Link to={to}>{children}</Link>;
  } else {
    return <>{children}</>;
  }
}

export default RequalificationDetailsSubtitle;
