import { useState } from "react";
import type { ReactElement } from "react";
import {
  Card,
  CardActionArea,
  Box,
  useTheme,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import ImageZoomModal from "components/inputs/photos/ImageZoomModal";
import DeleteButton from "./DeleteButton";

type PhotoInputProps = {
  base64String?: string;
  isLoading?: boolean;
  readOnly?: boolean;
  deleteImage: () => void;
  selectImage: () => void;
};

function PhotoInput({
  base64String,
  isLoading = false,
  readOnly = false,
  deleteImage,
  selectImage,
}: PhotoInputProps): ReactElement {
  const [openZoomModal, setOpenZoomModal] = useState<boolean>(false);

  const allZero = {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    m: "auto",
  };

  /* trick CSS savoureux de vieux singe à qui on n'apprend pas
    à faire la grimace pour avoir un ratio de 1:1 (carré) */
  const square = {
    width: "100%",
    height: 0,
    pb: "100%",
  };

  function handleClick(): void {
    if (base64String != null && base64String !== "") {
      setOpenZoomModal(true);
    } else if (!(readOnly ?? false)) {
      selectImage();
    }
  }

  function Image(): ReactElement {
    const squareWithImage = {
      ...square,
      backgroundImage: `url("${base64String ?? ""}")`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
    };

    function getInnerCard(): ReactElement {
      if (isLoading) {
        return (
          <CircularProgress
            disableShrink
            sx={{
              position: "absolute",
              ...allZero,
            }}
          />
        );
      }

      if (!readOnly && base64String == null) {
        return (
          <Box sx={square}>
            <AddAPhotoIcon
              color="secondary"
              sx={{
                position: "absolute",
                ...allZero,
                fontSize: "60px",
              }}
            />
          </Box>
        );
      }

      return <></>;
    }

    return (
      <CardActionArea
        onClick={handleClick}
        sx={{
          ...(base64String != null && base64String !== "" ? { cursor: "zoom-in" } : {}),
          ...(isLoading ? { cursor: "progress" } : {}),
        }}>
        <Box sx={squareWithImage}>{getInnerCard()}</Box>
      </CardActionArea>
    );
  }

  const smUp = useMediaQuery(useTheme().breakpoints.up("sm"));
  const responsiveBorder = {
    bgcolor: "#F2F2F2",
    border: smUp ? "3px dashed #4B515D" : "2px dashed #4B515D",
  };

  return (
    <>
      <ImageZoomModal
        altText="Image illustrant les circonstances de l'accident"
        src={base64String!}
        open={openZoomModal}
        setOpen={setOpenZoomModal}
      />
      <Card
        variant="outlined"
        sx={base64String != null && base64String !== "" ? {} : responsiveBorder}>
        <Box
          sx={{
            position: "relative",
            height: "100%",
            width: "100%",
          }}>
          <Image />
          {!readOnly && base64String != null && base64String !== "" && (
            <DeleteButton onClick={deleteImage} />
          )}
        </Box>
      </Card>
    </>
  );
}

export default PhotoInput;
