import "./App.css";
import SDEAThemeProvider from "providers/SDEAThemeProvider";
import { OnlineStatusProvider } from "providers/OnlineStatusProvider";
import type { ReactElement } from "react";
import frLocale from "date-fns/locale/fr";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import unAuthenticatedRouter from "routes/unAuthenticatedRouter";
import { RouterProvider } from "react-router-dom";
import ErrorPage from "components/errors/ErrorPage";
import authenticatedRouter from "routes/authenticatedRouter";
import { AppInsightsProvider } from "@sdeapps/react-core";
import { appiConfig, config } from "app-config";
import { SecuAuthentificationProvider } from "providers/Authentication/SecuAuthentificationProvider";

function App(): ReactElement {
  return (
    <div className="App">
      <AppInsightsProvider
        appVersion={config.appVersion}
        onError={ErrorPage}
        instrumentationKey={appiConfig.instrumentationKey}>
        <OnlineStatusProvider>
          <SDEAThemeProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frLocale}>
              <SecuAuthentificationProvider
                unauthorizedPage={<RouterProvider router={unAuthenticatedRouter} />}>
                <RouterProvider router={authenticatedRouter} />
              </SecuAuthentificationProvider>
            </LocalizationProvider>
          </SDEAThemeProvider>
        </OnlineStatusProvider>
      </AppInsightsProvider>
    </div>
  );
}

export default App;
