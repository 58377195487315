import { Avatar } from "@mui/material";
import Badge from "@mui/material/Badge";
import { useIsAuthenticated } from "@azure/msal-react";
import { useOnlineStatus } from "providers/OnlineStatusProvider";
import { useUser } from "providers/Authentication/SecuUserProvider";
import type { ReactElement } from "react";

function getInitials(name: string): string {
  const names = name.split(" ");
  let initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
}

function HeaderAvatar(): ReactElement {
  const { user } = useUser();
  const isAuthenticated = useIsAuthenticated();

  const { isOnline } = useOnlineStatus();

  function getBadgeColor(): "default" | "success" | "warning" {
    if (!isOnline) {
      return "default";
    }
    if (isAuthenticated) {
      return "success";
    }
    return "warning";
  }

  if (user == null) {
    return <Avatar />;
  } else {
    return (
      <Badge
        color={getBadgeColor()}
        overlap="circular"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        variant="dot">
        <Avatar alt={user.displayName} src={user.avatar} />
        {user?.avatar == null && getInitials(user.displayName)}
      </Badge>
    );
  }
}

export default HeaderAvatar;
