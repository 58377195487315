import { useEffect, useState } from "react";
import type { ReactElement, ReactNode } from "react";
import { Card, CardContent, Collapse, useTheme } from "@mui/material";
import { useInfo } from "providers/InfoBullesProvider";

interface InfoBulleProps {
  infoKey: string;
  children?: ReactNode;
}

/**
 * Affiche une card informative si le InfoSwitch associé (avec la même infoKey) est activé.
 * Doit être à l'intérieur d'un InfoBulleProvider.
 * @param {string} infoKey clé permettant de faire le lien entre ce composant et le toggle associé
 * @param {ReactNode} children les enfants, affichés une fois le toggle activé
 */
function InfoBulle({ infoKey, children = "" }: InfoBulleProps): ReactElement {
  const [show, setShow] = useState(false);
  const { infoSwitches, getInfoBulleSwitch: getInfoSwitch } = useInfo();

  useEffect(() => {
    setShow(getInfoSwitch(infoKey));
  }, [infoKey, infoSwitches]);

  const theme = useTheme();

  return (
    <Collapse in={show}>
      <Card sx={{ background: theme.palette.info.light, border: "none" }} variant="outlined">
        <CardContent sx={{ color: theme.palette.info.dark }}>{children}</CardContent>
      </Card>
    </Collapse>
  );
}

export default InfoBulle;
