import { createContext } from "react";
import type Ouvrage from "models/Ouvrage";
import type User from "models/User";
import type Commune from "models/Commune";
import type { ControlledTextFieldOptions } from "components/inputs/ControlledTextField";

export interface IData {
  isLoading: boolean;
  communes: Array<Commune>;
  territoires: ControlledTextFieldOptions;
  ouvrages: Array<Ouvrage>;
  agents: Array<User>;
  error: boolean;
}

const data: IData = {
  isLoading: true,
  communes: [],
  territoires: [],
  ouvrages: [],
  agents: [],
  error: false,
};

const DataContext = createContext(data);

export default DataContext;
