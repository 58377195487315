import { LinearProgress } from "@mui/material";
import { GridOverlay } from "@mui/x-data-grid";
import type { ReactElement } from "react";

function LoadingOverlay(): ReactElement {
  return (
    <GridOverlay>
      <div style={{ position: "absolute", top: -5, width: "100%" }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  );
}

export default LoadingOverlay;
