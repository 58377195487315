/**
 * Checke si le user a l'air d'être online en s'auto-requêtant avec un paramètre
 * get unique pour éviter la version cachée toujours disponible
 * @returns Promise boolean : true si il a l'air d'être online, false si offline
 */
async function isOnline(): Promise<boolean> {
  let isUserOnline = false;
  const url = `${window.location.origin}?${Date.now()}`;
  const options = {
    method: "HEAD",
  };

  function checkResult(result: Response): void {
    if (result.status === 200) {
      isUserOnline = true;
    }
  }

  try {
    // Si le navigateur (auquel on ne peut pas faire 100% confiance) pense
    // que l'utilisateur est offline, on timeout rapidement la requête.
    if (navigator.onLine) {
      await fetch(url, { ...options, mode: "cors" }).then(checkResult);
    } else {
      await Promise.race([
        fetch(url, { ...options, mode: "cors" }).then(checkResult),
        new Promise((_resolve, reject) =>
          setTimeout(() => {
            reject(new Error("timeout"));
          }, 500)
        ),
      ]);
    }
  } catch {
    isUserOnline = false;
  }
  return isUserOnline;
}

const onlineService = {
  isOnline,
};

export default onlineService;
