import type User from "models/User";

function getById(agents: Array<User>, id: string): User | undefined {
  return agents.find((u: User) => u.id === id);
}

function getDisplayNameById(
  agents: Array<User>,
  id: string,
  firstName: string = "",
  lastName: string = ""
): string | undefined {
  const user = agents.find((u: User) => u.id === id);
  if (user !== undefined) {
    return user?.displayName;
  }
  if (lastName !== "" && firstName !== "") {
    return `${lastName} ${firstName}`;
  }
  return undefined;
}

function getSurnameById(agents: Array<User>, id: string): string | undefined {
  return agents.find((u: User) => u.id === id)?.surname;
}

function getShortNameById(
  agents: Array<User>,
  id: string,
  firstName: string = "",
  lastName: string = ""
): string | undefined {
  const user = agents.find((u: User) => u.id === id);
  if (user?.surname != null && user?.givenName != null) {
    return `${user?.surname} ${user?.givenName?.substring(0, 1)}.`;
  }
  if (lastName !== "" && firstName !== "" && firstName != null && lastName != null) {
    return `${lastName} ${firstName.substring(0, 1)}.`;
  }
  return undefined;
}

const agentService = {
  getById,
  getDisplayNameById,
  getSurnameById,
  getShortNameById,
};

export default agentService;
