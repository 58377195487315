import { IconButton } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useNavigate } from "react-router-dom";
import type { ReactElement } from "react";

interface BackButtonProps {
  disabled?: boolean;
  toHomePage?: boolean;
}
/**
 * Un Bouton qui permet de revenir en arrière (utilise le comportement natif du navigateur)
 * @param disabled Booléen qui détermine si le bouton est désactivé; par défaut, false
 * @param toHomePage Booléen qui détermine si le bouton renvoie à la page d'accueil; par défaut, false
 */
function BackButton({ disabled = false, toHomePage = false }: BackButtonProps): ReactElement {
  const navigate = useNavigate();

  return (
    <IconButton
      onClick={() => {
        if (toHomePage) {
          navigate("/");
        } else {
          navigate(-1);
        }
      }}
      disabled={disabled}>
      <ChevronLeftIcon fontSize="large" />
    </IconButton>
  );
}

export default BackButton;
