const Informations = {
  ACCIDENTACTIVITEHABITUELLE: (
    <>
      Décrivez brièvement le travail que vous faites habituellement.
      <br /> Exemple : lavage de réservoirs
    </>
  ),
  ACCIDENTHORAIRESHABITUELS: (
    <>
      Indiquez vos horaires habituels de travail.
      <br /> Exemple : début : 08:00 fin : 16:00
    </>
  ),
  COORDONNEESPERSONNELLES: (
    <>
      Inscrivez les coordonnées personnelles de la victime, seulement si elle n'est pas joignable
      par téléphone ou mail professionnel.
    </>
  ),
};

export default Informations;
