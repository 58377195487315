import type { ReactElement } from "react";
import ControlledTextField, { type ControlledTextFieldOptions } from "./ControlledTextField";
import type { Control } from "react-hook-form";

const ouvrageTypes: ControlledTextFieldOptions = [
  { active: true, value: "NON", label: "Ce n'est pas un ouvrage" },
  { active: true, value: "EPU", label: "STEU" },
  { active: true, value: "OEP", label: "Ouvrage EP" },
  { active: true, value: "PPEU", label: "Ouvrage PPEU" },
  { active: true, value: "DO", label: "Ouvrage DO" },
];

interface ControlledOuvrageTypeSelectProps {
  name: string;
  control: Control;
  label: string;
  defaultValue?: string;
  readOnly?: boolean;
}

function ControlledOuvrageTypeSelect({
  name,
  control,
  label,
  defaultValue,
  readOnly,
}: ControlledOuvrageTypeSelectProps): ReactElement {
  return (
    <ControlledTextField
      name={name}
      control={control}
      defaultValue={defaultValue}
      label={label}
      readOnly={readOnly}
      options={ouvrageTypes}
      select
    />
  );
}

export default ControlledOuvrageTypeSelect;
