import { useEffect, useState } from "react";
import type { ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, Controller, type SubmitHandler, type FieldValues } from "react-hook-form";
import { Button, Grid, Typography } from "@mui/material";
import accidentService from "services/accidentService";
import type { Accident } from "models/Accident";
import LesionSection from "components/inputs/lesions/LesionSection";
import JoursTravaillesCheckboxes from "components/inputs/JoursTravaillesCheckboxes";
import SaveIcon from "@mui/icons-material/Save";
import StatutCompletion from "constants/StatutCompletion";
import { useSnackbar } from "notistack";
import ToastMessages from "constants/ToastMessages";
import ControlledTextField from "components/inputs/ControlledTextField";
import ControlledTimePicker from "components/inputs/ControlledTimePicker";
import LoadingButton from "components/buttons/LoadingButton";
import formModelService from "services/formModelService";
import type { AccidentFormModel } from "models/AccidentFormModel";
import { grey } from "@mui/material/colors";
import SkeletonFormComplement from "components/loading/SkeletonFormComplement";
import InfoBulle from "components/utils/InfoBulles/InfoBulle";
import Informations from "constants/Informations";
import { InfoBullesProvider } from "providers/InfoBullesProvider";
import FormSectionTitle from "./FormSectionTitle";
import typeActivites from "data/typeActivites";
import { dateUtil } from "@sdeapps/react-core";
import ApplicationRoles from "constants/ApplicationRoles";
import { useUser } from "providers/Authentication/SecuUserProvider";

const nbsp = "\u00A0"; // non-breakable space

interface AccidentComplementFormProps {
  accident?: Accident;
  isLoading: boolean;
  updateReferenceDeclaration: (accident: Accident) => void;
}

function AccidentComplementForm({
  accident,
  isLoading,
  updateReferenceDeclaration,
}: AccidentComplementFormProps): ReactElement {
  const navigate = useNavigate();
  const [isFormReadonly, setIsFormReadonly] = useState<boolean>(false);
  const [disableSending, setDisableSending] = useState(false);
  const { isRoles } = useUser();
  const { enqueueSnackbar } = useSnackbar();

  const { handleSubmit, control, reset } = useForm({ shouldFocusError: false });

  async function onSubmit(dec: AccidentFormModel): Promise<void> {
    setDisableSending(true);

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const declarationComplement = formModelService.formModelToAccident(dec, accident!.id!);
    declarationComplement.statutCompletion = StatutCompletion.Terminee;

    try {
      const updatedDeclaration: Accident = isRoles(ApplicationRoles.MODERATOR)
        ? await accidentService.update(declarationComplement)
        : await accidentService.complete(declarationComplement);
      updateReferenceDeclaration(updatedDeclaration);
      enqueueSnackbar(ToastMessages.SUCCESS_MODIFY, {
        variant: "success",
        autoHideDuration: 5000,
      });
      navigate("/");
    } catch (error) {
      console.error(error);
      enqueueSnackbar(ToastMessages.ERROR_UPDATE, {
        variant: "error",
      });
      setDisableSending(false);
    }
  }

  useEffect(() => {
    if (!isLoading && accident !== undefined) {
      const dec = formModelService.accidentToFormModel(accident);

      reset(dec);

      setIsFormReadonly(
        dec?.statutCompletion === StatutCompletion.Terminee && !isRoles(ApplicationRoles.MODERATOR)
      );
    }
  }, [accident, isLoading]);

  if (isLoading) {
    return <SkeletonFormComplement />;
  }

  function getOptionalLabelIfPrestataire(label: string): string {
    return `${label}${!(accident?.isVictimePrestataire ?? false) ? " *" : ""}`;
  }

  function getOptionalRulesIfPrestataire(
    maybeRules: object,
    alwaysRules?: object
  ): object | undefined {
    if (accident?.isVictimePrestataire ?? false) {
      return { ...alwaysRules };
    }
    return { ...maybeRules, ...alwaysRules };
  }

  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <form onSubmit={handleSubmit(onSubmit as SubmitHandler<FieldValues>)}>
      <Grid container spacing={3}>
        <InfoBullesProvider>
          {accident?.statutCompletion !== StatutCompletion.Terminee && (
            <Grid item xs={12}>
              <Button variant="contained" href="/">
                Compléter plus tard
              </Button>
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography variant="caption" sx={{ color: grey[500] }}>
              Les champs suivis d'un astérisque ( * ) sont obligatoires
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormSectionTitle title="Lésion(s)" infoKey="" />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="lesions"
              defaultValue={accident?.lesions ?? []}
              control={control}
              render={({ field: { onChange, value } }) => (
                <LesionSection value={value} onChange={onChange} readOnly={isFormReadonly} />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <FormSectionTitle title="Activités" infoKey="activites" />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              name="typeActivite"
              control={control}
              rules={getOptionalRulesIfPrestataire({ required: "Ce champ est obligatoire" })}
              label={getOptionalLabelIfPrestataire("Type d'activité")}
              defaultValue={accident?.typeActivite ?? ""}
              readOnly={isFormReadonly}
              options={typeActivites}
              select
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              name="activiteHabituelle"
              control={control}
              rules={getOptionalRulesIfPrestataire({ required: "Ce champ est obligatoire" })}
              label={getOptionalLabelIfPrestataire("Activité habituelle")}
              defaultValue={accident?.activiteHabituelle}
              placeholder="Champ libre obligatoire"
              readOnly={isFormReadonly}
            />
          </Grid>
          <Grid item xs={12}>
            <InfoBulle infoKey="activites">{Informations.ACCIDENTACTIVITEHABITUELLE}</InfoBulle>
          </Grid>
          <Grid item container xs={12}>
            <Controller
              name="joursTravailles"
              control={control}
              rules={getOptionalRulesIfPrestataire({
                required: "Veuillez sélectionner les jours durant lesquels vous travaillez",
                validate: (v: Array<string>) => {
                  return (
                    (v.length > 0 && v[0] !== "") ||
                    "Veuillez sélectionner les jours durant lesquels vous travaillez"
                  );
                },
              })}
              defaultValue={accident?.joursTravailles ?? []}
              render={({ field: { onChange, ref }, fieldState: { error } }) => (
                <JoursTravaillesCheckboxes
                  label={getOptionalLabelIfPrestataire("Jours travaillés la semaine de l'accident")}
                  defaultValue={accident?.joursTravailles ?? []}
                  onChange={onChange}
                  inputRef={ref}
                  readOnly={isFormReadonly}
                  error={error != null}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
          <Grid item container xs={12} sm={6}>
            <ControlledTimePicker
              name="horaireTravailDebut"
              control={control}
              defaultValue={accident?.horaireTravailDebut}
              label={getOptionalLabelIfPrestataire("Horaire de début de travail")}
              rules={getOptionalRulesIfPrestataire(
                {
                  required: "Veuillez renseigner une heure valide",
                },
                {
                  validate: (value: Date) =>
                    dateUtil.isValid(value) || "Veuillez renseigner une heure valide",
                }
              )}
              readOnly={isFormReadonly}
            />
          </Grid>
          <Grid item container xs={12} sm={6}>
            <ControlledTimePicker
              name="horaireTravailFin"
              control={control}
              defaultValue={accident?.horaireTravailFin}
              label={getOptionalLabelIfPrestataire("Horaire de fin de travail")}
              rules={getOptionalRulesIfPrestataire(
                {
                  required: "Veuillez renseigner une heure valide",
                },
                {
                  validate: (value: Date) =>
                    dateUtil.isValid(value) || "Veuillez renseigner une heure valide",
                }
              )}
              readOnly={isFormReadonly}
            />
          </Grid>
          <Grid item xs={12}>
            <InfoBulle infoKey="activites">{Informations.ACCIDENTHORAIRESHABITUELS}</InfoBulle>
          </Grid>
          <Grid item xs={12}>
            <FormSectionTitle title="Coordonnées" infoKey="coordonnees" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ControlledTextField
              name="victimeTelPerso"
              control={control}
              rules={{
                pattern: {
                  value: /^((\+\d{2}[./\s]?(\d{1,2}[./\s]?))|(\d{1,2}[./\s]?))(\d{2}[./\s]?){4}$/,
                  message: `Numéro de téléphone invalide : Les formats acceptés sont XX${nbsp}XX${nbsp}XX${nbsp}XX${nbsp}XX, XX.XX.XX.XX.XX, +XX${nbsp}X${nbsp}XX${nbsp}XX${nbsp}XX${nbsp}XX`,
                },
              }}
              defaultValue={accident?.victimeTelPerso}
              label="Téléphone de la victime"
              placeholder="01 02 03 04 05"
              readOnly={isFormReadonly}
              defaultHelperText={`Les formats acceptés sont XX${nbsp}XX${nbsp}XX${nbsp}XX${nbsp}XX, XX.XX.XX.XX.XX, +XX${nbsp}X${nbsp}XX${nbsp}XX${nbsp}XX${nbsp}XX`}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ControlledTextField
              name="victimeEmailPerso"
              control={control}
              rules={{
                pattern: {
                  value:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: "Adresse e-mail invalide",
                },
              }}
              defaultValue={accident?.victimeEmailPerso}
              label="E-mail personnel de la victime"
              placeholder="john.doe@msn.com"
              readOnly={isFormReadonly}
            />
          </Grid>
          <Grid item xs={12}>
            <InfoBulle infoKey="coordonnees">{Informations.COORDONNEESPERSONNELLES}</InfoBulle>
          </Grid>
          <Grid item xs={12}>
            <LoadingButton
              loading={disableSending}
              endIcon={<SaveIcon />}
              disabled={isFormReadonly || disableSending}>
              {accident?.statutCompletion === StatutCompletion.Terminee &&
              isRoles(ApplicationRoles.MODERATOR)
                ? "Modifier"
                : "Enregistrer"}
            </LoadingButton>
          </Grid>
        </InfoBullesProvider>
      </Grid>
      <br />
    </form>
  );
}

export default AccidentComplementForm;
