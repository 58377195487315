import type { ControlledTextFieldOptions } from "components/inputs/ControlledTextField";
import TypeActivite from "constants/TypeActivite";

const typeActivites: ControlledTextFieldOptions = [
  { active: true, label: "Bureau", value: TypeActivite.Bureau },
  {
    active: true,
    label: "Atelier / Terrain",
    value: TypeActivite.AtelierTerrain,
  },
  { active: true, label: "Laboratoire", value: TypeActivite.Laboratoire },
  { active: true, label: "Autre", value: TypeActivite.Autre },
];

export default typeActivites;
