import {
  type Control,
  Controller,
  type FieldValues,
  type UseFormRegister,
  type UseFormSetValue,
  type UseFormUnregister,
} from "react-hook-form";
import YesNoText from "./YesNoText";
import type { ReactElement } from "react";

interface ControlledYesNoTextProps {
  control: Control;
  name: string;
  register: UseFormRegister<FieldValues>;
  unregister: UseFormUnregister<FieldValues>;
  textFieldName: string;
  setValue: UseFormSetValue<FieldValues>;
  label: string;
  isNew: boolean;
  defaultValue?: string;
  readOnly?: boolean;
  textLabel?: string;
  textPlaceholder?: string;
  textRequired?: string;
}

function ControlledYesNoText({
  control,
  name,
  register,
  unregister,
  textFieldName,
  setValue,
  label,
  isNew,
  defaultValue,
  readOnly = false,
  textLabel = "",
  textPlaceholder = "",
  textRequired = "",
}: ControlledYesNoTextProps): ReactElement {
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        validate: (v?: boolean) => (v == null ? "Veuillez répondre à la question" : true),
      }}
      render={({ field: { onChange, value, ref }, formState: { errors } }) => (
        <YesNoText
          value={value}
          name={name}
          onChange={onChange}
          register={register}
          unregister={unregister}
          textFieldName={textFieldName}
          setValue={setValue}
          label={label}
          errors={errors}
          radioRef={ref}
          isNew={isNew}
          defaultValue={defaultValue}
          readOnly={readOnly}
          textLabel={textLabel}
          textPlaceholder={textPlaceholder}
          textRequired={textRequired}
        />
      )}
    />
  );
}

export default ControlledYesNoText;
