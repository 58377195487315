import {
  type PropsWithChildren,
  type ReactElement,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useAccount, useMsal } from "@azure/msal-react";
import type User from "models/User";
import { InteractionStatus } from "@azure/msal-browser";
import authService from "services/authService";
import type ApplicationRoles from "constants/ApplicationRoles";
import { localStorageKeys } from "app-config";
import graphService from "services/graphService";
import storageService from "services/storageService";

interface UserProviderProps extends PropsWithChildren {
  unauthorizedPage?: ReactElement;
}

/**
 * UserProvider custom, spécifique à Eau-Secours, qui cherche les directReports de l'utilisateur,
 * enregistre l'utilisateur dans le localStorage, et passe au InnerUserProvider ce même utilisateur
 * provenant du localStorage.
 */
function SecuUserProvider({ children, unauthorizedPage }: UserProviderProps): ReactElement {
  const { inProgress } = useMsal();
  const [user, setUser] = useState<User>();
  const [roles, setRoles] = useState<Array<string>>([]);
  const accountInfo = useAccount();

  // update user avec les informations du localStorage lorsque celui-ci est updaté
  useEffect(() => {
    checkUserDataStorage();
    window.addEventListener("storage", checkUserDataStorage);

    return () => {
      window.removeEventListener("storage", checkUserDataStorage);
    };
  }, []);

  /**
   * Cherche les informations de l'utilisateur dans le localStorage
   */
  function checkUserDataStorage(): void {
    const userDataString = localStorage.getItem(localStorageKeys.currentUserData);

    if (userDataString != null) {
      try {
        const newUser: User = JSON.parse(userDataString);
        setUser(newUser);
      } catch (error) {
        console.error(error);
      }
    } else {
      setUser(undefined);
    }
  }

  useEffect(() => {
    async function getFullUserInfos(): Promise<void> {
      try {
        const userInfos = await authService.getUserInfo();
        userInfos.directReports = await graphService.getDirectReports();
        if (userInfos != null) {
          storageService.updateStorageUser(userInfos);
        }
      } catch (error) {
        console.error("error getting user infos & directReports");
      }
    }

    if (
      inProgress === InteractionStatus.None &&
      // condition bancale
      (accountInfo == null || accountInfo.idTokenClaims?.oid !== user?.id)
    ) {
      void getFullUserInfos();
      setRoles(accountInfo?.idTokenClaims?.roles ?? []);
    }
  }, [accountInfo, inProgress]);

  if (user == null) {
    return <>{unauthorizedPage}</>;
  }

  return (
    <InnerUserProvider user={user} roles={roles}>
      {children}
    </InnerUserProvider>
  );
}

interface UserContextProps {
  /** Modèle de l'utilisateur */
  user: User;
  /** Teste si l'utilisateur possède l'un des rôles passés en paramètre */
  isRoles: (roles: Array<ApplicationRoles> | ApplicationRoles) => boolean;
}

const UserContext = createContext<null | UserContextProps>(null);

interface InnerUserProviderProps extends PropsWithChildren {
  user: User;
  roles: Array<string>;
}

function InnerUserProvider({ user, roles = [], children }: InnerUserProviderProps): ReactElement {
  const isRoles = useCallback(
    (rolesToTest: Array<ApplicationRoles> | ApplicationRoles): boolean => {
      let res: boolean = false;
      const trueRoles = Array.isArray(rolesToTest) ? rolesToTest : [rolesToTest];
      trueRoles.forEach((role) => {
        res = (roles.includes(role) ?? false) || res;
      });
      return res;
    },
    [roles]
  );

  const memoizedUserContext: UserContextProps = useMemo(() => ({ user, isRoles }), [user, isRoles]);

  return <UserContext.Provider value={memoizedUserContext}>{children}</UserContext.Provider>;
}

function useUser(): UserContextProps {
  const value = useContext(UserContext);
  if (value == null) {
    throw new Error("Vous devez entourer ce composant d'un <UserProvider>");
  }
  return value;
}

export default SecuUserProvider;

export { useUser };
