import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import type { ReactElement } from "react";
import { Controller } from "react-hook-form";
import type { Control } from "react-hook-form";

interface ControlledRadioGroupProps {
  name: string;
  control: Control;
  options: Array<{ label: string; value: string }>;
  label: string;
  defaultValue?: string | null;
  rules?: object; // difficile de retranscrire ce type
  fullWidth?: boolean;
}

/**
 * Un RadioGroup de mui controllé par react-hook-forms.
 * @param name obligatoire - Nom unique de l'input
 * @param control obligatoire - objet Control du formulaire react-hook-forms
 * https://react-hook-form.com/api/useform/control
 * @param options obligatoire - tableau de clé-valeur permettant de mapper
 * les labels et leurs valeurs
 * @param label obligatoire - label de l'input
 * @param defaultValue valeur par défaut de l'input
 * @param rules règles de validation de l'input au même format que
 * les options de la méthode register() de react-hook-forms
 * https://react-hook-form.com/api/useform/register#options
 * @param fullWidth - booléen déterminant si l'input prend toute la place
 * horizontale disponible
 * @returns Un RadioGroup de mui controllé par react-hook-forms
 */
function ControlledRadioGroup({
  name,
  control,
  options,
  label,
  defaultValue = "",
  rules,
  fullWidth = false,
}: ControlledRadioGroupProps): ReactElement {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl fullWidth={fullWidth} error={error != null}>
          <FormLabel>{label}</FormLabel>
          <RadioGroup value={value} onChange={onChange}>
            <Grid container spacing={2}>
              {options.map(({ label, value }) => {
                return (
                  <Grid item xs="auto" key={value}>
                    <FormControlLabel value={value} control={<Radio />} label={label} />
                  </Grid>
                );
              })}
            </Grid>
          </RadioGroup>
          {error != null && <FormHelperText>{error?.message}</FormHelperText>}
        </FormControl>
      )}
    />
  );
}

export default ControlledRadioGroup;
