export interface DeclarationFilter {
  dateYear: string;
  victimeId: string;
  typeDeclaration: string;
  statutCompletion: string;
  commune: string;
  precisions: string;
}

export const emptyFilters: DeclarationFilter = {
  dateYear: "",
  victimeId: "",
  typeDeclaration: "",
  statutCompletion: "",
  commune: "",
  precisions: "",
};
