import { Typography } from "@mui/material";
import TypeAccident from "constants/TypeAccident";
import TypeSecurite from "constants/TypeSecurite";
import type { ReactElement } from "react";
import declarationService from "services/declarationService";

type DeclarationFormTitleProps = {
  isRequalifying: boolean;
  isNewDeclaration: boolean;
  declarationType?: string;
  displayNumber?: number;
  isSmallScreen?: boolean;
};

function getTitle(type: string, isNewDeclaration: boolean = false): string {
  let s = "";
  if (isNewDeclaration) {
    switch (type) {
      case TypeAccident.TRAJET:
      case TypeAccident.TRAVAIL:
      case TypeSecurite.ACCIDENT_BENIN:
        s += "Nouvel ";
        break;
      case TypeSecurite.PRESQU_ACCIDENT:
        s += "Nouveau ";
        break;
      case TypeSecurite.SITUATION_DANGEREUSE:
        s += "Nouvelle ";
        break;
    }
  }
  s += declarationService.getTypeDeclarationLabel(type);
  return s;
}

/** Ce composant scrolle en haut de la page à la navigation */
function DeclarationFormTitle({
  isRequalifying,
  isNewDeclaration,
  declarationType = "",
  displayNumber,
  isSmallScreen = false,
}: DeclarationFormTitleProps): ReactElement {
  return (
    <Typography variant={isSmallScreen ? "h5" : "h4"} component="div">
      {isRequalifying ? "Requalification en " : ""}
      {getTitle(declarationType, isNewDeclaration && !isRequalifying)}
      {displayNumber !== undefined && !isRequalifying && ` n°${displayNumber}`}
    </Typography>
  );
}

export default DeclarationFormTitle;
