import { Grid, Typography } from "@mui/material";
import DividerTitle from "components/utils/DividerTitle";
import InfoBulleSwitch from "components/utils/InfoBulles/InfoBulleSwitch";
import type { ReactElement } from "react";

type FormSectionTitleProps = {
  title: string;
  infoKey?: string;
};

function FormSectionTitle({ title, infoKey }: FormSectionTitleProps): ReactElement {
  return (
    <Grid container alignItems="center">
      <Grid item xs>
        <DividerTitle>
          <Typography variant="h5">{title}</Typography>
        </DividerTitle>
      </Grid>
      {infoKey != null && infoKey !== "" && (
        <Grid item>
          <InfoBulleSwitch infoKey={infoKey} />
        </Grid>
      )}
    </Grid>
  );
}

export default FormSectionTitle;
