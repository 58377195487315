import { Typography, useTheme } from "@mui/material";
import type { ReactElement } from "react";

function IntroText(): ReactElement {
  const theme = useTheme();

  return (
    <>
      <Typography sx={{ span: { color: theme.palette.primary.main } }}>
        <span>
          <strong>Eau Secours</strong>
        </span>{" "}
        est votre nouvelle application pour déclarer vos <span>accidents de travail</span> et{" "}
        <span>de trajet</span>, ainsi que vos <span>situations dangereuses</span>,{" "}
        <span>presqu'accidents</span> et <span>accidents bénins</span>.
      </Typography>
    </>
  );
}

export default IntroText;
