import { useEffect, useRef, useState } from "react";
import type { ReactElement, ReactNode } from "react";
import { MenuItem, TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import type { Control } from "react-hook-form";

export type ControlledTextFieldOptions = Array<{
  active: boolean;
  value: string;
  label: string;
}>;

interface ControlledTextFieldProps {
  name: string;
  control: Control;
  label: string;
  defaultValue?: string;
  placeholder?: string;
  readOnly?: boolean;
  rules?: object; // difficile de retranscrire ce type
  defaultHelperText?: string;
  select?: boolean;
  options?: ControlledTextFieldOptions;
  children?: ReactNode;
  variant?: "outlined" | "standard" | "filled";
  multiline?: boolean;
  fullWidth?: boolean;
}

/**
 * Un Textfield (ou Select) de mui controllé par react-hook-forms.
 * @param name obligatoire - Nom unique de l'input
 * @param control obligatoire - objet Control du formulaire react-hook-forms
 * https://react-hook-form.com/api/useform/control
 * @param label obligatoire - label de l'input
 * @param defaultValue valeur par défaut de l'input
 * @param placeholder placeholder de l'input, par défaut même valeur que le label
 * @param readOnly booléen déterminant si l'input en lecture seule
 * @param rules règles de validation de l'input au même format que
 * les options de la méthode register() de react-hook-forms
 * https://react-hook-form.com/api/useform/register#options
 * @param defaultHelperText - texte d'aide à afficher lorsque le champ n'est
 * pas en erreur
 * @param select - booléen déterminant si l'input est un select ou un champ texte
 * @param options - un array de type ControlledTextFieldOptions contenant les différentes
 * options disponibles pour le select, pouvant contenir d'anciennes données (active:false)
 * qui ne seront pas proposées aux utilisateurs dans la liste, mais dont le label sera tout
 * de même affiché si la valeur a été sélectionnée dans le passé
 * @param children - les éléments enfants de ce composant sont passés au TextField
 * (pratique pour les MenuItem d'un Select par exemple)
 * @param variant - variante graphique de l'input "outlined" | "standard" |
 * "filled", par défaut "outlined"
 * @param multiline - booléen déterminant si l'input peut s'étendre sur
 * plusieurs lignes
 * @param fullWidth - booléen déterminant si l'input prend toute la place
 * horizontale disponible
 * @returns Un TextField ou Select de mui controllé par react-hook-forms
 */
function ControlledTextField({
  name,
  control,
  label,
  defaultValue = "",
  placeholder = label,
  readOnly = false,
  rules,
  defaultHelperText,
  select = false,
  options,
  children,
  variant = "outlined",
  multiline = true,
  fullWidth = true,
}: ControlledTextFieldProps): ReactElement {
  const [open, setOpen] = useState(false);
  const previousOpen = useRef(false);

  useEffect(() => {
    previousOpen.current = open;
  }, [open]);
  function handleClose(): void {
    setOpen(false);
  }

  function handleOpen(): void {
    if (!readOnly) {
      setOpen(true);
    }
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
        <TextField
          value={value ?? ""}
          onChange={onChange}
          inputRef={ref}
          label={label}
          placeholder={placeholder}
          {...(select && {
            SelectProps: {
              open,
              onFocus: () => {
                if (!previousOpen.current) {
                  handleOpen();
                }
              },
              onBlur: handleClose,
              onClose: handleClose,
              onOpen: handleOpen,
            },
            select: true,
          })}
          InputProps={{ readOnly }}
          error={error != null}
          helperText={error?.message ?? defaultHelperText}
          variant={variant}
          multiline={multiline}
          fullWidth={fullWidth}>
          {children}
          {options
            ?.filter((o) => o.active || o.value === value)
            ?.map((ouvrageType) => {
              return (
                <MenuItem key={ouvrageType.value} value={ouvrageType.value}>
                  {ouvrageType.label}
                </MenuItem>
              );
            })}
        </TextField>
      )}
    />
  );
}

export default ControlledTextField;
