import { Box, type SxProps } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import type { ReactElement } from "react";

interface SortIconsUpProps {
  up: boolean;
  down?: never;
}
interface SortIconsDownProps {
  up?: never;
  down: boolean;
}

type SortIconsProps = SortIconsUpProps | SortIconsDownProps;

const arrowCommonSx: SxProps = {
  position: "absolute",
  margin: 0,
  left: "-6px",
};

function SortIcons({ up = false, down = false }: SortIconsProps): ReactElement {
  return (
    <Box sx={{ height: "16px", width: "12px", position: "relative" }}>
      <ArrowDropUpIcon color={up ? "info" : "disabled"} sx={{ ...arrowCommonSx, top: "-10px" }} />
      <ArrowDropDownIcon
        color={down ? "info" : "disabled"}
        sx={{ ...arrowCommonSx, bottom: "-10px" }}
      />
    </Box>
  );
}

export function SortIconsUp(): ReactElement {
  return <SortIcons up />;
}

export function SortIconsDown(): ReactElement {
  return <SortIcons down />;
}

export default SortIcons;
