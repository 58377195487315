import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  Typography,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import type { ReactElement } from "react";

interface SecuriteCreateModalProps {
  open: boolean;
  handleClose: () => void;
  isDsd: boolean;
}

function SecuriteCreateModal({ open, handleClose, isDsd }: SecuriteCreateModalProps): ReactElement {
  const message = isDsd ? "une situation dangereuse" : "un presqu'accident";
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle display="grid" gridTemplateColumns="2rem 1fr" alignItems="center">
        <InfoIcon color="primary" />
        <Typography variant="h6" color="primary">
          Information
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText textAlign="justify">
          Merci d'avoir signalé {message}.<br />
          <br />
          <strong>
            Votre déclaration a bien été prise en compte et sera traitée sous peu.
          </strong>{" "}
          Pour plus d'informations sur le traitement de votre déclaration, n'hésitez pas à revenir
          vers votre <strong>assistant de prévention</strong> et/ou le <strong>pôle SST</strong>.
          <br /> <br /> Vous trouverez ici la liste des assistants de prévention :{" "}
          <strong>
            <Link
              component="a"
              href="https://sdeaalsacemoselle.sharepoint.com/sites/odyssee-rh/SitePages/Assistant-pr%C3%A9vention.aspx"
              target="_blank">
              Le Réseau des Assistants de Prévention
            </Link>
          </strong>
          .
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Fermer</Button>
      </DialogActions>
    </Dialog>
  );
}

export default SecuriteCreateModal;
