import { Container, Typography, useMediaQuery, useTheme } from "@mui/material";
import BackButton from "components/buttons/BackButton";
import type { ReactElement } from "react";

function LegalMentionsPage(): ReactElement {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const my3 = { marginY: 3 };

  return (
    <Container maxWidth="md">
      <Typography variant={isSmallScreen ? "h4" : "h3"}>
        <BackButton />
        Mentions Légales
      </Typography>

      <Typography sx={my3}>
        Les données recueillies dans cette application font l'objet d'un traitement informatique
        ayant pour finalité de gérer votre déclaration.
        <br />
        Eau secours respecte la réglementation française et européenne sur la protection des données
        personnelles.
      </Typography>
      <Typography sx={my3}>
        En utilisant cette application, vous acceptez que vos actions soient enregistrées dans
        l'unique but de faciliter sa maintenance. Ce traitement est enregistré sous la référence
        2018-22.
      </Typography>
      <Typography sx={my3}>
        Conformément aux articles 39 et 40 de la loi du 6 janvier 1978 modifiée en 2004, vous pouvez
        exercer vos droits d'accès et de rectification auprès du Délégué à la Protection des Données
        du SDEA Franck PERRU (<a href="mailto:franck.perru@sdea.fr">franck.perru@sdea.fr</a>).
      </Typography>
    </Container>
  );
}

export default LegalMentionsPage;
