import Errors from "constants/Errors";

/**
 * Lance une exception si le statut http de la réponse est supérieur
 * ou égal à 400
 * @param res: Response
 * @returns La réponse de départ, si elle n'est pas en erreur
 */
function defaultErrorHandling(res: Response): Response {
  switch (res.status) {
    case 403:
      throw new Error(Errors.FORBIDDEN);
    case 404:
      throw new Error(Errors.NOT_FOUND);
    case 500:
      throw new Error(Errors.SERVER_ERROR);
  }
  if (res.status >= 400) {
    throw new Error(res.status.toString());
  }
  return res;
}

/**
 * Lance une exception si le statut http de la réponse est supérieur
 * ou égal à 400, ou si la réponse est undefined.
 * @param res: Response
 * @returns La réponse de départ, si elle n'est ni en erreur ni undefined.
 */
function defaultAndDefined(res: Response): Response {
  defaultErrorHandling(res);
  if (res !== undefined) {
    return res;
  }
  throw new Error("response undefined");
}

const errorHandlingService = {
  defaultErrorHandling,
  defaultAndDefined,
};
export default errorHandlingService;
