import type { ReactElement, ReactNode } from "react";
import MuiLoadingButton from "@mui/lab/LoadingButton";
import { CircularProgress } from "@mui/material";

interface LoadingButtonProps {
  loading: boolean;
  children?: ReactNode;
  variant?: "outlined" | "contained" | "text";
  disabled?: boolean;
  endIcon?: ReactNode;
  type?: "button" | "submit" | "reset";
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}
/**
 * Un Bouton avec un cercle de progression permettant de communiquer à l'utilisateur
 * que quelquechose est en train de se passer en réponse à son action sur le bouton,
 * possiblement une requête réseau.
 * @param loading obligatoire - Booléen déterminant si le bouton doit s'afficher en
 * train de charger ou non
 * @param children les enfants sont transmis au bouton (ici probablement le texte du bouton)
 * https://react-hook-form.com/api/useform/control
 * @param variant la variante graphique du bouton; "outlined" | "contained" | "text",
 * par défaut "contained"
 * @param disabled Booléen
 * @param endIcon Icône à afficher à la fin du bouton
 * @param type type de bouton; "button" | "submit" | "reset", par défaut "submit"
 * @returns Un TextField ou Select de mui controllé par react-hook-forms
 */
function LoadingButton({
  loading,
  children,
  variant = "contained",
  disabled = false,
  endIcon,
  type = "submit",
  onClick,
}: LoadingButtonProps): ReactElement {
  return (
    <MuiLoadingButton
      endIcon={endIcon}
      loading={loading}
      loadingPosition="end"
      loadingIndicator={<CircularProgress role="progressbar" color="success" size={16} />}
      type={type}
      variant={variant}
      disabled={disabled}
      onClick={onClick}>
      {children}
    </MuiLoadingButton>
  );
}

export default LoadingButton;
