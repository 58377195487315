import { Breadcrumbs, Container, Link, Typography, useMediaQuery, useTheme } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import { matchRoutes, useLocation } from "react-router-dom";
import { routesConfig } from "app-config";
import type { ReactElement, ReactNode } from "react";

const routes: Array<{ path: string; element: ReactNode }> = [];

for (const route in routesConfig) {
  if (Object.prototype.hasOwnProperty.call(routesConfig, route)) {
    routes.push({
      path: routesConfig[route].path,
      element: routesConfig[route].title,
    });
  }
}

function FilDAriane(): ReactElement {
  const location = useLocation();
  const [{ route }] = matchRoutes(routes, location) ?? [{ route: { path: "", element: "" } }];

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  if (isSmallScreen || route.path === routesConfig.home.path) {
    return <></>;
  }

  return (
    <Container maxWidth={false} sx={{ p: 1, background: "white", width: "100%" }}>
      <Breadcrumbs>
        <Link
          sx={{
            display: "flex",
            alignItems: "center",
            textDecoration: "none",
            color: theme.palette.info.main,
            "&:hover": { textDecoration: "underline" },
          }}
          href="/">
          <HomeIcon sx={{ mr: 0.5 }} fontSize="small" />
          Accueil
        </Link>
        <Typography color="secondary">{route?.element}</Typography>
      </Breadcrumbs>
    </Container>
  );
}

export default FilDAriane;
