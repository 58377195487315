import { useEffect, useState } from "react";
import type { BaseSyntheticEvent, ReactElement } from "react";
import { useForm, Controller, type SubmitHandler, type FieldValues } from "react-hook-form";
import {
  Grid,
  Typography,
  Button,
  FormHelperText,
  FormControl,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import AgentSelect from "components/inputs/agents/AgentSelect";
import CommuneSelect from "components/inputs/CommuneSelect";
import OuvrageSelect from "components/inputs/OuvrageSelect";
import PhotosInput from "components/inputs/photos/PhotosInput";
import SkeletonForm from "components/loading/SkeletonForm";
import { grey } from "@mui/material/colors";
import declarationOfflineService from "services/declarationOfflineService";
import formModelService from "services/formModelService";
import { useSnackbar } from "notistack";
import ToastMessages from "constants/ToastMessages";
import SaveIcon from "@mui/icons-material/Save";
import ControlledTimePicker from "components/inputs/ControlledTimePicker";
import ControlledDateTime from "components/inputs/ControlledDateTime";
import ControlledTextField from "components/inputs/ControlledTextField";
import ControlledYesNoText from "components/inputs/YesNoText/ControlledYesNoText";
import LoadingButton from "components/buttons/LoadingButton";
import { Guid } from "guid-typescript";
import { useData } from "providers/DataProvider";
import TypeSecurite from "constants/TypeSecurite";
import LesionSection from "components/inputs/lesions/LesionSection";
import type { OfflineSecurite, Securite } from "models/Securite";
import { useNavigate } from "react-router-dom";
import ControlledOuvrageTypeSelect from "components/inputs/ControlledOuvrageTypeSelect";
import type { SecuriteFormModel } from "models/SecuriteFormModel";
import securiteService from "services/securiteService";
import risquesSecurites from "data/risquesSecurites";
import RequalificationModal from "../RequalificationModal";
import ConfirmationCardSST from "components/ConfirmationCard/ConfirmationCardSST";
import ConfirmationCardVictime from "components/ConfirmationCard/ConfirmationCardVictime";
import FormSectionTitle from "./FormSectionTitle";
import type User from "models/User";
import declarationService from "services/declarationService";
import { dateUtil } from "@sdeapps/react-core";
import type { IndexableType } from "dexie";
import Authorization from "../Authorization";
import ApplicationRoles from "constants/ApplicationRoles";
import SecuriteCreateModal from "../SecuriteCreateModal";
import ControlledBooleanRadioGroup from "components/inputs/ControlledBooleanRadioGroup";
import { useUser } from "providers/Authentication/SecuUserProvider";
import type Lesion from "models/Lesion";

type SecuriteFormProps = {
  isNew?: boolean;
  isRequalification?: boolean;
  securite?: Securite;
  isLoading: boolean;
  updateReferenceDeclaration: (declaration: Securite) => void;
  typeSecurite: TypeSecurite;
};

risquesSecurites.sort((a, b) => {
  return a.label.localeCompare(b.label, "fr");
});

function SecuriteForm({
  isNew = true,
  isRequalification = false,
  securite,
  isLoading,
  updateReferenceDeclaration,
  typeSecurite,
}: SecuriteFormProps): ReactElement {
  const [isFormReadonly, setIsFormReadonly] = useState(false);
  const [disableSending, setDisableSending] = useState(false);
  const [openRequalificationModal, setOpenRequalificationModal] = useState(false);
  const [isConfirmationSstLoading, setIsConfirmationSstLoading] = useState(false);
  const [isConfirmationVictimeLoading, setIsConfirmationVictimeLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const navigate = useNavigate();
  const data = useData();
  const { isRoles, user } = useUser();
  const { enqueueSnackbar } = useSnackbar();

  const isAccidentBenin = typeSecurite === TypeSecurite.ACCIDENT_BENIN;

  const {
    register,
    unregister,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
    watch,
    reset,
  } = useForm({ shouldFocusError: false });
  /** Le SubmitHandler valide les données par rapport à leurs propriétés
   * (required...) et l'interface AccidentPageViewModel */
  async function onSubmit(formModel: SecuriteFormModel): Promise<void> {
    setDisableSending(true);

    const newDeclaration = formModelService.formModelToSecurite(formModel);

    if (isRequalification) {
      await requalifyDeclaration(newDeclaration);
    } else if (isNew || !isRoles(ApplicationRoles.MODERATOR)) {
      await createDeclaration(newDeclaration);
    } else {
      await updateDeclaration(newDeclaration);
    }

    setDisableSending(false);
  }

  async function createDeclaration(newDeclaration: Securite): Promise<void> {
    let offlineFailed: boolean = false;
    newDeclaration.type = typeSecurite;
    let offlineIndex: IndexableType | undefined;
    let offlineDeclaration: OfflineSecurite | undefined;
    if (newDeclaration.isVictimePrestataire) {
      newDeclaration.victimeCity = "";
      newDeclaration.victimeDepartment = "";
      newDeclaration.victimeEmployeeId = "";
      newDeclaration.victimeId = "";
      newDeclaration.victimeJobTitle = "";
    } else {
      newDeclaration.victimeCompany = "";
    }

    try {
      offlineIndex = await declarationOfflineService.createSecurite(newDeclaration);
      offlineDeclaration = await declarationOfflineService.getSecuriteById(offlineIndex);
    } catch (error: any) {
      console.error(error);
      offlineFailed = true;
      newDeclaration.id = Guid.create().toString();
    }
    try {
      await securiteService.create(offlineDeclaration ?? newDeclaration);
      if (offlineIndex !== undefined) {
        void declarationOfflineService.removeSecuriteById(offlineIndex);
      }
      enqueueSnackbar(ToastMessages.SUCCESS_CREATE_REDIRECT, {
        variant: "success",
      });
      isAccidentBenin ? navigate("/") : setOpenModal(true);
    } catch (error) {
      if (!offlineFailed) {
        enqueueSnackbar(ToastMessages.WARNING_CREATE_OFFLINE, {
          variant: "warning",
        });
        isAccidentBenin ? navigate("/") : setOpenModal(true);
      } else {
        console.error("Offline save failed !", error);
        enqueueSnackbar(ToastMessages.ERROR_CREATE_OFFLINE, {
          variant: "error",
        });
      }
    }
  }

  async function updateDeclaration(newDeclaration: Securite): Promise<void> {
    newDeclaration.id = securite!.id;
    try {
      await securiteService.update(newDeclaration);
      enqueueSnackbar(ToastMessages.SUCCESS_MODIFY, {
        variant: "success",
        autoHideDuration: 5000,
      });
      navigate(`/`);
    } catch (error) {
      console.error(error);
      enqueueSnackbar(ToastMessages.ERROR_UPDATE, {
        variant: "error",
      });
    }
  }

  async function requalifyDeclaration(newDeclaration: Securite): Promise<void> {
    newDeclaration.id = Guid.create().toString();
    try {
      await securiteService.requalify(newDeclaration);
      enqueueSnackbar(ToastMessages.SUCCESS_MODIFY, {
        variant: "success",
        autoHideDuration: 5000,
      });
      navigate(`/`);
    } catch (error) {
      console.error(error);
      enqueueSnackbar(ToastMessages.ERROR_UPDATE, {
        variant: "error",
      });
    }
  }

  useEffect(() => {
    register("ouvragePosteTechnique", { value: "" });
    register("ouvrageDescription", { value: "" });
    register("victimeFirstName", { value: "" });
    register("victimeLastName", { value: "" });
    register("victimeEmployeeId", { value: "" });
    register("victimeCity", { value: "" });
    register("victimeDepartment", { value: "" });
    register("victimeJobTitle", { value: "" });
    setValue("ouvragePosteTechnique", "");
    setValue("ouvrageDescription", "");
    setValue("victimeFirstName", "");
    setValue("victimeLastName", "");
    setValue("victimeEmployeeId", "");
    setValue("victimeCity", "");
    setValue("victimeDepartment", "");
    setValue("victimeJobTitle", "");
  }, []);

  useEffect(() => {
    if (!isLoading && securite != null) {
      if (!isNew || isRequalification) {
        const securitePageFormModel: SecuriteFormModel =
          formModelService.securiteToFormModel(securite);

        reset(securitePageFormModel);

        setIsFormReadonly(!isRoles(ApplicationRoles.MODERATOR));
      }
    }
  }, [isLoading, securite]);

  function onFormValidationError(_errors: object, _event?: BaseSyntheticEvent): void {
    enqueueSnackbar(ToastMessages.ERROR_FORM_VALIDATION, {
      variant: "error",
    });
  }

  function displayConfirmationSauveteurIfNeeded(): ReactElement | null {
    if (
      securite?.id != null &&
      securite?.type === TypeSecurite.ACCIDENT_BENIN &&
      securite.accidentBeninSauveteurId === user?.id &&
      !securite.accidentBeninConfirmationSauveteur &&
      !securite.isVictimePrestataire
    ) {
      return (
        <Grid item xs={12}>
          <ConfirmationCardSST
            loading={isConfirmationSstLoading}
            onConfirm={async (b: boolean, comment?: string) => {
              setIsConfirmationSstLoading(true);
              try {
                updateReferenceDeclaration(
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  await securiteService.confirmSST(b, securite.id!, comment)
                );
                enqueueSnackbar(ToastMessages.SUCCESS_CONFIRM, {
                  variant: "success",
                  autoHideDuration: 5000,
                });
              } catch {
                enqueueSnackbar(ToastMessages.ERROR_RETRY, {
                  variant: "error",
                });
              } finally {
                setIsConfirmationSstLoading(false);
              }
            }}
            onRefute={() =>
              enqueueSnackbar(ToastMessages.REFUTE_CONFIRMATION_TEMP, {
                variant: "warning",
              })
            }
          />
        </Grid>
      );
    }
    return null;
  }

  function displayConfirmationVictimeIfNeeded(): ReactElement | null {
    if (
      !isNew &&
      securite?.type === TypeSecurite.ACCIDENT_BENIN &&
      securite.victimeId === user?.id &&
      !securite.accidentBeninConfirmationVictime &&
      !securite.isVictimePrestataire
    ) {
      return (
        <Grid item xs={12}>
          <ConfirmationCardVictime
            loading={isConfirmationVictimeLoading}
            onConfirm={async (b: boolean) => {
              setIsConfirmationVictimeLoading(true);
              try {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                updateReferenceDeclaration(await securiteService.confirmVictim(b, securite.id!));
                enqueueSnackbar(ToastMessages.SUCCESS_CONFIRM, {
                  variant: "success",
                  autoHideDuration: 5000,
                });
              } catch {
                enqueueSnackbar(ToastMessages.ERROR_RETRY, {
                  variant: "error",
                });
              } finally {
                setIsConfirmationVictimeLoading(false);
              }
            }}
            onRefute={() =>
              enqueueSnackbar(ToastMessages.REFUTE_CONFIRMATION_TEMP, {
                variant: "info",
                autoHideDuration: 20000,
              })
            }
          />
        </Grid>
      );
    }
    return null;
  }

  function getSaveButtonLabel(): string {
    if (isRequalification) {
      return "Requalifier";
    } else if (!isNew && isRoles(ApplicationRoles.MODERATOR)) {
      return "Modifier";
    } else {
      return "Enregistrer";
    }
  }

  if (isLoading || data.isLoading) {
    return <SkeletonForm />;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit as SubmitHandler<FieldValues>, onFormValidationError)}>
      <Grid container spacing={3}>
        {displayConfirmationSauveteurIfNeeded()}
        {displayConfirmationVictimeIfNeeded()}
        <Grid item xs={12}>
          <Typography variant="caption" sx={{ color: grey[500] }}>
            Les champs suivis d'un astérisque ( * ) sont obligatoires
          </Typography>
        </Grid>
        {!isNew && securite?.requalification == null && (
          <Authorization roles={ApplicationRoles.MODERATOR}>
            <Grid item container rowSpacing={2} columnSpacing={3} alignItems="center">
              <Grid item xs={12} sm="auto">
                <Button
                  variant="contained"
                  onClick={() => {
                    setOpenRequalificationModal(true);
                  }}>
                  Requalifier
                </Button>
                <RequalificationModal
                  handleClose={() => {
                    setOpenRequalificationModal(false);
                  }}
                  open={openRequalificationModal}
                  declaration={securite!}
                />
              </Grid>
            </Grid>
          </Authorization>
        )}
        <Grid item xs={12} sm={6}>
          <ControlledDateTime
            name="dateEtHeure"
            control={control}
            defaultValue={securite?.dateEtHeure ?? new Date()}
            rules={{
              required: "Veuillez renseigner une date valide",
              validate: (value: Date) =>
                !dateUtil.isFuture(value) || "Veuillez renseigner une date valide",
            }}
            label="Date de l'événement *"
            readOnly={isFormReadonly}
            maxDate={new Date()}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledTimePicker
            name="heureAccident"
            control={control}
            defaultValue={securite?.dateEtHeure ?? null}
            rules={{
              required: "Veuillez renseigner l'heure de l'événement.",
              validate: (value: Date) => {
                if (!dateUtil.isValid(value)) {
                  return "Veuillez renseigner une heure valide.";
                }
                let comparisonDate: Date | string;
                if (dateUtil.isValid(getValues("dateEtHeure"))) {
                  const date = dateUtil.getDate(getValues("dateEtHeure"));
                  const time = dateUtil.getDate(value);
                  comparisonDate = dateUtil.composeFromSeparateDateAndTime(date, time);
                } else {
                  comparisonDate = value;
                }
                return (
                  !dateUtil.isFuture(comparisonDate) ||
                  "L'heure de l'accident ne peut pas être dans le futur."
                );
              },
            }}
            label="Heure de l'événement *"
            readOnly={isFormReadonly}
          />
        </Grid>
        <Grid item xs={12}>
          <FormSectionTitle title="Lieu de l'évènement" infoKey="" />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            name="rue"
            control={control}
            defaultValue={securite?.rue ?? ""}
            rules={{ required: "Ce champ est obligatoire" }}
            label="Rue ou description du lieu *"
            placeholder="Rue ou description du lieu"
            readOnly={isFormReadonly}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="communeInsee"
            control={control}
            rules={{ required: "Ce champ est obligatoire" }}
            render={({ field: { onChange, ref }, fieldState: { error } }) => (
              <CommuneSelect
                onChange={onChange}
                setCommuneName={(value: string) => {
                  setValue("communeName", value);
                }}
                inputRef={ref}
                communes={data.communes}
                defaultValue={securite?.communeInsee}
                defaultName={securite?.communeName}
                error={error != null}
                readOnly={isFormReadonly}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledOuvrageTypeSelect
            name="ouvrageType"
            control={control}
            defaultValue={securite?.ouvrageType}
            label="Type d'ouvrage"
            readOnly={isFormReadonly}
          />
        </Grid>
        <Grid item xs={12}>
          <OuvrageSelect
            communeINSEE={watch("communeInsee") ?? ""}
            typeOuvrage={watch("ouvrageType") ?? ""}
            allOuvrages={data.ouvrages}
            notOuvrageTypeString="NON"
            defaultOuvrage={
              securite?.ouvragePosteTechnique != null &&
              securite?.ouvragePosteTechnique !== "" &&
              securite?.ouvrageDescription !== ""
                ? {
                    posteTechnique: securite.ouvragePosteTechnique,
                    designation: securite.ouvrageDescription,
                    typeObjet: "",
                    rue: "",
                  }
                : undefined
            }
            setPosteTechnique={(s: string) => {
              setValue("ouvragePosteTechnique", s);
            }}
            setOuvrageDescription={(s: string) => {
              setValue("ouvrageDescription", s);
            }}
            readOnly={isFormReadonly}
            error={errors.ouvragePosteTechnique != null || errors.ouvrageDescription != null}
            helperText={errors.ouvragePosteTechnique?.message as string}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            name="territoire"
            control={control}
            defaultValue={securite?.territoire}
            label="Territoire de l'événement si connu"
            readOnly={isFormReadonly}
            select
            options={data.territoires}
          />
        </Grid>
        <Grid item xs={12}>
          <FormSectionTitle title="Description" infoKey="" />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            name="risque"
            control={control}
            rules={{ required: "Ce champ est obligatoire" }}
            defaultValue={securite?.risque}
            label="Nature du risque *"
            readOnly={isFormReadonly}
            options={risquesSecurites}
            select
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            name="precisions"
            control={control}
            defaultValue={securite?.precisions ?? ""}
            rules={{ required: "Ce champ est obligatoire" }}
            label="Que s'est-il passé ? *"
            placeholder="Que s'est-il passé ?"
            readOnly={isFormReadonly}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            name="activiteEnCours"
            control={control}
            defaultValue={securite?.precisions ?? ""}
            rules={{ required: "Ce champ est obligatoire" }}
            label="Qu'étiez-vous en train de faire ? *"
            placeholder="Qu'étiez-vous en train de faire ?"
            readOnly={isFormReadonly}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            name="consequences"
            control={control}
            defaultValue={securite?.consequences ?? ""}
            label="Conséquences possibles ou avérées"
            readOnly={isFormReadonly}
          />
        </Grid>
        {!isAccidentBenin && (
          <Grid item xs={12}>
            <ControlledTextField
              name="actions"
              control={control}
              rules={{ required: "Ce champ est obligatoire" }}
              defaultValue={securite?.actions ?? ""}
              label="Actions immédiates *"
              placeholder="En tant qu'acteur de ma santé sécurité et celles des autres, j'ai ..."
              readOnly={isFormReadonly}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <ControlledTextField
            name="proposition"
            control={control}
            defaultValue={securite?.proposition ?? ""}
            label="Proposition de solution"
            readOnly={isFormReadonly}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledYesNoText
            name="isTiers"
            control={control}
            register={register}
            unregister={unregister}
            textFieldName="tiers"
            setValue={setValue}
            label="L'événement implique-t-il un tiers? *"
            isNew={isNew && !isRequalification}
            defaultValue={securite?.tiers}
            readOnly={isFormReadonly}
            textLabel="Nom Prénom du tiers"
            textPlaceholder="Nom Prénom du tiers"
            textRequired="Veuillez indiquer les nom et prénom du tiers"
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledYesNoText
            name="isTemoin"
            control={control}
            register={register}
            unregister={unregister}
            textFieldName="temoin"
            setValue={setValue}
            label="Quelqu'un d'autre a-t-il été témoin de l'événement ? *"
            isNew={isNew && !isRequalification}
            defaultValue={securite?.temoin}
            readOnly={isFormReadonly}
            textLabel="Nom Prénom du témoin"
            textPlaceholder="Nom Prénom du témoin"
            textRequired="Veuillez indiquer les nom et prénom du témoin"
          />
        </Grid>
        <Grid item xs={12}>
          <FormSectionTitle title="Agent concerné" infoKey="" />
        </Grid>

        <Grid item xs={12}>
          <ControlledBooleanRadioGroup
            name="isVictimePrestataire"
            control={control}
            label="La victime est-elle : *"
            defaultValue={securite?.isVictimePrestataire ?? false}
            trueLabel="Prestataire"
            falseLabel="Agent SDEA"
            readOnly={isFormReadonly || !isNew || isRequalification}
          />
        </Grid>
        {watch("isVictimePrestataire") === true ? (
          <>
            <Grid item xs={12} sm={6}>
              <ControlledTextField
                name="victimeFirstName"
                control={control}
                rules={{ required: "Ce champ est obligatoire" }}
                defaultValue={securite?.victimeFirstName}
                label="Prénom du prestataire *"
                placeholder="Champ libre obligatoire"
                readOnly={isFormReadonly}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ControlledTextField
                name="victimeLastName"
                control={control}
                rules={{ required: "Ce champ est obligatoire" }}
                defaultValue={securite?.victimeLastName}
                label="Nom du prestataire *"
                placeholder="Champ libre obligatoire"
                readOnly={isFormReadonly}
              />
            </Grid>
            <Grid item xs={12}>
              <ControlledTextField
                name="victimeCompany"
                control={control}
                rules={{ required: "Ce champ est obligatoire" }}
                defaultValue={securite?.victimeCompany}
                label="Entreprise du prestataire *"
                placeholder="Champ libre obligatoire"
                readOnly={isFormReadonly}
              />
            </Grid>
          </>
        ) : (
          <Grid item xs={12}>
            <Controller
              name="victimeId"
              control={control}
              rules={isAccidentBenin ? { required: "Ce champ est obligatoire" } : {}}
              render={({ field: { onChange, ref }, fieldState: { error } }) => (
                <AgentSelect
                  agents={data.agents}
                  label={`Agent concerné${isAccidentBenin ? " *" : ""}`}
                  onValueChange={(user: User | null) => {
                    if (user != null) {
                      onChange(user?.id);
                      setValue("victimeFirstName", user.givenName);
                      setValue("victimeLastName", user.surname);
                      setValue("victimeEmployeeId", user.employeeId);
                      setValue("victimeCity", user.city);
                      setValue("victimeDepartment", user.department);
                      setValue("victimeJobTitle", user.jobTitle);
                    } else {
                      onChange("");
                      setValue("victimeFirstName", "");
                      setValue("victimeLastName", "");
                      setValue("victimeEmployeeId", "");
                      setValue("victimeCity", "");
                      setValue("victimeDepartment", "");
                      setValue("victimeJobTitle", "");
                    }
                  }}
                  inputRef={ref}
                  agentInfos
                  fullWidth
                  defaultValue={securite?.victimeId}
                  defaultAgent={declarationService.getSnapshotVictim(securite)}
                  readOnly={isFormReadonly}
                  error={error != null}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
        )}
        {isAccidentBenin && (
          <>
            <Grid item xs={12}>
              <FormSectionTitle title="Sauveteur Secouriste du Travail" infoKey="" />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="accidentBeninSauveteurId"
                control={control}
                rules={
                  watch("isVictimePrestataire") === false
                    ? { required: "Ce champ est obligatoire" }
                    : { required: false }
                }
                render={({ field: { onChange, ref }, fieldState: { error } }) => (
                  <AgentSelect
                    onChange={onChange}
                    agents={data.agents}
                    label={`SST ayant dispensé les soins${
                      watch("isVictimePrestataire") === false ? " *" : ""
                    }`}
                    inputRef={ref}
                    agentInfos
                    fullWidth
                    defaultValue={securite?.accidentBeninSauveteurId ?? undefined}
                    readOnly={isFormReadonly}
                    error={error != null}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid>
            {!isNew && (
              <Grid item xs={12}>
                <ControlledTextField
                  name="accidentBeninCommentaireSauveteur"
                  control={control}
                  defaultValue={securite?.accidentBeninCommentaireSauveteur ?? ""}
                  label="Commentaires du SST"
                  placeholder="Commentaires du SST"
                  readOnly={isFormReadonly}
                />
              </Grid>
            )}
          </>
        )}

        <Grid item xs={12}>
          <FormSectionTitle title="Lésion(s)" infoKey="" />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="lesions"
            defaultValue={securite?.lesions ?? []}
            rules={
              isAccidentBenin
                ? {
                    required: "Veuillez renseigner les lésions",
                    validate: (value: Array<Lesion>) =>
                      value.length > 0 || "Veuillez renseigner les lésions",
                  }
                : undefined
            }
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <LesionSection value={value} onChange={onChange} readOnly={isFormReadonly} />
                {error != null && <FormHelperText error>{error.message}</FormHelperText>}
              </>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            name="commentaire"
            control={control}
            defaultValue={securite?.commentaire ?? ""}
            label="Commentaire lié à l'événement"
            placeholder="Mon commentaire..."
            readOnly={isFormReadonly}
          />
        </Grid>
        <Grid item xs={12}>
          <FormSectionTitle title="Photos" infoKey="" />
        </Grid>
        <Controller
          name="base64Photos"
          control={control}
          render={({ field: { onChange } }) => (
            <PhotosInput
              value={securite?.photos ?? []}
              onChange={onChange}
              maximumPhotos={3}
              readOnly={!isNew || isRequalification}
            />
          )}
        />
        <Grid item xs={12}>
          <Controller
            name="isConfidentiel"
            control={control}
            defaultValue={false}
            render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
              <FormControl fullWidth error={error != null}>
                <FormControlLabel
                  label="Je souhaite que ma déclaration soit confidentielle, à ce titre que la diffusion soit limitée au strict minimum de destinataire (lien avec des RPS, harcèlement, signaux faibles, ...)"
                  control={<Checkbox checked={value} onChange={onChange} />}
                  labelPlacement="end"
                />
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <LoadingButton
            loading={disableSending}
            endIcon={<SaveIcon />}
            disabled={isFormReadonly || disableSending}>
            {getSaveButtonLabel()}
          </LoadingButton>
        </Grid>
        {displayConfirmationSauveteurIfNeeded()}
        {displayConfirmationVictimeIfNeeded()}
      </Grid>
      <br />
      <SecuriteCreateModal
        open={openModal}
        handleClose={() => {
          setOpenModal(false);
          navigate(`/`);
        }}
        isDsd={typeSecurite === TypeSecurite.SITUATION_DANGEREUSE}
      />
    </form>
  );
}

export default SecuriteForm;
