import PopupHandler from "components/utils/PopupHandler/PopupHandler";
import ScrollToTop from "components/utils/ScrollToTop";
import TitlesUtil from "components/utils/TitlesUtil";
import SnackbarProvider from "providers/SnackbarProvider";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header/Header";
import type { ReactElement } from "react";

function UnauthenticatedRoot(): ReactElement {
  return (
    <SnackbarProvider>
      <Header unAuthenticated />
      <Outlet />
      <Footer />
      <ScrollToTop />
      <TitlesUtil />
      <PopupHandler />
    </SnackbarProvider>
  );
}

export default UnauthenticatedRoot;
