import { apiConfig } from "app-config";
import networkService from "./networkService";
import Errors from "constants/Errors";
import errorHandlingService from "./errorHandlingService";
import type { DangerGrave } from "models/DangerGrave";

const apiBaseUrl = `${apiConfig.apimBaseUrl}/${apiConfig.declarationApi.apiName}`;
const scopes = apiConfig.declarationApi.scopes;

const dangersEndpoint = "dangers";
const userDangersEndpoint = "me/dangers";
const photosEndpoint = "photos";

/**
 * Génère les headers pour la requête http
 * @returns Les Headers
 */
async function createHeaders(): Promise<HeadersInit> {
  return networkService.createHeaders(scopes);
}

/**
 * Récupère toutes les DGI
 * Cette route n'est accessible qu'aux modérateurs
 * @returns Liste de DGI
 */
async function getAll(): Promise<Array<DangerGrave>> {
  const url = `${apiBaseUrl}/${dangersEndpoint}`;
  return fetch(url, {
    method: "GET",
    headers: await createHeaders(),
  })
    .then(errorHandlingService.defaultAndDefined)
    .then(networkService.getJsonData);
}

/**
 * Récupère toutes les DGI de l'utilisateur courant
 * @returns Liste de DGI
 */
async function getAllForCurrentUser(): Promise<Array<DangerGrave>> {
  const url = `${apiBaseUrl}/${userDangersEndpoint}`;
  return fetch(url, {
    method: "GET",
    headers: await createHeaders(),
  })
    .then(errorHandlingService.defaultAndDefined)
    .then(networkService.getJsonData);
}

/**
 * Récupère les photos de la DGI en fonction de son id
 * @param id l'id de la DGI
 * @returns Tableau d'urls des photos
 */
async function getPhotosById(id: string): Promise<Array<string>> {
  const url = `${apiBaseUrl}/${dangersEndpoint}/${id}/${photosEndpoint}`;
  return fetch(url, {
    method: "GET",
    headers: await createHeaders(),
    mode: "cors",
  })
    .then(errorHandlingService.defaultAndDefined)
    .then(networkService.getJsonData);
}

/**
 * Crée une nouvelle DGI
 * @returns La nouvelle DGI créée
 */
async function create(danger: DangerGrave): Promise<DangerGrave> {
  const url = `${apiBaseUrl}/${dangersEndpoint}/${danger.id}`;
  return fetch(url, {
    method: "PUT",
    headers: await createHeaders(),
    mode: "cors",
    body: JSON.stringify(danger),
  })
    .then((res) => {
      // 201 - Created = nouvelle DGI créée
      // 409 - Conflict = DGI offline déjà envoyée
      switch (res.status) {
        case 201:
          return res;
        case 409:
          throw new Error(Errors.ALREADY_CREATED);
        default:
          throw new Error(`Error ${res.status} creating danger`);
      }
    })
    .then(errorHandlingService.defaultAndDefined)
    .then(networkService.getJsonData);
}

const dangerService = {
  getAll,
  getAllForCurrentUser,
  create,
  getPhotosById,
};

export default dangerService;
