import accidentService from "services/accidentService";
import declarationOfflineService from "services/declarationOfflineService";
import Errors from "constants/Errors";
import type { OfflineAccident } from "models/Accident";
import type { OfflineSecurite } from "models/Securite";
import securiteService from "./securiteService";
import { dateUtil } from "@sdeapps/react-core";
import type { OfflineDangerGrave } from "models/DangerGrave";
import dangerService from "./dangerService";

/**
 * Tente d'envoyer en BDD toutes les déclarations enregistrées en local
 * @returns Un booléen, true si une des synchronisations a réussi, indiquant un
 * potentiel besoin de rafraîchissement de l'affichage
 */
async function synchronizeOfflineAndOnline(): Promise<boolean> {
  const fetches: Array<Promise<boolean>> = [];
  fetches.push(
    declarationOfflineService
      .getAllAccidents()
      .then(async (offlineAccidents: Array<OfflineAccident>) => {
        if (offlineAccidents.length < 1) return false;
        return batchSyncOfflineAccidents(offlineAccidents)
          .then(isOneTrueInArray)
          .catch(returnFalse);
      })
      .catch(returnFalse)
  );
  fetches.push(
    declarationOfflineService
      .getAllSecurites()
      .then(async (offlineSecurite: Array<OfflineSecurite>) => {
        if (offlineSecurite.length < 1) return false;
        return batchSyncOfflineSecurites(offlineSecurite).then(isOneTrueInArray).catch(returnFalse);
      })
      .catch(returnFalse)
  );

  fetches.push(
    declarationOfflineService
      .getAllDangerGrave()
      .then(async (offlineDgi: Array<OfflineDangerGrave>) => {
        if (offlineDgi.length < 1) return false;
        return batchSyncOfflineDangerGraves(offlineDgi).then(isOneTrueInArray).catch(returnFalse);
      })
      .catch(returnFalse)
  );

  const newArray: Array<boolean> = await Promise.all(fetches);
  let newValue: boolean = false;
  newArray.forEach((v) => {
    newValue = newValue || v;
  });

  return newValue;
}

/**
 *
 * @param offlineAccidents Liste de déclarations Offline à envoyer
 * @returns Un booléen, true si une des synchronisations a réussi, indiquant un
 * potentiel besoin de rafraîchissement de l'affichage
 */
async function batchSyncOfflineAccidents(
  offlineAccidents: Array<OfflineAccident>
): Promise<Array<boolean>> {
  const fetches: Array<Promise<boolean>> = [];
  offlineAccidents.forEach((accident) => {
    if (
      !accident.sending ||
      (accident.sending &&
        dateUtil.differenceInMinutes(undefined, accident.lastSyncAttempt ?? "0") >= 1)
    ) {
      void declarationOfflineService.setAccidentLastSyncAttempt(accident.id);
      fetches.push(
        declarationOfflineService
          .setAccidentSendingById(accident.id, true)
          .then(async () => {
            await declarationOfflineService.setAccidentLastSyncAttempt(accident.id);
            return accidentService
              .create(accident)
              .then(async () => {
                await declarationOfflineService.removeAccidentById(accident.id);
                return true;
              })
              .catch(async (error) => {
                await disableAccidentSending(accident.id);
                if (error?.message === Errors.ALREADY_CREATED) {
                  await declarationOfflineService.removeAccidentById(accident.id);
                  return true;
                }
                console.error("Error creating accident from offline", error);
                return false;
              })
              .finally(() => {
                void disableAccidentSending(accident.id);
              });
          })
          .catch(() => {
            return false;
          })
          .finally(() => {
            void disableAccidentSending(accident.id);
          })
      );
    }
  });
  // Permet de fetch en parallèle
  return Promise.all(fetches);
}

/**
 *
 * @param offlineSecurites Liste de déclarations Offline à envoyer
 * @returns Un booléen, true si une des synchronisations a réussi, indiquant un
 * potentiel besoin de rafraîchissement de l'affichage
 */
async function batchSyncOfflineSecurites(
  offlineSecurites: Array<OfflineSecurite>
): Promise<Array<boolean>> {
  const fetches: Array<Promise<boolean>> = [];
  offlineSecurites.forEach((securite) => {
    if (
      !securite.sending ||
      (securite.sending &&
        dateUtil.differenceInMinutes(undefined, securite.lastSyncAttempt ?? "0") >= 1)
    ) {
      void declarationOfflineService.setSecuriteLastSyncAttempt(securite.id);
      fetches.push(
        declarationOfflineService
          .setSecuriteSendingById(securite.id, true)
          .then(async () => {
            await declarationOfflineService.setSecuriteLastSyncAttempt(securite.id);
            return securiteService
              .create(securite)
              .then(async () => {
                await declarationOfflineService.removeSecuriteById(securite.id);
                return true;
              })
              .catch(async (error) => {
                await disableSecuriteSending(securite.id);
                if (error?.message === Errors.ALREADY_CREATED) {
                  await declarationOfflineService.removeSecuriteById(securite.id);
                  return true;
                }
                console.error("Error creating securite from offline", error);
                return false;
              })
              .finally(() => {
                void disableSecuriteSending(securite.id);
              });
          })
          .catch(() => {
            return false;
          })
          .finally(() => {
            void disableSecuriteSending(securite.id);
          })
      );
    }
  });
  // Permet de fetch en parallèle
  return Promise.all(fetches);
}

/**
 *
 * @param offlineDgis Liste de déclarations Offline à envoyer
 * @returns Un booléen, true si une des synchronisations a réussi, indiquant un
 * potentiel besoin de rafraîchissement de l'affichage
 */
async function batchSyncOfflineDangerGraves(
  offlineDgis: Array<OfflineDangerGrave>
): Promise<Array<boolean>> {
  const fetches: Array<Promise<boolean>> = [];
  offlineDgis.forEach((dangerGrave) => {
    if (
      !dangerGrave.sending ||
      (dangerGrave.sending &&
        dateUtil.differenceInMinutes(undefined, dangerGrave.lastSyncAttempt ?? "0") >= 1)
    ) {
      void declarationOfflineService.setDangerLastSyncAttempt(dangerGrave.id);
      fetches.push(
        declarationOfflineService
          .setDangerSendingById(dangerGrave.id, true)
          .then(async () => {
            await declarationOfflineService.setDangerLastSyncAttempt(dangerGrave.id);
            return dangerService
              .create(dangerGrave)
              .then(async () => {
                await declarationOfflineService.removeDangerById(dangerGrave.id);
                return true;
              })
              .catch(async (error) => {
                await disableDangerSending(dangerGrave.id);
                if (error?.message === Errors.ALREADY_CREATED) {
                  await declarationOfflineService.removeDangerById(dangerGrave.id);
                  return true;
                }
                console.error("Error creating securite from offline", error);
                return false;
              })
              .finally(() => {
                void disableDangerSending(dangerGrave.id);
              });
          })
          .catch(() => {
            return false;
          })
          .finally(() => {
            void disableDangerSending(dangerGrave.id);
          })
      );
    }
  });
  // Permet de fetch en parallèle
  return Promise.all(fetches);
}

function isOneTrueInArray(res: Array<boolean>): boolean {
  let atLeastOneTrue = false;
  res.forEach((v) => {
    atLeastOneTrue = atLeastOneTrue || v;
  });
  return atLeastOneTrue;
}

function returnFalse(): boolean {
  return false;
}

async function disableAccidentSending(id: string): Promise<void> {
  await declarationOfflineService.setAccidentSendingById(id, false);
}

async function disableSecuriteSending(id: string): Promise<void> {
  await declarationOfflineService.setSecuriteSendingById(id, false);
}

async function disableDangerSending(id: string): Promise<void> {
  await declarationOfflineService.setDangerSendingById(id, false);
}

const declarationSyncService = {
  synchronizeOfflineAndOnline,
};
export default declarationSyncService;
