import { Divider, useMediaQuery, useTheme } from "@mui/material";
import type { PropsWithChildren, ReactElement } from "react";

function DividerTitle({ children }: PropsWithChildren): ReactElement {
  const theme = useTheme();
  const isVerySmallScreen = useMediaQuery(theme.breakpoints.only("xs"));

  if (!isVerySmallScreen) {
    return (
      <Divider role="presentation" textAlign="left">
        {children}
      </Divider>
    );
  }
  return <>{children}</>;
}

export default DividerTitle;
