import { routesConfig } from "app-config";
import UnauthenticatedRoot from "components/sections/UnauthenticatedRoot";
import ConnectionPage from "pages/ConnectionPage";
import FlushPage from "pages/FlushPage";
import LegalMentionsPage from "pages/LegalMentionsPage";
import { createBrowserRouter } from "react-router-dom";

const unAuthenticatedRouter = createBrowserRouter([
  {
    element: <UnauthenticatedRoot />,
    children: [
      {
        path: routesConfig.flush.path,
        element: <FlushPage />,
      },
      {
        path: routesConfig.legal.path,
        element: <LegalMentionsPage />,
      },
      {
        path: routesConfig.any.path,
        element: <ConnectionPage />,
      },
    ],
  },
]);

export default unAuthenticatedRouter;
