import Errors from "constants/Errors";
import ErrorPage from "./ErrorPage";
import ForbiddenErrorPage from "./ForbiddenErrorPage";
import NotFoundErrorPage from "./NotFoundErrorPage";
import ServerErrorPage from "./ServerErrorPage";
import type { ReactElement } from "react";

export function defaultErrorHandlingPages(error: Error): ReactElement {
  switch (error?.message) {
    case Errors.FORBIDDEN:
      return <ForbiddenErrorPage />;
    case Errors.NOT_FOUND:
      return <NotFoundErrorPage />;
    case Errors.SERVER_ERROR:
      return <ServerErrorPage />;
    default:
      return <ErrorPage message={error?.message ?? ""} />;
  }
}
