import { Box, Link, Typography } from "@mui/material";
import Spoiler from "components/utils/Spoiler";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import type { ReactElement } from "react";

function TutoBeginningAccidentPage(): ReactElement {
  return (
    <Spoiler
      toggleNode={
        <Link
          sx={{
            display: "flex",
            alignItems: "center",
            "&:hover": { cursor: "pointer" },
          }}>
          <ExpandMoreIcon color="inherit" fontSize="small" />
          Comment bien remplir ma déclaration ?
        </Link>
      }
      defaultValue={true}>
      <Typography sx={{ marginTop: 2 }}>La déclaration se compose de deux volets :</Typography>
      <ul>
        <li>
          Le premier, <strong>Informations générales</strong>, permet d'enregistrer votre
          déclaration et de la partager à l'ensemble de l'équipe RH et de vos différents
          responsables.
        </li>
        <Box component="li" sx={{ marginTop: 2 }}>
          Le second volet, <strong>Informations complémentaires</strong>, permet une prise en charge
          complète et rapide de l'organisme de santé. Si vous ne disposez pas de tous les éléments,
          vous pouvez la compléter plus tard, mais elle devra être finalisée sous{" "}
          <strong>24h</strong>.
        </Box>
      </ul>
    </Spoiler>
  );
}

export default TutoBeginningAccidentPage;
