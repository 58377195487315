import { useState } from "react";
import type { ReactElement } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Checkbox,
  FormControlLabel,
  DialogTitle,
  useTheme,
} from "@mui/material";
import { TransitionSlideUp } from "components/utils/animations";
import { localStorageKeys } from "app-config";
import IntroText from "./IntroText";

type IntroTextModalProps = {
  open: boolean;
  setOpen: (v: boolean) => void;
  onClose?: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
function IntroTextModal({ open, setOpen, onClose = () => {} }: IntroTextModalProps): ReactElement {
  const [isOptingOut, setIsOptingOut] = useState(false);
  const theme = useTheme();

  function onClick(): void {
    if (isOptingOut) {
      localStorage.setItem(localStorageKeys.introText, "true");
    }
    onClose();
    setOpen(false);
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={TransitionSlideUp}
      fullWidth
      keepMounted={false}
      onClose={() => {
        onClose();
        setOpen(false);
      }}>
      <DialogTitle sx={{ span: { color: theme.palette.primary.main } }}>
        Bienvenue dans <span>Eau Secours</span>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={3} maxWidth="md">
          <Grid item xs={12}>
            <IntroText />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <FormControlLabel
          control={
            <Checkbox
              checked={isOptingOut}
              onChange={() => {
                setIsOptingOut(!isOptingOut);
              }}
            />
          }
          label="Ne plus afficher ce message"
          labelPlacement="end"
        />
        <Button variant="contained" onClick={onClick}>
          Fermer
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default IntroTextModal;
