import { Grid, Skeleton } from "@mui/material";
import {
  type GridColDef,
  type GridRenderCellParams,
  type GridValueGetterParams,
} from "@mui/x-data-grid";
import type User from "models/User";
import declarationService from "services/declarationService";
import StatusChip from "./StatusChip";
import type { ReactElement } from "react";
import { dateUtil } from "@sdeapps/react-core";

function renderStatusCell(params: GridRenderCellParams): ReactElement {
  return (
    <Grid container justifyContent="center">
      <StatusChip statutCompletion={params.row.statutCompletion} sending={params.row.sending} />
    </Grid>
  );
}

function renderDateCell(params: GridRenderCellParams): string {
  return dateUtil.format(params?.value?.toString(), "dd/MM/yyyy");
}

function shortVictimValueGetter(params: GridValueGetterParams): string {
  return params?.row?.shortVictimeLibelle;
}

function longVictimValueGetter(params: GridValueGetterParams): string {
  return params?.row?.longVictimeLibelle;
}

function renderVictimCell(params: GridRenderCellParams, agents: Array<User>): ReactElement {
  if (agents.length > 0) {
    return params?.value;
  }
  /** Pour faire joli pendant que la liste des agents charge, on affiche une
   * barre de chargement sexy de longueur aléatoire.
   * Pour avoir toujours la même longueur pour la même déclaration, on utilise
   * le dernier charactère de l'id de la déclaration comme seed.
   * Ca sert à rien mais c'est joli. */
  const seed = Number(`0x${params?.row?.id?.toString()?.substr(-1)}`) / 16;
  const rand = Math.floor(seed * (100 - 25 + 1) + 25);
  return <Skeleton width={`${rand}%`} />;
}

function typeValueGetter(params: GridValueGetterParams): string {
  const typeDeclaration = declarationService.getTypeDeclarationLabel(params.value);
  return typeDeclaration;
}

// Colonnes sur écran large
function getLargeColumns(agents: Array<User>): Array<GridColDef> {
  return [
    {
      headerName: "Date",
      field: "dateEtHeure",
      type: "date",
      width: 120,
      renderCell: renderDateCell,
    },
    {
      headerName: "Victime",
      field: "victimeId",
      flex: 2,
      valueGetter: (params: GridValueGetterParams) => longVictimValueGetter(params),
      renderCell: (params: GridRenderCellParams) => renderVictimCell(params, agents),
    },
    {
      headerName: "Type",
      field: "type",
      width: 155,
      valueGetter: typeValueGetter,
    },
    {
      headerName: "État",
      field: "statutCompletion",
      width: 145,
      renderCell: renderStatusCell,
    },
    {
      headerName: "Commune",
      field: "libelleCommune",
      flex: 1,
    },
    {
      headerName: "Que s'est-il passé",
      field: "precisions",
      flex: 2,
    },
  ];
}

// Colonnes sur écran moyen
function getMediumColumns(agents: Array<User>): Array<GridColDef> {
  return [
    {
      headerName: "Date",
      field: "dateEtHeure",
      type: "date",
      minWidth: 100,
      width: 100,
      renderCell: renderDateCell,
    },
    {
      headerName: "Victime",
      field: "victimeId",
      minWidth: 150,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => longVictimValueGetter(params),
      renderCell: (params: GridRenderCellParams) => renderVictimCell(params, agents),
    },
    {
      headerName: "Type",
      field: "type",
      width: 155,
      valueGetter: typeValueGetter,
    },
    {
      headerName: "État",
      field: "statutCompletion",
      minWidth: 150,
      renderCell: renderStatusCell,
    },
  ];
}

// Colonnes sur petit écran
function getSmallColumns(agents: Array<User>): Array<GridColDef> {
  return [
    {
      headerName: "Date",
      field: "dateEtHeure",
      type: "date",
      minWidth: 90,
      width: 90,
      renderCell: renderDateCell,
    },
    {
      headerName: "Victime",
      field: "victimeId",
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => shortVictimValueGetter(params),
      renderCell: (params: GridRenderCellParams) => renderVictimCell(params, agents),
    },
    {
      headerName: "État",
      field: "statutCompletion",
      minWidth: 125,
      renderCell: renderStatusCell,
    },
  ];
}

export default function getDeclarationsGridColumns(agents: Array<User>): {
  largeGridColumns: Array<GridColDef>;
  mediumGridColumns: Array<GridColDef>;
  smallGridColumns: Array<GridColDef>;
} {
  return {
    largeGridColumns: getLargeColumns(agents),
    mediumGridColumns: getMediumColumns(agents),
    smallGridColumns: getSmallColumns(agents),
  };
}
