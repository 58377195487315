import type { Theme } from "@mui/material";
import type { SxProps } from "@mui/system";

function getHelpIconSvg(color: string): string {
  return `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="18" width="18"><path fill="${encodeURIComponent(
    color
  )}" d="M11.07 12.85c.77-1.39 2.25-2.21 3.11-3.44.91-1.29.4-3.7-2.18-3.7-1.69 0-2.52 1.28-2.87 2.34L6.54 6.96C7.25 4.83 9.18 3 11.99 3c2.35 0 3.96 1.07 4.78 2.41.7 1.15 1.11 3.3.03 4.9-1.2 1.77-2.35 2.31-2.97 3.45-.25.46-.35.76-.35 2.24h-2.89c-.01-.78-.13-2.05.48-3.15zM14 20c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2z"></path></svg>')`;
}

export function getHelpIconInfoSwitchStyles(theme: Theme): SxProps<Theme> {
  const styles: SxProps<Theme> = {
    "& .MuiSwitch-switchBase": {
      "&.Mui-checked": {
        "& .MuiSwitch-thumb:before": {
          backgroundImage: getHelpIconSvg(theme.palette.common.white),
        },
      },
    },
    "& .MuiSwitch-thumb": {
      "&:before": {
        content: "''",
        position: "absolute",
        width: "100%",
        height: "100%",
        left: 0,
        top: 0,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: getHelpIconSvg(theme.palette.info.main),
      },
    },
  };
  return styles;
}
