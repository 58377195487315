import type Ouvrage from "models/Ouvrage";
import { apiConfig, cacheConfig } from "app-config";
import networkService from "./networkService";

const apiBaseUrl = `${apiConfig.apimBaseUrl}/${apiConfig.ouvragesApi.apiName}`;
const scopes = apiConfig.ouvragesApi.scopes;

const ouvragesEndpoint = "ouvrages";
const cacheName = cacheConfig.ouvrages.name;

async function createHeaders(): Promise<HeadersInit> {
  return networkService.createHeaders(scopes);
}

/**
 * Récupère tous les ouvrages
 * @returns Liste des ouvrages
 */
async function getAll(): Promise<Array<Ouvrage>> {
  const url = `${apiBaseUrl}/${ouvragesEndpoint}`;
  const options: RequestInit = {
    method: "GET",
    headers: await createHeaders(),
  };
  return networkService
    .fetchWithCacheFallback(url, options, cacheName)
    .then(networkService.getJsonData);
}

const ouvrageService = {
  getAll,
};
export default ouvrageService;
