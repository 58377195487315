import { useState } from "react";
import type { ReactElement } from "react";
import { DialogActions, DialogContent, Grid, Typography } from "@mui/material";
import { Controller, type FieldValues, type SubmitHandler, useForm } from "react-hook-form";
import ControlledDateTime from "components/inputs/ControlledDateTime";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { grey } from "@mui/material/colors";
import DeclarationTypeSelectionCheckboxes from "../inputs/DeclarationTypeSelectionCheckboxes";
import LoadingButton from "components/buttons/LoadingButton";
import { useData } from "providers/DataProvider";
import excelService from "services/excelService";
import accidentService from "services/accidentService";
import securiteService from "services/securiteService";
import type TypeAccident from "constants/TypeAccident";
import type TypeSecurite from "constants/TypeSecurite";
import type { Accident } from "models/Accident";
import type { Securite } from "models/Securite";
import ToastMessages from "constants/ToastMessages";
import { useSnackbar } from "notistack";
import DividerTitle from "components/utils/DividerTitle";
import { dateUtil } from "@sdeapps/react-core";

const defaultDate = new Date();
defaultDate.setHours(0, 0, 0, 0);

type ExportForms = {
  dateDebut: Date;
  dateFin: Date;
  selectedTypes: Array<TypeAccident | TypeSecurite>;
};

async function getAndSelectAccidents(data: ExportForms): Promise<Array<Accident>> {
  const { dateDebut, dateFin } = data;
  dateDebut.setHours(0, 0, 0, 0);
  dateFin.setHours(23, 59, 59, 999);
  const allAccidentsInDates: Array<Accident> = await accidentService.getAllBetweenDates(
    dateDebut,
    dateFin
  );

  return allAccidentsInDates.filter((accident) => {
    return data.selectedTypes.includes(accident.type);
  });
}

async function getAndSelectSecurites(data: ExportForms): Promise<Array<Securite>> {
  const { dateDebut, dateFin } = data;
  dateDebut.setHours(0, 0, 0, 0);
  dateFin.setHours(23, 59, 59, 999);
  const allSecuritesInDates: Array<Securite> = await securiteService.getAllBetweenDates(
    dateDebut,
    dateFin
  );

  return allSecuritesInDates.filter((securites) => {
    return data.selectedTypes.includes(securites.type);
  });
}

type ControlledExportDeclarationsDialogPartsProps = {
  handleClose: () => void;
};

function ControlledExportDeclarationsDialogParts({
  handleClose,
}: ControlledExportDeclarationsDialogPartsProps): ReactElement {
  const { communes, agents } = useData();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const { handleSubmit, control, getValues } = useForm({
    shouldFocusError: false,
  });

  async function exportExcel(exportData: ExportForms): Promise<void> {
    setLoading(true);

    try {
      const accidents = await getAndSelectAccidents(exportData);
      const securites = await getAndSelectSecurites(exportData);

      excelService.exportDeclarations(
        [...securites, ...accidents],
        communes,
        agents,
        `declarations_${dateUtil.format(exportData.dateDebut, "dd-MM-yyyy")}_${dateUtil.format(
          exportData.dateFin,
          "dd-MM-yyyy"
        )}`
      );
    } catch (error) {
      console.error("Export Excel Declarations failed !", error);
      enqueueSnackbar(ToastMessages.ERROR_RETRY, {
        variant: "error",
      });
    }

    handleClose();
    setLoading(false);
  }

  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <form onSubmit={handleSubmit(exportExcel as SubmitHandler<FieldValues>)}>
      <DialogContent>
        <Grid container spacing={3} maxWidth="md">
          <Grid item xs={12}>
            <DividerTitle>
              <Typography variant="subtitle1">Filtres</Typography>
            </DividerTitle>
          </Grid>
          <Grid item xs={12} sm={6}>
            <ControlledDateTime
              name="dateDebut"
              control={control}
              label="Date de début *"
              defaultValue={defaultDate}
              rules={{
                required: "Veuillez renseigner une date valide",
                validate: (value: Date) =>
                  dateUtil.isValid(value) || "Veuillez renseigner une date valide",
              }}
              maxDate={defaultDate}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ControlledDateTime
              name="dateFin"
              control={control}
              label="Date de fin *"
              defaultValue={defaultDate}
              rules={{
                required: "Veuillez renseigner une date valide",
                validate: (value: Date) => {
                  if (
                    !dateUtil.isValid(value) ||
                    dateUtil.isAfter(getValues("dateDebut") as Date, value)
                  )
                    return "Veuillez renseigner une date de fin postérieure à la date de début";
                },
              }}
              maxDate={defaultDate}
              fullWidth
            />
          </Grid>
          <Grid item container xs={12}>
            <Controller
              name="selectedTypes"
              control={control}
              defaultValue={[]}
              rules={{
                required: "Veuillez choisir au moins un type de déclaration à exporter",
              }}
              render={({ field: { onChange, ref }, fieldState: { error } }) => (
                <DeclarationTypeSelectionCheckboxes
                  onChange={onChange}
                  label="Types de déclarations à exporter *"
                  error={error != null}
                  helperText={error?.message}
                  inputRef={ref}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ color: grey[500] }}>
              NB : Les sélections prennent en compte la date de création de la déclaration et non la
              date de l'accident lui-même.
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <LoadingButton variant="contained" loading={loading} endIcon={<FileDownloadIcon />}>
          Télécharger
        </LoadingButton>
      </DialogActions>
    </form>
  );
}

export default ControlledExportDeclarationsDialogParts;
