import { useState } from "react";
import type { ReactElement } from "react";
import {
  Link,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  MenuItem,
  Menu,
  Box,
  useTheme,
  useMediaQuery,
  Avatar,
  type PaperProps,
  type MenuProps,
} from "@mui/material";
import WifiOffIcon from "@mui/icons-material/WifiOff";
import { grey } from "@mui/material/colors";
import authService from "services/authService";
import HeaderAvatar from "components/sections/Header/HeaderAvatar";
import Offline from "components/utils/onlineStatus/Offline";
import AppLogo from "images/logo.svg";
import { useOnlineStatus } from "providers/OnlineStatusProvider";
import ExcelExportButton from "components/excelExport/ExcelExportButton";
import FilDAriane from "./FilDAriane";
import { envConfig } from "app-config";
import Authorization from "../Authorization";
import ApplicationRoles from "constants/ApplicationRoles";

const env = envConfig.name ?? "";

function capitalizeFirstLetter(s: string): string {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

function getEnvName(): string {
  if (env?.length > 0 && env?.toLocaleLowerCase() !== "prod") {
    return `- ${capitalizeFirstLetter(env)}`;
  }
  return "";
}

function handleLogout(): void {
  void authService.logout();
}

const paperPropsTriangle: PaperProps = {
  sx: {
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgb(255,255,255))",
    mt: 1.5,
    "& .MuiAvatar-root": {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
  },
};

/** Menu contextuel du profil utilisateur */
function LogoutMenu({ ...props }: MenuProps): ReactElement {
  return (
    <Menu
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      {...props}
      PaperProps={paperPropsTriangle}>
      <MenuItem onClick={handleLogout}>Déconnexion</MenuItem>
    </Menu>
  );
}

type HeaderProps = { unAuthenticated?: boolean };

function Header({ unAuthenticated = false }: HeaderProps): ReactElement {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [isLogoutMenuOpen, setIsLogoutMenuOpen] = useState(false);

  function handleLogoutMenuOpen(event: React.MouseEvent<HTMLElement>): void {
    setAnchorElUser(event.currentTarget);
    setIsLogoutMenuOpen(true);
  }

  function handleLogoutMenuClose(): void {
    setIsLogoutMenuOpen(false);
  }

  const { isOnline } = useOnlineStatus();
  function greyIfOffline(): object {
    if (isOnline) {
      return {};
    } else {
      return { sx: { backgroundColor: grey[600] } };
    }
  }

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <AppBar
        sx={{
          position: "relative",
          visibility: "hidden",
          mb: 4,
        }}>
        <Toolbar />
        <FilDAriane />
      </AppBar>
      <AppBar {...greyIfOffline()} sx={{ mb: 4 }}>
        <Toolbar>
          <Link sx={{ textDecoration: "none", flexGrow: 1 }} href="/" color="inherit">
            <Box sx={{ mr: isSmallScreen ? 1 : 2, display: "inline-block" }}>
              <img
                src={AppLogo}
                alt="logo"
                height={isSmallScreen ? "30" : "40"}
                style={{ verticalAlign: "middle" }}
              />
            </Box>
            <Typography
              variant="h6"
              sx={{
                display: "inline-block",
                verticalAlign: "middle",
              }}>
              Eau Secours {getEnvName()}
            </Typography>
          </Link>
          <div style={{ flexGrow: 1 }} />
          {!unAuthenticated && (
            <Authorization roles={ApplicationRoles.MODERATOR}>
              <ExcelExportButton />
            </Authorization>
          )}
          <Offline>
            <WifiOffIcon sx={{ paddingLeft: 1 }} fontSize="large" />
          </Offline>
          <IconButton edge="end" onClick={handleLogoutMenuOpen} color="inherit">
            {!unAuthenticated ? <HeaderAvatar /> : <Avatar />}
          </IconButton>
        </Toolbar>
        {!unAuthenticated && (
          <LogoutMenu
            open={isLogoutMenuOpen}
            anchorEl={anchorElUser}
            onClose={handleLogoutMenuClose}
          />
        )}
        <FilDAriane />
      </AppBar>
    </>
  );
}

export default Header;
