import type { Accident, OfflineAccident } from "models/Accident";
import Dexie from "dexie";
import type { IndexableType, Table } from "dexie";
import { Guid } from "guid-typescript";
import type { OfflineSecurite, Securite } from "models/Securite";
import type { DangerGrave, OfflineDangerGrave } from "models/DangerGrave";

/**
 * Décrit, puis crée si elle n'existe pas, puis se connecte à la base de données
 * des déclarations offline (IndexedDB dans le navigateur)
 */
class DeclarationsDB extends Dexie {
  accidents!: Table<OfflineAccident>;
  securites!: Table<OfflineSecurite>;
  dangerGraves!: Table<OfflineDangerGrave>;

  constructor() {
    super("declarations");
    this.version(4).stores({
      // Primary key and indexed props
      accidents: "++id, sending",
      securites: "++id, sending",
      dangerGraves: "++id, sending",
    });
  }
}
const db = new DeclarationsDB();

/**
 * Récupère toutes les déclarations offline
 * @returns Liste des déclarations offline
 */
async function getAllAccidents(): Promise<Array<OfflineAccident>> {
  return db.accidents.toArray();
}

/**
 * Récupère toutes les déclarations offline
 * @returns Liste des déclarations offline
 */
async function getAllSecurites(): Promise<Array<OfflineSecurite>> {
  return db.securites.toArray();
}

/**
 * Récupère toutes les déclarations offline
 * @returns Liste des déclarations offline
 */
async function getAllDangerGrave(): Promise<Array<OfflineDangerGrave>> {
  return db.dangerGraves.toArray();
}

/**
 * Récupère un danger grave ou imminent offline
 * @param indexed est l'identifidant GUID du danger
 * @returns Le danger offline trouver ou undefined s'il n'existe pas
 */
async function getDangerGrave(indexed: IndexableType): Promise<OfflineDangerGrave | undefined> {
  return db.dangerGraves.get(indexed);
}

/**
 * Récupère une déclarations offline
 * @returns La déclaration offline
 */
async function getAccidentById(id: IndexableType): Promise<OfflineAccident | undefined> {
  return db.accidents.get(id);
}

/**
 * Récupère une déclarations offline
 * @returns La déclaration offline
 */
async function getSecuriteById(id: IndexableType): Promise<OfflineSecurite | undefined> {
  return db.securites.get(id);
}

/**
 * Supprime une déclaration offline en fonction de son id
 */
async function removeAccidentById(id: IndexableType): Promise<void> {
  await db.accidents.delete(id);
}

/**
 * Supprime une déclaration offline en fonction de son id
 */
async function removeSecuriteById(id: IndexableType): Promise<void> {
  await db.securites.delete(id);
}

/**
 * Supprime une déclaration offline en fonction de son id
 */
async function removeDangerById(id: IndexableType): Promise<void> {
  await db.dangerGraves.delete(id);
}

/**
 * Crée une nouvelle déclaration offline dans l'IndexedDB
 * @returns La nouvelle déclaration créée
 */
async function createAccident(declaration: Accident): Promise<IndexableType> {
  return db.accidents.add(
    JSON.parse(
      JSON.stringify({
        ...declaration,
        sending: false,
        id: Guid.create().toString(),
      })
    )
  );
}

async function createSecurite(declaration: Securite): Promise<IndexableType> {
  return db.securites.add(
    JSON.parse(
      JSON.stringify({
        ...declaration,
        sending: false,
        id: Guid.create().toString(),
      })
    )
  );
}

async function createDanger(declaration: DangerGrave): Promise<IndexableType> {
  return db.dangerGraves.add(
    JSON.parse(
      JSON.stringify({
        ...declaration,
        sending: false,
        id: Guid.create().toString(),
      })
    )
  );
}

async function setAccidentSendingById(id: IndexableType, isSending: boolean): Promise<void> {
  await db.accidents.update(id, { sending: isSending });
}

async function setSecuriteSendingById(id: IndexableType, isSending: boolean): Promise<void> {
  await db.securites.update(id, { sending: isSending });
}

async function setDangerSendingById(id: IndexableType, isSending: boolean): Promise<void> {
  await db.dangerGraves.update(id, { sending: isSending });
}

async function setAccidentLastSyncAttempt(id: IndexableType): Promise<void> {
  await db.accidents.update(id, { lastSyncAttempt: new Date() });
}

async function setSecuriteLastSyncAttempt(id: IndexableType): Promise<void> {
  await db.securites.update(id, { lastSyncAttempt: new Date() });
}

async function setDangerLastSyncAttempt(id: IndexableType): Promise<void> {
  await db.dangerGraves.update(id, { lastSyncAttempt: new Date() });
}

async function deleteDuplicates(offlineIds: Array<IndexableType>): Promise<void> {
  const offlineAccs = await getAllAccidents();
  offlineAccs.forEach((offDec) => {
    if (offDec?.id != null && offlineIds.includes(offDec.id)) {
      void removeAccidentById(offDec.id);
    }
  });
  const offlineSecs = await getAllSecurites();
  offlineSecs.forEach((offDec) => {
    if (offDec?.id != null && offlineIds.includes(offDec.id)) {
      void removeSecuriteById(offDec.id);
    }
  });
  const offlineDangers = await getAllDangerGrave();
  offlineDangers.forEach((offDec) => {
    if (offDec?.id != null && offlineIds.includes(offDec.id)) {
      void removeDangerById(offDec.id);
    }
  });
}

const declarationOfflineService = {
  deleteDuplicates,

  getAllAccidents,
  getAccidentById,
  createAccident,
  removeAccidentById,
  setAccidentSendingById,
  setAccidentLastSyncAttempt,

  getAllSecurites,
  getSecuriteById,
  createSecurite,
  removeSecuriteById,
  setSecuriteSendingById,
  setSecuriteLastSyncAttempt,

  getAllDangerGrave,
  getDangerGrave,
  createDanger,
  removeDangerById,
  setDangerSendingById,
  setDangerLastSyncAttempt,
};
export default declarationOfflineService;
