import TypeAccident from "constants/TypeAccident";
import TypeSecurite from "constants/TypeSecurite";
import type { Declaration } from "models/Declaration";
import type User from "models/User";

function getTypeDeclarationLabel(option: string, withArticle: boolean = false): string {
  let typeDeclaration = "";
  switch (option) {
    case TypeAccident.TRAVAIL:
      typeDeclaration = `${withArticle ? "l'" : ""}Accident de travail`;
      break;
    case TypeAccident.TRAJET:
      typeDeclaration = `${withArticle ? "l'" : ""}Accident de trajet`;
      break;
    case TypeSecurite.ACCIDENT_BENIN:
      typeDeclaration = `${withArticle ? "l'" : ""}Accident bénin`;
      break;
    case TypeSecurite.PRESQU_ACCIDENT:
      typeDeclaration = `${withArticle ? "le " : ""}Presqu'accident`;
      break;
    case TypeSecurite.SITUATION_DANGEREUSE:
      typeDeclaration = `${withArticle ? "la " : ""}Situation dangereuse`;
      break;
    case "DANGER_GRAVE":
      typeDeclaration = `${withArticle ? "la Déclaration de " : ""}Danger grave et imminent`;
      break;
    default:
      typeDeclaration = `${withArticle ? "la " : ""}Déclaration`;
      break;
  }
  return typeDeclaration;
}

function getTypeDeclarationLabelFromDeclaration(
  declaration: Declaration,
  withArticle: boolean = false
): string {
  const typeDeclaration = getTypeDeclarationLabel(declaration?.type, withArticle);

  return typeDeclaration;
}

/**
 * Vérifie si une déclaration est un Accident
 * @param declaration
 * @returns true si oui, sinon false
 */
function isAccident(declaration: Declaration): boolean {
  return Object.keys(TypeAccident).some((v) => declaration?.type?.includes(v as TypeAccident));
}

/**
 * Vérifie si une déclaration est une Securite
 * @param declaration
 * @returns true si oui, sinon false
 */
function isSecurite(declaration: Declaration): boolean {
  return Object.keys(TypeSecurite).some((v) => declaration?.type?.includes(v as TypeSecurite));
}

/**
 * Récupère un 'snapshot' (copie à un instant t) de la victime d'une déclaration
 * @param declaration
 * @returns Un User si les champs nécessaires existent, sinon undefined
 */
function getSnapshotVictim(declaration: Declaration | undefined): User | undefined {
  if (
    declaration?.isVictimePrestataire != null &&
    declaration.isVictimePrestataire &&
    declaration?.victimeFirstName != null &&
    declaration?.victimeFirstName !== "" &&
    declaration?.victimeLastName != null &&
    declaration?.victimeLastName !== ""
  ) {
    const u = {
      surname: declaration.victimeFirstName,
      givenName: declaration.victimeLastName,
      displayName: `${declaration.victimeLastName} ${declaration.victimeFirstName} (externe)`,
    };
    return u as User;
  } else if (
    declaration?.victimeId != null &&
    declaration?.victimeId !== "" &&
    declaration?.victimeFirstName != null &&
    declaration?.victimeFirstName !== "" &&
    declaration?.victimeLastName != null &&
    declaration?.victimeLastName !== "" &&
    declaration?.victimeEmployeeId != null &&
    declaration?.victimeEmployeeId !== ""
  ) {
    const u = {
      id: declaration.victimeId,
      surname: declaration.victimeFirstName,
      givenName: declaration.victimeLastName,
      displayName: `${declaration.victimeLastName} ${declaration.victimeFirstName}`,
      employeeId: declaration.victimeEmployeeId,
      city: declaration?.victimeCity ?? "",
      department: declaration?.victimeDepartment ?? "",
      jobTitle: declaration?.victimeJobTitle ?? "",
    };
    return u as User;
  }
  return undefined;
}

const declarationService = {
  getTypeDeclarationLabel,
  getTypeDeclarationLabelFromDeclaration,
  isAccident,
  isSecurite,
  getSnapshotVictim,
};
export default declarationService;
